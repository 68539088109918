<div class="loading" *ngIf="showSpinner">
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="loading-text">
    <div style="display: flex;">
      <span class="loading-text-words" *ngFor="let l of letters">{{ l }}</span>
    </div>

  </div>
</div>
