import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ModelBlogPostFilterInput, ModelPublisherFilterInput, ModelSortDirection, SearchableBlogPostFilterInput, SearchableBlogPostSortableFields, SearchableBlogPostSortInput } from 'src/app/core/api/api';
import { blogPostsByCityQuery, blogPostsByReadCountShortQuery, listBlogPostByStatus, listBlogPosts, listCaseStudys, searchBlogPosts } from 'src/app/core/api/blog/blog.queries';
import { GraphResponse } from 'src/app/core/models/aws';
import { IBlogPost } from 'src/app/core/models/blog';
import { SearchableSortDirection } from 'src/app/core/models/entity';
import { IPublisher } from 'src/app/core/models/publisher';
import { ApiService, ErrorType } from 'src/app/core/services/api.service';
import { StoreService } from 'src/app/core/services/store.service';
import { getBlogPost, blogPostsByAreaQuery, listAllPublishersLogos } from 'src/app/shared/api/queries';

@Injectable({
  providedIn: 'root'
})
export class BlogService extends ApiService {

  constructor(private store: StoreService) {
    super();
  }

  /**
   * getBlogPostById
   * @param id
   * @returns
   */
  public getBlogPostById(id: string): Observable<IBlogPost> {
    return this.runQuery(getBlogPost, { id }, 'getBlogPost', true, true).pipe(first());
  }

  /**
   * getBlogPostByCity
   * @param city
   * @param area
   * @param nextToken
   * @returns
   */
  public async getBlogPostByCity(city: string, area?: string, nextToken?: string, limit?: number, storeName?: string): Promise<GraphResponse<IBlogPost>> {
    const sortDirection: ModelSortDirection = ModelSortDirection.DESC;
    let res: GraphResponse<IBlogPost> | ErrorType;
    const filter = { publishStatus: {eq: 'PUBLISHED'}};
    if (area) {
      res = await this.runQuery<GraphResponse<IBlogPost>>(blogPostsByAreaQuery, {
        area,
        sortDirection,
        limit: limit || 6,
        filter,
        nextToken
      }, 'blogPostsByAreaQuery', true, true).pipe(first()).toPromise();
    } else {
      res = await this.runQuery<GraphResponse<IBlogPost>>(blogPostsByCityQuery, {
        city,
        sortDirection,
        limit: limit || 6,
        filter,
        nextToken
      }, 'blogPostsByCityQuery', true, true).pipe(first()).toPromise();
    }

    if ((res as ErrorType) !== ErrorType.ERROR) {
      this.store.setList(storeName || 'countryLaunchBlogs', res as GraphResponse<IBlogPost>);
      return res as GraphResponse<IBlogPost>;
    } else {
      return { items: [], nextToken: null }
    }
  }

  public getBlogPostByCity$(city: string, area?: string, nextToken?: string, limit?: number): Observable<GraphResponse<IBlogPost>> {
    const sortDirection: ModelSortDirection = ModelSortDirection.DESC;
    let res: Observable<GraphResponse<IBlogPost>>;
    if (area) {
      res = this.runQuery<GraphResponse<IBlogPost>>(blogPostsByAreaQuery, {
        area,
        filter: { publishStatus : { eq: 'PUBLISHED'}},
        sortDirection,
        limit: limit || 6,
        nextToken
      }, 'blogPostsByAreaQuery', true, true).pipe(
        first(),
        map((res: any) => {
          if ((res as ErrorType) !== ErrorType.ERROR) {
            return res as GraphResponse<IBlogPost>;
          } else {
            return { items: [], nextToken: null }
          }
        })
      )
    } else {
      res = this.runQuery<GraphResponse<IBlogPost>>(blogPostsByCityQuery, {
        city,
        filter: { publishStatus : { eq: 'PUBLISHED'}},
        sortDirection,
        limit: limit || 6,
        nextToken
      }, 'blogPostsByCityQuery', true, true).pipe(first(),
        map((res: any) => {
          if ((res as ErrorType) !== ErrorType.ERROR) {
            return res as GraphResponse<IBlogPost>;
          } else {
            return { items: [], nextToken: null }
          }
        }));
    }
    return res;
  }

  public getBlogPosts(nextToken?: string, limit?: number, store?: string): Observable<GraphResponse<IBlogPost>> {
    return this.runQuery<GraphResponse<IBlogPost>>(listBlogPostByStatus, {
      limit: limit || 5,
      nextToken,
      publishStatus: 'PUBLISHED',
      sortDirection: ModelSortDirection.DESC
    }, 'listBlogPostByStatus', true, true).pipe(first(),
      map((res: any) => {
        if ((res as ErrorType) !== ErrorType.ERROR) {
          this.store.setList(store || 'blogs', res);
          return res as GraphResponse<IBlogPost>;
        } else {
          this.store.setList(store || 'blogs', { items: [], nextToken: null });
          return { items: [], nextToken: null }
        }
      }), first());
  }
  public blogPostsByReadCountShortQuery(city: string, nextToken?: string, limit?: number): Observable<GraphResponse<IBlogPost>> {
    const filter: ModelBlogPostFilterInput = { publishStatus: { eq: 'PUBLISHED' }}
    return this.runQuery<GraphResponse<IBlogPost>>(blogPostsByReadCountShortQuery, {
      city,
      filter,
      limit: limit || 20,
      sortDirection: ModelSortDirection.DESC
    }, 'blogPostsByReadCountQuery', true, true).pipe(first(),
      map((res: any) => {
        if ((res as ErrorType) !== ErrorType.ERROR) {
          return res as GraphResponse<IBlogPost>;
        } else {
          return { items: [], nextToken: null }
        }
      }), first());
  }

  public async searchBlogs(term: string): Promise<any> {
    const filter: any = {
      or: [
        { title: { matchPhrasePrefix: `${term}*` } },
        { title: { matchPhrasePrefix: `${term.toLowerCase()}*` } },
        { catgories: { matchPhrasePrefix: `${term}*` }}
      ]
    };
    try {
      const res: any = await this.runQuery(searchBlogPosts, { filter, limit: 15 }, 'searchBlogPosts', true).pipe(first()).toPromise();
      if ((res as ErrorType) === ErrorType.ERROR) {
        return null;
      }
      return res;
    } catch (err) {
      console.error(err)
      return false;
    }
  }

  public async searchBlogsByCategory(category: string, city: string, limit?: number): Promise<any> {
    const filter: SearchableBlogPostFilterInput = {
      and: [
        { catgories: { matchPhrase: category }}
      ]
    };

    const sort: SearchableBlogPostSortInput = {
        field: SearchableBlogPostSortableFields.readCount,
        direction: SearchableSortDirection.desc
    }


    if (city) {
      filter.and.push({ city: { eq: city } });
    }

    try {
      const res: any = await this.runQuery(searchBlogPosts, { filter, limit: limit || 15, sort }, 'searchBlogPosts', true).pipe(first()).toPromise();
      if ((res as ErrorType) === ErrorType.ERROR) {
        return null;
      }
      return res;
    } catch (err) {
      console.error(err)
      return false;
    }
  }

  public async getSidebarBlogs(city?: string, limit?: number) {
    const sideblogs = await Promise.all([
      this.blogPostsByReadCountShortQuery(city, null, limit || 8).pipe(first()).toPromise(),
      this.searchBlogsByCategory('Drinks', city, limit || 4),
      this.searchBlogsByCategory('Restaurants', city, limit || 4),
      this.searchBlogsByCategory('Activities', city, limit || 4),
    ]);

    this.store.setObject('sidebarBlogs', sideblogs);
    return sideblogs;
  }


  public async getInternationalBlogs(cities?: string[]): Promise<IBlogPost[]> {
    try {
      const blogPosts: IBlogPost[] = [];
      const blogFetchs: any[] = [];

      for (let c of cities) {
        blogFetchs.push(this.getBlogPostByCity$(c, null, null, 3).pipe(first()).toPromise());
      }

      const results: any = await Promise.all(blogFetchs);

      if (results?.length) {
        for (let res of results) {
          if (res?.items?.length) {
            blogPosts.push(...res.items);
          }
        }
      }

      this.store.setObject('homeBlogs', blogPosts || []);
      return blogPosts;

    } catch(err) {
      console.log('GET INT BLOGS ERROR', err);
      return [];
    }
  }


  public getCaseStudies(nextToken?: string, limit?: number, store?: string): Observable<GraphResponse<any>> {
    return this.runQuery<GraphResponse<any>>(listCaseStudys, {
      limit: limit || 5,
      nextToken,
      // publishStatus: 'PUBLISHED',
      sortDirection: ModelSortDirection.DESC
    }, 'listCaseStudys', true, true).pipe(first(),
      map((res: any) => {
        if ((res as ErrorType) !== ErrorType.ERROR) {
          // now order in terms of priority
          if (res?.items?.length) {
            res.items = res.items.sort((a, b) => {
              // Handle null priorities
              if (a.priority === null && b.priority !== null) {
                return  1; // Place a after b
              } else if (a.priority !== null && b.priority === null) {
                return -1; // Place a before b
              } else if (a.priority === null && b.priority === null) {
                return  0; // Equal priority
              }

              // Compare numeric priorities
              return a.priority - b.priority;
            });
          }
          this.store.setList(store || 'caseStudies', res);
          return res as GraphResponse<any>;
        } else {
          this.store.setList(store || 'caseStudies', { items: [], nextToken: null });
          return { items: [], nextToken: null }
        }
      }), first());
  }


  public getPublisherLogos(nextToken?: string, limit?: number): Observable<GraphResponse<any>> {
    let filter: ModelPublisherFilterInput = {
      role: {eq: 'PUBLISHER'},
      status: { ne: 'DELETED'},
      activated: { attributeExists: true },
      logo: { attributeExists: true }
    }

    return this.runQuery<GraphResponse<any>>(listAllPublishersLogos, {
      limit: limit || 15,
      nextToken,
      filter,
      sortDirection: ModelSortDirection.DESC
    }, 'listPublishers', true, true).pipe(first(),
    map((res: any) => {
      if ((res as ErrorType) !== ErrorType.ERROR && res?.items?.length) {
        // Remove any without logos.
        res.items = res.items.sort((a: any, b: any) => a?.activatedClaimedOffersCount < b?.activatedClaimedOffersCount ? 1 : -1);
        this.store.setList('publisherLogos', res);
        return res as GraphResponse<any>;
      } else {
        this.store.setList('publisherLogos', { items: [], nextToken: null });
        return { items: [], nextToken: null }
      }
    }), first());
  }

}
