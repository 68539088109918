            <!-- BEGIN SITE FOOTER -->
            <footer id="footer" class="footer">
              <div class="footer-top">
                  <div class="container">
                      <div class="inner">
                          <div class="row">
                              <div class="col-lg-3">
                                  <div class="footer-item footer-about">
                                      <div class="logo">
                                          <a href="/" class="d-flex align-items-center">
                                              <img src="assets/images/pingfluence/logo.png" alt="Ping's Logo" width="45" height="45" />
                                              <!-- <img *ngIf="shrinkNav" src="assets/logo/logo-black-icon.png" alt="Ping's Logo in black" /> -->
                                              <span class="branding-text">Pingfluence</span>
                                          </a>
                                      </div>
                                      <div class="d-flex flex-row social-icons">
                                          <a
                                            href="https://www.instagram.com/pingfluenceapp"
                                            target="_blank"
                                            class="social-icon instagram mx-1"
                                            title="Open to Ping's Instagram"
                                            alt="Open to Ping's Instagram"
                                          >
                                          Instagram
                                          </a>
                                          <a
                                            href="https://tiktok.com/@pingfluenceapp"
                                            target="_blank"
                                            class="social-icon tiktok mx-1"
                                            title="Open to Ping's TikTok"
                                            alt="Open to Ping's TikTok"
                                          >
                                          <!-- <i class="lab la-tiktok"></i> -->
                                          Tiktok
                                          </a>
                                          <a
                                            href="https://www.linkedin.com/company/69716828"
                                            target="_blank"
                                            class="social-icon fb mx-1"
                                            title="Open to Ping's Linkedin"
                                            alt="Open to Ping's LinkedIn"
                                          >LinkedIn
                                          </a>
                                        </div>
                                  </div>
                              </div>
                              <div class="col-lg-1"></div>
                              <div class="col-lg-4">
                                  <div class="footer-item footer-menu">
                                      <h6>The good stuff</h6>
                                      <ul>
                                          <li>
                                              <a href="/influencer">Influencers</a>
                                          </li>
                                          <!-- <li>
                                              <a href="/contact">Get In Youch</a>
                                          </li> -->
                                          <li>
                                              <a target="_blank" href="https://ping-culture.com">Ping Culture</a>
                                          </li>
                                          <li>
                                              <a target="_blank" href="https://pingfluence.com/blog/list">Ping Blogs</a>
                                          </li>
                                          <!-- <li>
                                              <a target="_blank" href="https://portal.ping-culture.com">Business Portal</a>
                                          </li> -->
                                      </ul>
                                  </div>
                              </div>
                              <div class="col-lg-4">
                                  <div class="footer-item footer-menu">
                                      <h6>The boring stuff</h6>
                                      <ul>
                                          <li><a href="https://intercom.help/pingfluence">Help</a>
                                          </li>
                                          <li><a href="https://intercom.help/pingfluence/en/articles/8006123-pingfluence-privacy-policy" target="_blank"
                                              >Privacy</a
                                            >
                                          </li>
                                          <li><a href="https://intercom.help/pingfluence/en/articles/8006233-user-terms-conditions" target="_blank"
                                              >Terms</a
                                            >
                                          </li>
                                          <li><a href="https://help.ping-culture.com/docs/cookie-policy" target="_blank"
                                              >Cookies</a
                                            >
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="footer-bottom border-top">
                  <div class="container">
                      <div class="inner d-flex justify-content-between flex-content-sb flex-align-c">
                          <div class="copyright">© {{ currentYear }} The Ping Culture Ltd. All Rights Reserved.</div>
                          <div class="menu">
                              <ul>
                                  <li><a href="https://help.ping-culture.com/docs/cookie-policy" target="_blank" title="Cookies">Cookies</a></li>
                                  <li><a href="https://intercom.help/pingfluence/en/articles/8006233-user-terms-conditions" target="_blank" title="Terms">Terms</a></li>
                                  <li><a href="https://intercom.help/pingfluence/en/articles/8006123-pingfluence-privacy-policy" target="_blank" title="Privacy Policy">Privacy Policy</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>