import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOutAttr'
})
export class FilterOutPipe implements PipeTransform {

  constructor() {}

  transform(collection: any[], attrs: any): any {
    const results = collection.filter((item: any) => {
      let passFilter: boolean = true;
      for (let att in attrs) {
        if (item[att] === attrs[att]) {
          passFilter = false;
        }
      }
      return passFilter;
    });
    if(results?.length === 0) {
      return [-1];
    }
    return results;
  }

}
