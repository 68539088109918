import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { faFacebook, faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
@Component({
  selector: 'ping-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  faFacebook= faFacebook;
  faInstagram = faInstagram;
  faTwitter = faTwitter;
  faTiktok = faTiktok;

  public currentYear: number = 2023;

  constructor(private analytics: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  public downloadApp(platform: string) {
    this.analytics.trackMixpanelEvent('app-download', { platform: platform });
    this.analytics.trackFbEvent('Lead', {content_name: platform, value: 1});
    this.analytics.trackEvent('Download Link', 'Header Icons', platform);
  }

}
