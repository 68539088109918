import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface GraphResponse {
  items: any[];
  groupedItems?: any[];
  nextToken: any;
}

export interface GraphResponseStream {
  data: any;
}

@Injectable({ providedIn: 'root' })
export class StoreService {

  private listStore: any = {};
  private objectStore: any = {};
  private keyStore: any = {};

  constructor() { }

  resetAllStores() {
    this.listStore = {};
    this.objectStore = {};
    this.keyStore = {};
  }

  // Setters
  public setObject<T>(key: string, item: T): T {
    if (this.objectStore[key]) {
      this.objectStore[key].next(item)
    } else {
      this.objectStore[key] = new BehaviorSubject<T>(item);
    }
    return item;
  }

  public setList<T>(key: string, item: GraphResponse): GraphResponse {
    if (this.listStore[key]) {
      this.listStore[key].next(item)
    } else {
      this.listStore[key] = new BehaviorSubject<GraphResponse>(item);
    }
    return item;
  }

  public setKey<T>(key: string, value: T): T {
    key = 'key-' + key;
    if (this.keyStore[key]) {
      this.keyStore[key].next(value);
    } else {
      this.keyStore[key] = new BehaviorSubject<T>(value);
    }
    return value;
  }

  // Getters
  public getObject<T>(key: string): T {
    if (this.objectStore[key]) {
      return this.objectStore[key].value;
    } else {
      return null;
    }

  }

  public getList<T>(key: string): GraphResponse {
    if (this.listStore[key]) {
      return this.listStore[key].value;
    } else {
      return null;
    }
  }

  public getKey<T>(key: string): T {
    key = 'key-' + key;
    if (this.keyStore[key]) {
      return this.keyStore[key].value;
    } else {
      return null;
    }
  }

  // Streams
  public object$<T>(key: string): Observable<T> {
    if (!this.objectStore[key]) {
      this.objectStore[key] = new BehaviorSubject<T>(null);
    }
    return this.objectStore[key].asObservable();
  }

  public list$<T>(key: string): Observable<T> {
    if (!this.listStore[key]) {
      this.listStore[key] = new BehaviorSubject<T>(null);
    }
    return this.listStore[key].asObservable();
  }

  public key$<T>(key: string): Observable<T> {
    key = 'key-' + key;
    if (!this.keyStore[key]) {
      this.keyStore[key] = new BehaviorSubject<T>(null);
    }
    return this.keyStore[key].asObservable();
  }

  // Modifiers
  public addToList<T extends any>(key: string, item: any): GraphResponse {
    let list:GraphResponse;
    if (!this.listStore[key]) {
      this.listStore[key] = new BehaviorSubject<T>({ items:[], nextToken: null } as any);
    }
    list =  this.listStore[key].value;

    if (!list){
      list = { items:[], nextToken: null }
    }

    const indexOfItem: number = list.items.findIndex((i: any) => i.id === item.id);

    indexOfItem > -1 ? (list.items[indexOfItem] = item) : list.items.unshift(item);

    this.listStore[key].next(list);
    return list;
  }

  public joinToList<T extends any>(key: string, data: GraphResponse): GraphResponse {
    let list:GraphResponse;
    if (!this.listStore[key]) {
      this.listStore[key] = new BehaviorSubject<T>({ items:[], nextToken: null } as any);
    } else {
      list =  this.listStore[key].value;
    }

    if (!list){
      list = { items:[], nextToken: null }
    }

    if (!list) {
      list = data;
    } else {
      list.items.push(...data.items);
      list.nextToken = data.nextToken;
    }
    this.listStore[key].next(list);
    return list;
  }

  public removeFromList<T extends any>(key: string, item: T, findBy?: string): GraphResponse {
    let list: GraphResponse;
    if (this.listStore[key]) {

      let list: GraphResponse = this.listStore[key].value

      const by = findBy ? findBy : 'id';

      const indexOfItem = list.items.findIndex((i: any) => i[by] === item[by]);

      if (indexOfItem > -1) {
        list.items.splice(indexOfItem, 1);
      }

      this.listStore[key].next(list);
    }
    return list;
  }

  public updateItemInList<T extends any>(key: string, data: any): GraphResponse {
    let list:GraphResponse;

    if (!this.listStore[key]) {
      this.listStore[key] = new BehaviorSubject<T>({ items:[], nextToken: null } as any);
    }
      list =  this.listStore[key].value;


    if (!list){
      list = { items:[], nextToken: null }
    }

    const indexOfItem: number = list.items.findIndex((i: any) => i.id === data.id);

    if (indexOfItem > -1) {
      list.items[indexOfItem] = { ...list.items[indexOfItem], ...data };
    // } else {
    //   list.items = [data]
    }

    this.listStore[key].next(list)
    return list;
  }

  public updateCounter(key: string, value: any, setValue?: boolean): void {

    if (!this.objectStore['counterStore']) {
      this.objectStore['counterStore'] = new BehaviorSubject<any>({});
    }

    let counterStore = this.objectStore['counterStore'].value;

    if (!counterStore[key]) {
      counterStore[key] = 0;
    }

    counterStore[key] = (counterStore[key] === 0 && value < 0) ? 0 : counterStore[key] + value;

    if (setValue) {
      counterStore[key] = value;
    }

    if (value > 0 && key !== 'profile') {
      counterStore['profile'] = counterStore['profile'] ? counterStore['profile'] + value : value;
    } else {
      counterStore[key] = value;
    }
    this.objectStore['counterStore'].next(counterStore);
    return counterStore;
  }

  public isListPopulated(key: string): boolean {
    const store = this.listStore.value;
    return store ? (store[key]?.items?.length) : false;
  }


}
