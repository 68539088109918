/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendSNSToTopic = /* GraphQL */ `
  query SendSNSToTopic($payload: PushPayload!, $env: String) {
    sendSNSToTopic(payload: $payload, env: $env)
  }
`;
export const unsubscribeFromTopic = /* GraphQL */ `
  query UnsubscribeFromTopic($endpointArns: [String], $env: String) {
    unsubscribeFromTopic(endpointArns: $endpointArns, env: $env)
  }
`;
export const toggleUserEventSubscription = /* GraphQL */ `
  query ToggleUserEventSubscription($input: AWSJSON) {
    toggleUserEventSubscription(input: $input)
  }
`;
export const subscribeToFrom = /* GraphQL */ `
  query SubscribeToFrom(
    $parentId: ID
    $subscribe: Boolean
    $type: String
    $userId: ID
    $pingfluencerId: ID
    $pingfluencerFollowerCount: Float
    $cognitoUsername: String
    $userFullName: String
    $tokens: [UserPushTokenInput]
    $onlySNS: Boolean
    $env: String
  ) {
    subscribeToFrom(
      parentId: $parentId
      subscribe: $subscribe
      type: $type
      userId: $userId
      pingfluencerId: $pingfluencerId
      pingfluencerFollowerCount: $pingfluencerFollowerCount
      cognitoUsername: $cognitoUsername
      userFullName: $userFullName
      tokens: $tokens
      onlySNS: $onlySNS
      env: $env
    )
  }
`;
export const addUserPoints = /* GraphQL */ `
  query AddUserPoints($input: UserPointsInput, $env: String) {
    addUserPoints(input: $input, env: $env)
  }
`;
export const addUserToEvent = /* GraphQL */ `
  query AddUserToEvent(
    $userId: ID
    $eventId: ID
    $attending: Boolean
    $tokens: [UserPushTokenInput]
    $type: String
    $cognitoUsername: String
    $publisherCognitoUsername: String
    $eventDate: Float
    $status: String
    $env: String
    $repeatEventOccurencId: String
  ) {
    addUserToEvent(
      userId: $userId
      eventId: $eventId
      attending: $attending
      tokens: $tokens
      type: $type
      cognitoUsername: $cognitoUsername
      publisherCognitoUsername: $publisherCognitoUsername
      eventDate: $eventDate
      status: $status
      env: $env
      repeatEventOccurencId: $repeatEventOccurencId
    )
  }
`;
export const addLoyaltyPoints = /* GraphQL */ `
  query AddLoyaltyPoints(
    $parentId: ID
    $type: String!
    $userId: ID!
    $userFullName: String
    $cognitoUsername: String
    $points: Int!
    $env: String
  ) {
    addLoyaltyPoints(
      parentId: $parentId
      type: $type
      userId: $userId
      userFullName: $userFullName
      cognitoUsername: $cognitoUsername
      points: $points
      env: $env
    ) {
      code
      data {
        id
        userId
        isNewSubscriber
        reward {
          title
          description
          rewardType
          loyaltyPointsRequired
        }
        publisher {
          title
          logo
          id
        }
        event {
          id
          publisherId
          type
          images
          title
          entity
          city
        }
        entity {
          id
          publisherId
          logo
          images
          title
          city
        }
        totalPoints
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  query DeleteTopic($type: String!, $id: String!, $env: String) {
    deleteTopic(type: $type, id: $id, env: $env)
  }
`;
export const createNewSNSApplicationEndpoint = /* GraphQL */ `
  query CreateNewSNSApplicationEndpoint(
    $deviceType: String!
    $deviceToken: String!
    $env: String!
    $deviceName: String
  ) {
    createNewSNSApplicationEndpoint(
      deviceType: $deviceType
      deviceToken: $deviceToken
      env: $env
      deviceName: $deviceName
    )
  }
`;
export const updatePushToken = /* GraphQL */ `
  query UpdatePushToken(
    $endpointArn: String!
    $deviceToken: String!
    $enabled: Boolean!
    $env: String
  ) {
    updatePushToken(
      endpointArn: $endpointArn
      deviceToken: $deviceToken
      enabled: $enabled
      env: $env
    )
  }
`;
export const citiesByLocation = /* GraphQL */ `
  query CitiesByLocation($input: SearchCitiesInput, $env: String) {
    citiesByLocation(input: $input, env: $env) {
      items {
        id
        title
        venueCount
        areas {
          title
          parentArea
          images
          timezoneName
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
        createdAt
        updatedAt
        status
        config {
          events
          places
          pingers
        }
        utcOffsetMinutes
        timezoneName
        city
        isPingfluencerCity
      }
      nextToken
    }
  }
`;
export const searchElasticEvents = /* GraphQL */ `
  query SearchElasticEvents($input: SearchEventsInput, $env: String) {
    searchElasticEvents(input: $input, env: $env) {
      items {
        id
        title
        entityId
        logo
        headerVideos
        publisherId
        area
        sponsors {
          id
          title
          logo
          desc
        }
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        genres
        dateTime
        startDate
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        endDate
        closingTime
        rules
        email
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        pushTimestamps
        ownerType
        tickets {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        influencerInfo {
          hashtags
          tags
          details
        }
        username
        menu {
          id
          title
          status
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        variations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchElasticSpecialOffers = /* GraphQL */ `
  query SearchElasticSpecialOffers(
    $input: SearchElasticOffersInput
    $env: String
  ) {
    searchElasticSpecialOffers(input: $input, env: $env) {
      items {
        id
        daysAvailable
        startTime
        price
        discount
        endTime
        endDate
        countryCode
        declineReason
        requiresBooking
        startDate
        maxTransactionAmount
        minTransactionAmount
        returnPeriod
        metaData {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        description
        totalLoyaltyPointsRequiredForAccess
        sharePointsRequiredForAccess
        contactToClaim
        contactEmail
        notificationEmails
        title
        images
        weeks
        entityId
        publisherId
        location {
          lat
          lon
        }
        fidelId
        spendRequired
        delayMinutes
        delayHours
        currency
        isStudentDiscount
        city
        area
        availableEntities
        quantity
        status
        loyaltyPointsRequired
        loyaltyPointsValue
        visitsRequired
        redeemCount
        claimCount
        dateAvailable
        allowanceLimit
        refreshDaysPeriod
        type
        rewardType
        subscribersOnly
        owner
        disclaimer
        metadata {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        fidelOfferId
        fidelStartDate
        fidelEndDate
        fidelMinTransactionAmount
        fidelMaxTransactionAmount
        fidelReturnPeriod
        fidelDaysOfWeek
        hashtags
        mentions
        tiktokHashtags
        tiktokMentions
        instagramHashtags
        instagramMentions
        claimType
        redeemLatencyPeriod
        contactPhone
        doNotWhatsapp
        userAllowedClaimsPerMonth
        socialPostRequirements {
          and
          extraRequirement
        }
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        citiesAvailable
        minimumStarRating
        monetaryValue
        monthlyLimitPerUser
        pinToWelcome
        numOfFollowersRequired
        eventDateStart
        eventDateEnd
        customInstructions
        categories
        offerCategories
        quantityAvailable
        claimUrl
        comingSoon
        requiresConfirmation
        peopleQuantity
        additionalGuestsAllowed
        offerDuration
        bookingStickerUrl
        appearancePriority
        listingPriority
        showAsStandaloneOffer
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchElasticFeaturedPlaces = /* GraphQL */ `
  query SearchElasticFeaturedPlaces(
    $input: SearchFeaturedPlacesInput
    $env: String
  ) {
    searchElasticFeaturedPlaces(input: $input, env: $env) {
      items {
        id
        entityId
        eventId
        offerId
        location {
          lat
          lon
        }
        area
        title
        images
        deeplink
        linkText
        description
        pinToTop
        city
        featuredUntil
        coverage
        country
        days_selected
        startDate
        budget
        budget_left
        paid
        viewCount
        owner
        createdAt
        updatedAt
        status
        ttl
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const searchElasticRepeatedEvents = /* GraphQL */ `
  query SearchElasticRepeatedEvents(
    $input: SearchRepeatEventsInput
    $env: String
  ) {
    searchElasticRepeatedEvents(input: $input, env: $env) {
      items {
        id
        title
        entityId
        area
        headerVideos
        publisherId
        sponsors {
          id
          title
          logo
          desc
        }
        logo
        subtitle
        description
        type
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        categories
        genres
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        firstEventDate
        lastEventDate
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        pushTimestamps
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        tickets {
          nextToken
        }
        occurencesRules {
          title
          startTime
          endTime
          typeOfRepeat
          repeatInterval
          daysOfWeek
          daysOfMonth
          weeksOfMonth
          weeksDaysOfMonth
          occurenceTotalRepeat
          startDate
          endDate
          usesEntityTables
          quantity
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        influencerInfo {
          hashtags
          tags
          details
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        occurences {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchElasticPlaces = /* GraphQL */ `
  query SearchElasticPlaces($input: SearchPlacesInput, $env: String) {
    searchElasticPlaces(input: $input, env: $env) {
      items {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchElasticartistEventLocation = /* GraphQL */ `
  query SearchElasticartistEventLocation(
    $filter: SearchElasticArtistEventPositionFilter
    $km: Int
    $limit: Int
    $nextToken: String
    $env: String
  ) {
    searchElasticartistEventLocation(
      filter: $filter
      km: $km
      limit: $limit
      nextToken: $nextToken
      env: $env
    ) {
      items {
        id
        location {
          lat
          lon
        }
        eventDate
        city
        ttl
        createdAt
        updatedAt
        artist {
          id
          title
          country
          spotifyAppUri
          videoUrls
          nickname
          rating
          pingGigsPlayed
          numReviews
          logo
          soundcloudAppUri
          appleMusicAppUri
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          stripeConnectId
          amazonMusicUrl
          stripeCustomerId
          tidalUrl
          soundcloudUrl
          appleMusicUrl
          description
          genres
          images
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchElasticInfluencers = /* GraphQL */ `
  query SearchElasticInfluencers(
    $input: SearchElasticInfluencersInput
    $env: String
  ) {
    searchElasticInfluencers(input: $input, env: $env) {
      items {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchElasticInstagrams = /* GraphQL */ `
  query SearchElasticInstagrams(
    $input: SearchElasticInstagramsInput
    $env: String
  ) {
    searchElasticInstagrams(input: $input, env: $env) {
      items {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const incrementCount = /* GraphQL */ `
  query IncrementCount($input: CountInput) {
    incrementCount(input: $input)
  }
`;
export const activatePublisherProfile = /* GraphQL */ `
  query ActivatePublisherProfile(
    $input: ActivationPayload
    $email: String
    $name: String
    $entityIds: [String]
    $env: String
  ) {
    activatePublisherProfile(
      input: $input
      email: $email
      name: $name
      entityIds: $entityIds
      env: $env
    )
  }
`;
export const stripeCreateCardToken = /* GraphQL */ `
  query StripeCreateCardToken(
    $card: StripeCard
    $id: String
    $name: String
    $email: String
    $env: String
  ) {
    stripeCreateCardToken(
      card: $card
      id: $id
      name: $name
      email: $email
      env: $env
    ) {
      code
      message
      body
    }
  }
`;
export const eposCustomerAPI = /* GraphQL */ `
  query EposCustomerAPI($requestType: String, $data: AWSJSON) {
    eposCustomerAPI(requestType: $requestType, data: $data)
  }
`;
export const eposCustomerTypeAPI = /* GraphQL */ `
  query EposCustomerTypeAPI($requestType: String, $data: AWSJSON) {
    eposCustomerTypeAPI(requestType: $requestType, data: $data)
  }
`;
export const sevenRoomsAvailability = /* GraphQL */ `
  query SevenRoomsAvailability($input: SevenRoomsAvailabilityInput!) {
    sevenRoomsAvailability(input: $input)
  }
`;
export const sevenRoomsCreateBooking = /* GraphQL */ `
  query SevenRoomsCreateBooking($input: AWSJSON) {
    sevenRoomsCreateBooking(input: $input)
  }
`;
export const sevenRoomsGetAvailability = /* GraphQL */ `
  query SevenRoomsGetAvailability($input: AWSJSON) {
    sevenRoomsGetAvailability(input: $input)
  }
`;
export const stripeCreateConnectAccount = /* GraphQL */ `
  query StripeCreateConnectAccount($arguments: AWSJSON) {
    stripeCreateConnectAccount(arguments: $arguments)
  }
`;
export const stripeGetConnectSignupUrl = /* GraphQL */ `
  query StripeGetConnectSignupUrl($connectAccountId: String, $env: String) {
    stripeGetConnectSignupUrl(connectAccountId: $connectAccountId, env: $env)
  }
`;
export const stripeGetConnectAccount = /* GraphQL */ `
  query StripeGetConnectAccount($arguments: AWSJSON) {
    stripeGetConnectAccount(arguments: $arguments)
  }
`;
export const stripeCreatePaymentIntent = /* GraphQL */ `
  query StripeCreatePaymentIntent($arguments: AWSJSON) {
    stripeCreatePaymentIntent(arguments: $arguments)
  }
`;
export const stripeConfirmCardPayment = /* GraphQL */ `
  query StripeConfirmCardPayment($arguments: AWSJSON) {
    stripeConfirmCardPayment(arguments: $arguments)
  }
`;
export const createEventTicket = /* GraphQL */ `
  query CreateEventTicket($arguments: AWSJSON) {
    createEventTicket(arguments: $arguments)
  }
`;
export const stripeRefundTicket = /* GraphQL */ `
  query StripeRefundTicket($arguments: AWSJSON) {
    stripeRefundTicket(arguments: $arguments)
  }
`;
export const updateEventTicketCounter = /* GraphQL */ `
  query UpdateEventTicketCounter(
    $eventId: String
    $increment: Int
    $env: String
  ) {
    updateEventTicketCounter(
      eventId: $eventId
      increment: $increment
      env: $env
    )
  }
`;
export const deleteHihglightVideos = /* GraphQL */ `
  query DeleteHihglightVideos($url: String, $env: String) {
    deleteHihglightVideos(url: $url, env: $env)
  }
`;
export const stripeListProducts = /* GraphQL */ `
  query StripeListProducts($arguments: AWSJSON) {
    stripeListProducts(arguments: $arguments)
  }
`;
export const adminProspectingEmailFunction = /* GraphQL */ `
  query AdminProspectingEmailFunction(
    $email: String
    $entity: String
    $env: String
  ) {
    adminProspectingEmailFunction(email: $email, entity: $entity, env: $env)
  }
`;
export const updateFollowerAggregation = /* GraphQL */ `
  query UpdateFollowerAggregation(
    $subscriptionId: String
    $increment: Int
    $type: String
    $env: String
  ) {
    updateFollowerAggregation(
      subscriptionId: $subscriptionId
      increment: $increment
      type: $type
      env: $env
    )
  }
`;
export const broadcastNotfication = /* GraphQL */ `
  query BroadcastNotfication($notificationData: AWSJSON) {
    broadcastNotfication(notificationData: $notificationData)
  }
`;
export const sendEventInvite = /* GraphQL */ `
  query SendEventInvite($arguments: AWSJSON) {
    sendEventInvite(arguments: $arguments)
  }
`;
export const activateUserAccount = /* GraphQL */ `
  query ActivateUserAccount($arguments: AWSJSON) {
    activateUserAccount(arguments: $arguments)
  }
`;
export const deletePublisherAccount = /* GraphQL */ `
  query DeletePublisherAccount($arguments: AWSJSON) {
    deletePublisherAccount(arguments: $arguments)
  }
`;
export const sendUserGift = /* GraphQL */ `
  query SendUserGift($giftData: AWSJSON) {
    sendUserGift(giftData: $giftData)
  }
`;
export const stripeSendInvoice = /* GraphQL */ `
  query StripeSendInvoice($arguments: AWSJSON) {
    stripeSendInvoice(arguments: $arguments)
  }
`;
export const sendTicketEmail = /* GraphQL */ `
  query SendTicketEmail($arguments: AWSJSON) {
    sendTicketEmail(arguments: $arguments)
  }
`;
export const eventCrawler = /* GraphQL */ `
  query EventCrawler($arguments: AWSJSON) {
    eventCrawler(arguments: $arguments)
  }
`;
export const sendContactFormEmail = /* GraphQL */ `
  query SendContactFormEmail($arguments: AWSJSON) {
    sendContactFormEmail(arguments: $arguments)
  }
`;
export const deleteUserAccount = /* GraphQL */ `
  query DeleteUserAccount($arguments: AWSJSON) {
    deleteUserAccount(arguments: $arguments)
  }
`;
export const sendSMS = /* GraphQL */ `
  query SendSMS($arguments: AWSJSON) {
    sendSMS(arguments: $arguments)
  }
`;
export const addUserToGroup = /* GraphQL */ `
  query AddUserToGroup($groupName: String, $env: String, $userName: String) {
    addUserToGroup(groupName: $groupName, env: $env, userName: $userName)
  }
`;
export const emailerScraper = /* GraphQL */ `
  query EmailerScraper($website: String) {
    emailerScraper(website: $website)
  }
`;
export const stripeCreateCustomer = /* GraphQL */ `
  query StripeCreateCustomer($arguments: AWSJSON) {
    stripeCreateCustomer(arguments: $arguments)
  }
`;
export const stripeRemoveCustomer = /* GraphQL */ `
  query StripeRemoveCustomer($arguments: AWSJSON) {
    stripeRemoveCustomer(arguments: $arguments)
  }
`;
export const stripeCreateCustomerPaymentIntent = /* GraphQL */ `
  query StripeCreateCustomerPaymentIntent($input: AWSJSON) {
    StripeCreateCustomerPaymentIntent(input: $input)
  }
`;
export const stripeCreateCustomerSetupIntent = /* GraphQL */ `
  query StripeCreateCustomerSetupIntent($input: AWSJSON) {
    StripeCreateCustomerSetupIntent(input: $input)
  }
`;
export const stripeConfirmCustomerSetupIntent = /* GraphQL */ `
  query StripeConfirmCustomerSetupIntent($input: AWSJSON) {
    StripeConfirmCustomerSetupIntent(input: $input)
  }
`;
export const stripeListCustomerPaymentMethods = /* GraphQL */ `
  query StripeListCustomerPaymentMethods($input: AWSJSON) {
    stripeListCustomerPaymentMethods(input: $input)
  }
`;
export const stripeRemovePaymentMethod = /* GraphQL */ `
  query StripeRemovePaymentMethod($paymentMethodId: String, $env: String) {
    stripeRemovePaymentMethod(paymentMethodId: $paymentMethodId, env: $env)
  }
`;
export const updateCounter = /* GraphQL */ `
  query UpdateCounter($id: String, $increment: Int, $env: String) {
    updateCounter(id: $id, increment: $increment, env: $env)
  }
`;
export const addUserToEntityListOwners = /* GraphQL */ `
  query AddUserToEntityListOwners(
    $id: String
    $code: String
    $userId: String
    $cognitoUsername: String
    $placeFirst: Boolean
    $env: String
  ) {
    addUserToEntityListOwners(
      id: $id
      code: $code
      userId: $userId
      cognitoUsername: $cognitoUsername
      placeFirst: $placeFirst
      env: $env
    )
  }
`;
export const deleteSharedUserList = /* GraphQL */ `
  query DeleteSharedUserList(
    $id: String
    $userId: String
    $cognitoUsername: String
    $env: String
  ) {
    deleteSharedUserList(
      id: $id
      userId: $userId
      cognitoUsername: $cognitoUsername
      env: $env
    )
  }
`;
export const sendEmailsWithTemplate = /* GraphQL */ `
  query SendEmailsWithTemplate(
    $template: String
    $subject: String
    $emails: [EmailVenueName]
    $sender: String
    $env: String
  ) {
    sendEmailsWithTemplate(
      template: $template
      subject: $subject
      emails: $emails
      sender: $sender
      env: $env
    )
  }
`;
export const stripeSubscriptionFunction = /* GraphQL */ `
  query StripeSubscriptionFunction($input: AWSJSON) {
    stripeSubscriptionFunction(input: $input)
  }
`;
export const stripeListAllProducts = /* GraphQL */ `
  query StripeListAllProducts($ids: [String], $env: String) {
    stripeListAllProducts(ids: $ids, env: $env)
  }
`;
export const fidelBrandFunction = /* GraphQL */ `
  query FidelBrandFunction($input: AWSJSON) {
    FidelBrandFunction(input: $input)
  }
`;
export const fideLocationFunction = /* GraphQL */ `
  query FideLocationFunction($input: AWSJSON) {
    fideLocationFunction(input: $input)
  }
`;
export const claimLoyaltyReward = /* GraphQL */ `
  query ClaimLoyaltyReward($input: AWSJSON) {
    claimLoyaltyReward(input: $input)
  }
`;
export const toggleLoyaltyCardSNS = /* GraphQL */ `
  query ToggleLoyaltyCardSNS($input: AWSJSON) {
    toggleLoyaltyCardSNS(input: $input)
  }
`;
export const fidelRemoveUserCard = /* GraphQL */ `
  query FidelRemoveUserCard($input: AWSJSON) {
    fidelRemoveUserCard(input: $input)
  }
`;
export const googlePlacesSearch = /* GraphQL */ `
  query GooglePlacesSearch($query: String) {
    googlePlacesSearch(query: $query)
  }
`;
export const addTotaskScheduler = /* GraphQL */ `
  query AddTotaskScheduler($input: AWSJSON) {
    addTotaskScheduler(input: $input)
  }
`;
export const fidelBrandUser = /* GraphQL */ `
  query FidelBrandUser($input: AWSJSON) {
    fidelBrandUser(input: $input)
  }
`;
export const fidelOfferFunction = /* GraphQL */ `
  query FidelOfferFunction($input: AWSJSON) {
    FidelOfferFunction(input: $input)
  }
`;
export const addViewCount = /* GraphQL */ `
  query AddViewCount($input: AWSJSON) {
    addViewCount(input: $input)
  }
`;
export const subscribeToEmailDatabase = /* GraphQL */ `
  query SubscribeToEmailDatabase($input: AWSJSON) {
    subscribeToEmailDatabase(input: $input)
  }
`;
export const registerInvitedSignUp = /* GraphQL */ `
  query RegisterInvitedSignUp($arguments: AWSJSON) {
    registerInvitedSignUp(arguments: $arguments)
  }
`;
export const socialsConvertCodeToAccessToken = /* GraphQL */ `
  query SocialsConvertCodeToAccessToken($arguments: AWSJSON) {
    socialsConvertCodeToAccessToken(arguments: $arguments)
  }
`;
export const sendEmailFromPing = /* GraphQL */ `
  query SendEmailFromPing($arguments: AWSJSON) {
    sendEmailFromPing(arguments: $arguments)
  }
`;
export const tiktokProfileDataUsingAccessToken = /* GraphQL */ `
  query TiktokProfileDataUsingAccessToken($arguments: AWSJSON) {
    tiktokProfileDataUsingAccessToken(arguments: $arguments)
  }
`;
export const tiktokUserVideosUsingAccessToken = /* GraphQL */ `
  query TiktokUserVideosUsingAccessToken($arguments: AWSJSON) {
    tiktokUserVideosUsingAccessToken(arguments: $arguments)
  }
`;
export const tiktokVideosInsightsUsingAccessToken = /* GraphQL */ `
  query TiktokVideosInsightsUsingAccessToken($arguments: AWSJSON) {
    tiktokVideosInsightsUsingAccessToken(arguments: $arguments)
  }
`;
export const cancelStripeFreeTrial = /* GraphQL */ `
  query CancelStripeFreeTrial($arguments: AWSJSON) {
    cancelStripeFreeTrial(arguments: $arguments)
  }
`;
export const createFirebaseShortLink = /* GraphQL */ `
  query CreateFirebaseShortLink($arguments: AWSJSON) {
    createFirebaseShortLink(arguments: $arguments)
  }
`;
export const updateUserCognitoPassword = /* GraphQL */ `
  query UpdateUserCognitoPassword($arguments: AWSJSON) {
    updateUserCognitoPassword(arguments: $arguments)
  }
`;
export const sendPublisherWelcomeEmail = /* GraphQL */ `
  query SendPublisherWelcomeEmail($arguments: AWSJSON) {
    sendPublisherWelcomeEmail(arguments: $arguments)
  }
`;
export const getUserInteraction = /* GraphQL */ `
  query GetUserInteraction($id: ID!) {
    getUserInteraction(id: $id) {
      id
      type
      itemId
      categories
      itemType
      city
      itemTitle
      userId
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listUserInteractions = /* GraphQL */ `
  query ListUserInteractions(
    $filter: ModelUserInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInteractions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        itemId
        categories
        itemType
        city
        itemTitle
        userId
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTrackingActivityStat = /* GraphQL */ `
  query GetTrackingActivityStat($id: ID!) {
    getTrackingActivityStat(id: $id) {
      id
      action
      itemType
      itemId
      location {
        lat
        lon
      }
      gender
      city
      owners
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listTrackingActivityStats = /* GraphQL */ `
  query ListTrackingActivityStats(
    $filter: ModelTrackingActivityStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrackingActivityStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        action
        itemType
        itemId
        location {
          lat
          lon
        }
        gender
        city
        owners
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConvoLink = /* GraphQL */ `
  query GetConvoLink($id: ID!) {
    getConvoLink(id: $id) {
      id
      status
      createdAt
      updatedAt
      owners
      messages {
        items {
          id
          messageConversationId
          content
          contentType
          author
          entity
          offerId
          pingfluencerId
          publisherId
          userId
          receiver
          owners
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      publisherId
      claimedOfferId
      publisherLastRead
      pingfluencerLastRead
      entityId
      userId
      pingfluencerId
      pingfluencer {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      offer {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
    }
  }
`;
export const listConvoLinks = /* GraphQL */ `
  query ListConvoLinks(
    $filter: ModelConvoLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConvoLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        createdAt
        updatedAt
        owners
        messages {
          nextToken
        }
        publisherId
        claimedOfferId
        publisherLastRead
        pingfluencerLastRead
        entityId
        userId
        pingfluencerId
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        offer {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      messageConversationId
      conversation {
        id
        status
        createdAt
        updatedAt
        owners
        messages {
          nextToken
        }
        publisherId
        claimedOfferId
        publisherLastRead
        pingfluencerLastRead
        entityId
        userId
        pingfluencerId
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        offer {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
      }
      content
      contentType
      author
      entity
      offerId
      pingfluencerId
      publisherId
      userId
      receiver
      owners
      status
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageConversationId
        conversation {
          id
          status
          createdAt
          updatedAt
          owners
          publisherId
          claimedOfferId
          publisherLastRead
          pingfluencerLastRead
          entityId
          userId
          pingfluencerId
        }
        content
        contentType
        author
        entity
        offerId
        pingfluencerId
        publisherId
        userId
        receiver
        owners
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCardTransactions = /* GraphQL */ `
  query GetCardTransactions($id: ID!) {
    getCardTransactions(id: $id) {
      currency
      auth
      type
      programId
      id
      accountId
      userEntityId
      userPublisherId
      userId
      owner
      owners
      entityId
      publisherId
      created
      authCode
      updated
      amount
      cleared
      visits
      wallet
      offer
      ttl
      datetime
      card {
        id
        firstNumbers
        lastNumbers
        scheme
      }
      location {
        address
        city
        countryCode
        id
        geolocation
        postcode
        timezone
        metadata {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
      }
      brand {
        id
        name
        logoURL
        metadata {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
      }
      identifiers {
        amexApprovalCode
        mastercardAuthCode
        mastercardRefNumber
        mastercardTransactionSequenceNumber
        MID
        visaAuthCode
      }
      createdAt
      updatedAt
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const listCardTransactionss = /* GraphQL */ `
  query ListCardTransactionss(
    $filter: ModelCardTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCardTransactionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        currency
        auth
        type
        programId
        id
        accountId
        userEntityId
        userPublisherId
        userId
        owner
        owners
        entityId
        publisherId
        created
        authCode
        updated
        amount
        cleared
        visits
        wallet
        offer
        ttl
        datetime
        card {
          id
          firstNumbers
          lastNumbers
          scheme
        }
        location {
          address
          city
          countryCode
          id
          geolocation
          postcode
          timezone
        }
        brand {
          id
          name
          logoURL
        }
        identifiers {
          amexApprovalCode
          mastercardAuthCode
          mastercardRefNumber
          mastercardTransactionSequenceNumber
          MID
          visaAuthCode
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const getLoyaltyUserCounters = /* GraphQL */ `
  query GetLoyaltyUserCounters($id: ID!) {
    getLoyaltyUserCounters(id: $id) {
      id
      date
      publisherId
      entityId
      userId
      userDob
      userAge
      visits
      idTypeKey
      userGender
      spend
      ttl
      period
      owners
      owner
      currency
      transactions
      updatedAt
      createdAt
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const listLoyaltyUserCounterss = /* GraphQL */ `
  query ListLoyaltyUserCounterss(
    $filter: ModelLoyaltyUserCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoyaltyUserCounterss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        publisherId
        entityId
        userId
        userDob
        userAge
        visits
        idTypeKey
        userGender
        spend
        ttl
        period
        owners
        owner
        currency
        transactions
        updatedAt
        createdAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const getLoyaltyEntityCounters = /* GraphQL */ `
  query GetLoyaltyEntityCounters($id: ID!) {
    getLoyaltyEntityCounters(id: $id) {
      id
      date
      publisherId
      entityId
      spend
      ttl
      idTypeKey
      genderCount {
        m
        f
        nb
        t
        o
      }
      ageCount {
        below18
        age18To25
        age26To30
        age31To35
        age36To40
        age41To45
        Over45
      }
      visits
      currency
      transactions
      paid
      invoice
      owners
      owner
      updatedAt
      createdAt
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const listLoyaltyEntityCounterss = /* GraphQL */ `
  query ListLoyaltyEntityCounterss(
    $filter: ModelLoyaltyEntityCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoyaltyEntityCounterss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        publisherId
        entityId
        spend
        ttl
        idTypeKey
        genderCount {
          m
          f
          nb
          t
          o
        }
        ageCount {
          below18
          age18To25
          age26To30
          age31To35
          age36To40
          age41To45
          Over45
        }
        visits
        currency
        transactions
        paid
        invoice
        owners
        owner
        updatedAt
        createdAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const getPingfluencerReview = /* GraphQL */ `
  query GetPingfluencerReview($id: ID!) {
    getPingfluencerReview(id: $id) {
      id
      score
      scores {
        speed {
          score
          comment
        }
        quality {
          score
          comment
        }
        captions {
          score
          comment
        }
        deliverables {
          score
          comment
        }
        behaviour {
          score
          comment
        }
        overall
      }
      didntDeliverContent
      dateOfVisit
      pingfluencerId
      claimedOfferId
      entityId
      publisherId
      status
      verified
      createdAt
      updatedAt
      pingfluencer {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      claimedOffer {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
    }
  }
`;
export const listPingfluencerReviews = /* GraphQL */ `
  query ListPingfluencerReviews(
    $filter: ModelPingfluencerReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPingfluencerReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        score
        scores {
          overall
        }
        didntDeliverContent
        dateOfVisit
        pingfluencerId
        claimedOfferId
        entityId
        publisherId
        status
        verified
        createdAt
        updatedAt
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        claimedOffer {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
      }
      nextToken
    }
  }
`;
export const getScheduleQueue = /* GraphQL */ `
  query GetScheduleQueue($id: ID!) {
    getScheduleQueue(id: $id) {
      id
      type
      due
      action
      Payload
      createdAt
      updatedAt
    }
  }
`;
export const listScheduleQueues = /* GraphQL */ `
  query ListScheduleQueues(
    $filter: ModelScheduleQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduleQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        due
        action
        Payload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStripePayment = /* GraphQL */ `
  query GetStripePayment($id: ID!) {
    getStripePayment(id: $id) {
      id
      startDate
      endDate
      type
      owner
      amount
      amount_total
      amount_due
      due_date
      amount_paid
      currency
      receipt_url
      invoice_url
      invoice_id
      additionalInvoiceItems
      status
      intentId
      userId
      pingfluencerId
      publisherId
      productId
      priceId
      subscriptionItemId
      metaData
      ttl
      createdAt
      updatedAt
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const listStripePayments = /* GraphQL */ `
  query ListStripePayments(
    $filter: ModelStripePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripePayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startDate
        endDate
        type
        owner
        amount
        amount_total
        amount_due
        due_date
        amount_paid
        currency
        receipt_url
        invoice_url
        invoice_id
        additionalInvoiceItems
        status
        intentId
        userId
        pingfluencerId
        publisherId
        productId
        priceId
        subscriptionItemId
        metaData
        ttl
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStripeSubscription = /* GraphQL */ `
  query GetStripeSubscription($id: ID!) {
    getStripeSubscription(id: $id) {
      id
      publisherId
      owner
      current_period_end
      current_period_start
      type
      paused
      priceId
      bespokePrice {
        amount
        trial
        interval
        currency
      }
      bespokeConfig {
        places
        offers
        claims
        instant_redeem_only
        booking
        content
        invite
        support
        places_additional_fee
      }
      productId
      cancel_at_period_end
      subscriptionItemId
      entitiesIncluded
      quantity
      latest_invoice
      status
      trial_start
      trial_end
      isBespoke
      ttl
      createdAt
      updatedAt
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const listStripeSubscriptions = /* GraphQL */ `
  query ListStripeSubscriptions(
    $filter: ModelStripeSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripeSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publisherId
        owner
        current_period_end
        current_period_start
        type
        paused
        priceId
        bespokePrice {
          amount
          trial
          interval
          currency
        }
        bespokeConfig {
          places
          offers
          claims
          instant_redeem_only
          booking
          content
          invite
          support
          places_additional_fee
        }
        productId
        cancel_at_period_end
        subscriptionItemId
        entitiesIncluded
        quantity
        latest_invoice
        status
        trial_start
        trial_end
        isBespoke
        ttl
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserReport = /* GraphQL */ `
  query GetUserReport($id: ID!) {
    getUserReport(id: $id) {
      id
      userId
      message
      targetId
      targetType
      createdAt
      updatedAt
    }
  }
`;
export const listUserReports = /* GraphQL */ `
  query ListUserReports(
    $filter: ModelUserReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        message
        targetId
        targetType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      userId
      pingfluencerId
      bookingReference
      eventId
      repeatEventId
      repeatEventOccurencId
      eventType
      eventStartTimestamp
      price {
        price
        currency {
          code
          name
          symbol
        }
      }
      chargeId
      paymentIntentId
      content
      ticketActivasted
      userName
      additionalOptions {
        title
        chosenOption
      }
      tier
      variation
      owners
      status
      ttl
      test
      refunded
      createdAt
      updatedAt
      event {
        id
        title
        entityId
        logo
        headerVideos
        publisherId
        area
        sponsors {
          id
          title
          logo
          desc
        }
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        genres
        dateTime
        startDate
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        endDate
        closingTime
        rules
        email
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        pushTimestamps
        ownerType
        tickets {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        influencerInfo {
          hashtags
          tags
          details
        }
        username
        menu {
          id
          title
          status
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        variations {
          nextToken
        }
      }
      repeatEvent {
        id
        title
        entityId
        area
        headerVideos
        publisherId
        sponsors {
          id
          title
          logo
          desc
        }
        logo
        subtitle
        description
        type
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        categories
        genres
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        firstEventDate
        lastEventDate
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        pushTimestamps
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        tickets {
          nextToken
        }
        occurencesRules {
          title
          startTime
          endTime
          typeOfRepeat
          repeatInterval
          daysOfWeek
          daysOfMonth
          weeksOfMonth
          weeksDaysOfMonth
          occurenceTotalRepeat
          startDate
          endDate
          usesEntityTables
          quantity
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        influencerInfo {
          hashtags
          tags
          details
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        occurences {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      repeatEventOccurence {
        id
        repeatEventId
        startDate
        endDate
        owners
        isSoldOut
        totalTicketsSold
        canBuyTickets
        tickets {
          nextToken
        }
        ttl
        createdAt
        updatedAt
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        attendees {
          nextToken
        }
        variations {
          nextToken
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        pingfluencerId
        bookingReference
        eventId
        repeatEventId
        repeatEventOccurencId
        eventType
        eventStartTimestamp
        price {
          price
        }
        chargeId
        paymentIntentId
        content
        ticketActivasted
        userName
        additionalOptions {
          title
          chosenOption
        }
        tier
        variation
        owners
        status
        ttl
        test
        refunded
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEventOccurence {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const getUserSavedItem = /* GraphQL */ `
  query GetUserSavedItem($id: ID!) {
    getUserSavedItem(id: $id) {
      id
      type
      itemId
      title
      image
      authorId
      author
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listUserSavedItems = /* GraphQL */ `
  query ListUserSavedItems(
    $filter: ModelUserSavedItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSavedItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        itemId
        title
        image
        authorId
        author
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listtrackingActivityByDate = /* GraphQL */ `
  query ListtrackingActivityByDate(
    $itemId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTrackingActivityStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listtrackingActivityByDate(
      itemId: $itemId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        action
        itemType
        itemId
        location {
          lat
          lon
        }
        gender
        city
        owners
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const blogPostsByEntityQuery = /* GraphQL */ `
  query BlogPostsByEntityQuery(
    $entityId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogPostsByEntityQuery(
      entityId: $entityId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        blogPostId
        summary
        title
        order
        createdAt
        updatedAt
        blogPost {
          id
          postType
          title
          desc
          city
          area
          catgories
          subcategories
          cuisine
          headerImg
          readCount
          shareCount
          metaDesc
          metaTitle
          videoUrl
          instagram
          tiktok
          twitter
          website
          facebook
          userId
          publishStatus
          owners
          ttl
          createdAt
          updatedAt
        }
        owner
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listCardTransactionsByUser = /* GraphQL */ `
  query ListCardTransactionsByUser(
    $userId: String
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCardTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCardTransactionsByUser(
      userId: $userId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        currency
        auth
        type
        programId
        id
        accountId
        userEntityId
        userPublisherId
        userId
        owner
        owners
        entityId
        publisherId
        created
        authCode
        updated
        amount
        cleared
        visits
        wallet
        offer
        ttl
        datetime
        card {
          id
          firstNumbers
          lastNumbers
          scheme
        }
        location {
          address
          city
          countryCode
          id
          geolocation
          postcode
          timezone
        }
        brand {
          id
          name
          logoURL
        }
        identifiers {
          amexApprovalCode
          mastercardAuthCode
          mastercardRefNumber
          mastercardTransactionSequenceNumber
          MID
          visaAuthCode
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listCardTransactionsByPublisher = /* GraphQL */ `
  query ListCardTransactionsByPublisher(
    $publisherId: String
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCardTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCardTransactionsByPublisher(
      publisherId: $publisherId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        currency
        auth
        type
        programId
        id
        accountId
        userEntityId
        userPublisherId
        userId
        owner
        owners
        entityId
        publisherId
        created
        authCode
        updated
        amount
        cleared
        visits
        wallet
        offer
        ttl
        datetime
        card {
          id
          firstNumbers
          lastNumbers
          scheme
        }
        location {
          address
          city
          countryCode
          id
          geolocation
          postcode
          timezone
        }
        brand {
          id
          name
          logoURL
        }
        identifiers {
          amexApprovalCode
          mastercardAuthCode
          mastercardRefNumber
          mastercardTransactionSequenceNumber
          MID
          visaAuthCode
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listCardTransactionsByEntity = /* GraphQL */ `
  query ListCardTransactionsByEntity(
    $entityId: ID
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCardTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCardTransactionsByEntity(
      entityId: $entityId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        currency
        auth
        type
        programId
        id
        accountId
        userEntityId
        userPublisherId
        userId
        owner
        owners
        entityId
        publisherId
        created
        authCode
        updated
        amount
        cleared
        visits
        wallet
        offer
        ttl
        datetime
        card {
          id
          firstNumbers
          lastNumbers
          scheme
        }
        location {
          address
          city
          countryCode
          id
          geolocation
          postcode
          timezone
        }
        brand {
          id
          name
          logoURL
        }
        identifiers {
          amexApprovalCode
          mastercardAuthCode
          mastercardRefNumber
          mastercardTransactionSequenceNumber
          MID
          visaAuthCode
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listcardTransactionsByUserAndPublisher = /* GraphQL */ `
  query ListcardTransactionsByUserAndPublisher(
    $userPublisherId: String
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCardTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listcardTransactionsByUserAndPublisher(
      userPublisherId: $userPublisherId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        currency
        auth
        type
        programId
        id
        accountId
        userEntityId
        userPublisherId
        userId
        owner
        owners
        entityId
        publisherId
        created
        authCode
        updated
        amount
        cleared
        visits
        wallet
        offer
        ttl
        datetime
        card {
          id
          firstNumbers
          lastNumbers
          scheme
        }
        location {
          address
          city
          countryCode
          id
          geolocation
          postcode
          timezone
        }
        brand {
          id
          name
          logoURL
        }
        identifiers {
          amexApprovalCode
          mastercardAuthCode
          mastercardRefNumber
          mastercardTransactionSequenceNumber
          MID
          visaAuthCode
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listcardTransactionsByUserAndEntity = /* GraphQL */ `
  query ListcardTransactionsByUserAndEntity(
    $userEntityId: String
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCardTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listcardTransactionsByUserAndEntity(
      userEntityId: $userEntityId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        currency
        auth
        type
        programId
        id
        accountId
        userEntityId
        userPublisherId
        userId
        owner
        owners
        entityId
        publisherId
        created
        authCode
        updated
        amount
        cleared
        visits
        wallet
        offer
        ttl
        datetime
        card {
          id
          firstNumbers
          lastNumbers
          scheme
        }
        location {
          address
          city
          countryCode
          id
          geolocation
          postcode
          timezone
        }
        brand {
          id
          name
          logoURL
        }
        identifiers {
          amexApprovalCode
          mastercardAuthCode
          mastercardRefNumber
          mastercardTransactionSequenceNumber
          MID
          visaAuthCode
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listbyLoyaltyCounterPublisher = /* GraphQL */ `
  query ListbyLoyaltyCounterPublisher(
    $publisherId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyUserCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listbyLoyaltyCounterPublisher(
      publisherId: $publisherId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        publisherId
        entityId
        userId
        userDob
        userAge
        visits
        idTypeKey
        userGender
        spend
        ttl
        period
        owners
        owner
        currency
        transactions
        updatedAt
        createdAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listbyLoyaltyCounterEntity = /* GraphQL */ `
  query ListbyLoyaltyCounterEntity(
    $entityId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyUserCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listbyLoyaltyCounterEntity(
      entityId: $entityId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        publisherId
        entityId
        userId
        userDob
        userAge
        visits
        idTypeKey
        userGender
        spend
        ttl
        period
        owners
        owner
        currency
        transactions
        updatedAt
        createdAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listbyLoyaltyCounterUser = /* GraphQL */ `
  query ListbyLoyaltyCounterUser(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyUserCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listbyLoyaltyCounterUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        publisherId
        entityId
        userId
        userDob
        userAge
        visits
        idTypeKey
        userGender
        spend
        ttl
        period
        owners
        owner
        currency
        transactions
        updatedAt
        createdAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listLoyaltyCountersByType = /* GraphQL */ `
  query ListLoyaltyCountersByType(
    $idTypeKey: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyUserCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoyaltyCountersByType(
      idTypeKey: $idTypeKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        publisherId
        entityId
        userId
        userDob
        userAge
        visits
        idTypeKey
        userGender
        spend
        ttl
        period
        owners
        owner
        currency
        transactions
        updatedAt
        createdAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listyLoyaltyEntityCountersPublisher = /* GraphQL */ `
  query ListyLoyaltyEntityCountersPublisher(
    $publisherId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyEntityCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listyLoyaltyEntityCountersPublisher(
      publisherId: $publisherId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        publisherId
        entityId
        spend
        ttl
        idTypeKey
        genderCount {
          m
          f
          nb
          t
          o
        }
        ageCount {
          below18
          age18To25
          age26To30
          age31To35
          age36To40
          age41To45
          Over45
        }
        visits
        currency
        transactions
        paid
        invoice
        owners
        owner
        updatedAt
        createdAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listyLoyaltyEntityCountersByDatePublisher = /* GraphQL */ `
  query ListyLoyaltyEntityCountersByDatePublisher(
    $publisherId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyEntityCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listyLoyaltyEntityCountersByDatePublisher(
      publisherId: $publisherId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        publisherId
        entityId
        spend
        ttl
        idTypeKey
        genderCount {
          m
          f
          nb
          t
          o
        }
        ageCount {
          below18
          age18To25
          age26To30
          age31To35
          age36To40
          age41To45
          Over45
        }
        visits
        currency
        transactions
        paid
        invoice
        owners
        owner
        updatedAt
        createdAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listbyLoyaltyEntityCountersEntity = /* GraphQL */ `
  query ListbyLoyaltyEntityCountersEntity(
    $entityId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyEntityCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listbyLoyaltyEntityCountersEntity(
      entityId: $entityId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        publisherId
        entityId
        spend
        ttl
        idTypeKey
        genderCount {
          m
          f
          nb
          t
          o
        }
        ageCount {
          below18
          age18To25
          age26To30
          age31To35
          age36To40
          age41To45
          Over45
        }
        visits
        currency
        transactions
        paid
        invoice
        owners
        owner
        updatedAt
        createdAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listLoyaltyEntityCountersByType = /* GraphQL */ `
  query ListLoyaltyEntityCountersByType(
    $idTypeKey: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyEntityCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoyaltyEntityCountersByType(
      idTypeKey: $idTypeKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        publisherId
        entityId
        spend
        ttl
        idTypeKey
        genderCount {
          m
          f
          nb
          t
          o
        }
        ageCount {
          below18
          age18To25
          age26To30
          age31To35
          age36To40
          age41To45
          Over45
        }
        visits
        currency
        transactions
        paid
        invoice
        owners
        owner
        updatedAt
        createdAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const getStripePaymentsByUser = /* GraphQL */ `
  query GetStripePaymentsByUser(
    $userId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStripePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStripePaymentsByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        type
        owner
        amount
        amount_total
        amount_due
        due_date
        amount_paid
        currency
        receipt_url
        invoice_url
        invoice_id
        additionalInvoiceItems
        status
        intentId
        userId
        pingfluencerId
        publisherId
        productId
        priceId
        subscriptionItemId
        metaData
        ttl
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStripePaymentsByPingfluencer = /* GraphQL */ `
  query GetStripePaymentsByPingfluencer(
    $pingfluencerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStripePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStripePaymentsByPingfluencer(
      pingfluencerId: $pingfluencerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        type
        owner
        amount
        amount_total
        amount_due
        due_date
        amount_paid
        currency
        receipt_url
        invoice_url
        invoice_id
        additionalInvoiceItems
        status
        intentId
        userId
        pingfluencerId
        publisherId
        productId
        priceId
        subscriptionItemId
        metaData
        ttl
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStripePaymentsByPublisher = /* GraphQL */ `
  query GetStripePaymentsByPublisher(
    $publisherId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStripePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStripePaymentsByPublisher(
      publisherId: $publisherId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        type
        owner
        amount
        amount_total
        amount_due
        due_date
        amount_paid
        currency
        receipt_url
        invoice_url
        invoice_id
        additionalInvoiceItems
        status
        intentId
        userId
        pingfluencerId
        publisherId
        productId
        priceId
        subscriptionItemId
        metaData
        ttl
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStripeSubscriptionsByPublisherId = /* GraphQL */ `
  query GetStripeSubscriptionsByPublisherId(
    $publisherId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStripeSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStripeSubscriptionsByPublisherId(
      publisherId: $publisherId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publisherId
        owner
        current_period_end
        current_period_start
        type
        paused
        priceId
        bespokePrice {
          amount
          trial
          interval
          currency
        }
        bespokeConfig {
          places
          offers
          claims
          instant_redeem_only
          booking
          content
          invite
          support
          places_additional_fee
        }
        productId
        cancel_at_period_end
        subscriptionItemId
        entitiesIncluded
        quantity
        latest_invoice
        status
        trial_start
        trial_end
        isBespoke
        ttl
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStripeSubscriptionsByProductId = /* GraphQL */ `
  query GetStripeSubscriptionsByProductId(
    $productId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStripeSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStripeSubscriptionsByProductId(
      productId: $productId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publisherId
        owner
        current_period_end
        current_period_start
        type
        paused
        priceId
        bespokePrice {
          amount
          trial
          interval
          currency
        }
        bespokeConfig {
          places
          offers
          claims
          instant_redeem_only
          booking
          content
          invite
          support
          places_additional_fee
        }
        productId
        cancel_at_period_end
        subscriptionItemId
        entitiesIncluded
        quantity
        latest_invoice
        status
        trial_start
        trial_end
        isBespoke
        ttl
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listReportsByDate = /* GraphQL */ `
  query ListReportsByDate(
    $createdAt: Float
    $sortDirection: ModelSortDirection
    $filter: ModelUserReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportsByDate(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        message
        targetId
        targetType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEventTickets = /* GraphQL */ `
  query ListEventTickets(
    $eventId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventTickets(
      eventId: $eventId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        bookingReference
        eventId
        repeatEventId
        repeatEventOccurencId
        eventType
        eventStartTimestamp
        price {
          price
        }
        chargeId
        paymentIntentId
        content
        ticketActivasted
        userName
        additionalOptions {
          title
          chosenOption
        }
        tier
        variation
        owners
        status
        ttl
        test
        refunded
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEventOccurence {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listRepeatEventTickets = /* GraphQL */ `
  query ListRepeatEventTickets(
    $repeatEventId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRepeatEventTickets(
      repeatEventId: $repeatEventId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        bookingReference
        eventId
        repeatEventId
        repeatEventOccurencId
        eventType
        eventStartTimestamp
        price {
          price
        }
        chargeId
        paymentIntentId
        content
        ticketActivasted
        userName
        additionalOptions {
          title
          chosenOption
        }
        tier
        variation
        owners
        status
        ttl
        test
        refunded
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEventOccurence {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listUserTickets = /* GraphQL */ `
  query ListUserTickets(
    $userId: ID
    $eventStartTimestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTickets(
      userId: $userId
      eventStartTimestamp: $eventStartTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        bookingReference
        eventId
        repeatEventId
        repeatEventOccurencId
        eventType
        eventStartTimestamp
        price {
          price
        }
        chargeId
        paymentIntentId
        content
        ticketActivasted
        userName
        additionalOptions {
          title
          chosenOption
        }
        tier
        variation
        owners
        status
        ttl
        test
        refunded
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEventOccurence {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listPingfluencerTickets = /* GraphQL */ `
  query ListPingfluencerTickets(
    $pingfluencerId: ID
    $eventStartTimestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPingfluencerTickets(
      pingfluencerId: $pingfluencerId
      eventStartTimestamp: $eventStartTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        bookingReference
        eventId
        repeatEventId
        repeatEventOccurencId
        eventType
        eventStartTimestamp
        price {
          price
        }
        chargeId
        paymentIntentId
        content
        ticketActivasted
        userName
        additionalOptions {
          title
          chosenOption
        }
        tier
        variation
        owners
        status
        ttl
        test
        refunded
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEventOccurence {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listChargeIdTickets = /* GraphQL */ `
  query ListChargeIdTickets(
    $chargeId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChargeIdTickets(
      chargeId: $chargeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        bookingReference
        eventId
        repeatEventId
        repeatEventOccurencId
        eventType
        eventStartTimestamp
        price {
          price
        }
        chargeId
        paymentIntentId
        content
        ticketActivasted
        userName
        additionalOptions {
          title
          chosenOption
        }
        tier
        variation
        owners
        status
        ttl
        test
        refunded
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEventOccurence {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listTicketsByName = /* GraphQL */ `
  query ListTicketsByName(
    $userName: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByName(
      userName: $userName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        bookingReference
        eventId
        repeatEventId
        repeatEventOccurencId
        eventType
        eventStartTimestamp
        price {
          price
        }
        chargeId
        paymentIntentId
        content
        ticketActivasted
        userName
        additionalOptions {
          title
          chosenOption
        }
        tier
        variation
        owners
        status
        ttl
        test
        refunded
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEventOccurence {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listTicketsByPaymentIntent = /* GraphQL */ `
  query ListTicketsByPaymentIntent(
    $paymentIntentId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByPaymentIntent(
      paymentIntentId: $paymentIntentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        bookingReference
        eventId
        repeatEventId
        repeatEventOccurencId
        eventType
        eventStartTimestamp
        price {
          price
        }
        chargeId
        paymentIntentId
        content
        ticketActivasted
        userName
        additionalOptions {
          title
          chosenOption
        }
        tier
        variation
        owners
        status
        ttl
        test
        refunded
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEventOccurence {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listTicketsByBookingReference = /* GraphQL */ `
  query ListTicketsByBookingReference(
    $bookingReference: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByBookingReference(
      bookingReference: $bookingReference
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        bookingReference
        eventId
        repeatEventId
        repeatEventOccurencId
        eventType
        eventStartTimestamp
        price {
          price
        }
        chargeId
        paymentIntentId
        content
        ticketActivasted
        userName
        additionalOptions {
          title
          chosenOption
        }
        tier
        variation
        owners
        status
        ttl
        test
        refunded
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEventOccurence {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listTicketsByVariation = /* GraphQL */ `
  query ListTicketsByVariation(
    $variation: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByVariation(
      variation: $variation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        bookingReference
        eventId
        repeatEventId
        repeatEventOccurencId
        eventType
        eventStartTimestamp
        price {
          price
        }
        chargeId
        paymentIntentId
        content
        ticketActivasted
        userName
        additionalOptions {
          title
          chosenOption
        }
        tier
        variation
        owners
        status
        ttl
        test
        refunded
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEventOccurence {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listUserSavedItemsQuery = /* GraphQL */ `
  query ListUserSavedItemsQuery(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserSavedItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSavedItemsQuery(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        itemId
        title
        image
        authorId
        author
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchStripePayments = /* GraphQL */ `
  query SearchStripePayments(
    $filter: SearchableStripePaymentFilterInput
    $sort: SearchableStripePaymentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchStripePayments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        startDate
        endDate
        type
        owner
        amount
        amount_total
        amount_due
        due_date
        amount_paid
        currency
        receipt_url
        invoice_url
        invoice_id
        additionalInvoiceItems
        status
        intentId
        userId
        pingfluencerId
        publisherId
        productId
        priceId
        subscriptionItemId
        metaData
        ttl
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getCounters = /* GraphQL */ `
  query GetCounters($id: ID!) {
    getCounters(id: $id) {
      id
      parentId
      itemId
      attending
      views
      impressions
      seen
      week
      booked
      transactions
      idTypeKey
      bookings_clicked
      subscribed
      unsubscribed
      checkins
      list
      shared
      visits
      reviews
      offersClaimed
      offersRedeemed
      loyaltyPoints
      loyaltyCard
      scan
      score
      ttl
      reviewScore
      date
      updatedAt
      createdAt
    }
  }
`;
export const listCounterss = /* GraphQL */ `
  query ListCounterss(
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCounterss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentId
        itemId
        attending
        views
        impressions
        seen
        week
        booked
        transactions
        idTypeKey
        bookings_clicked
        subscribed
        unsubscribed
        checkins
        list
        shared
        visits
        reviews
        offersClaimed
        offersRedeemed
        loyaltyPoints
        loyaltyCard
        scan
        score
        ttl
        reviewScore
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listCountersByParent = /* GraphQL */ `
  query ListCountersByParent(
    $parentId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountersByParent(
      parentId: $parentId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        itemId
        attending
        views
        impressions
        seen
        week
        booked
        transactions
        idTypeKey
        bookings_clicked
        subscribed
        unsubscribed
        checkins
        list
        shared
        visits
        reviews
        offersClaimed
        offersRedeemed
        loyaltyPoints
        loyaltyCard
        scan
        score
        ttl
        reviewScore
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listCountersByItem = /* GraphQL */ `
  query ListCountersByItem(
    $itemId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountersByItem(
      itemId: $itemId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        itemId
        attending
        views
        impressions
        seen
        week
        booked
        transactions
        idTypeKey
        bookings_clicked
        subscribed
        unsubscribed
        checkins
        list
        shared
        visits
        reviews
        offersClaimed
        offersRedeemed
        loyaltyPoints
        loyaltyCard
        scan
        score
        ttl
        reviewScore
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listCountersByType = /* GraphQL */ `
  query ListCountersByType(
    $idTypeKey: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountersByType(
      idTypeKey: $idTypeKey
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        itemId
        attending
        views
        impressions
        seen
        week
        booked
        transactions
        idTypeKey
        bookings_clicked
        subscribed
        unsubscribed
        checkins
        list
        shared
        visits
        reviews
        offersClaimed
        offersRedeemed
        loyaltyPoints
        loyaltyCard
        scan
        score
        ttl
        reviewScore
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getDailyCounters = /* GraphQL */ `
  query GetDailyCounters($id: ID!) {
    getDailyCounters(id: $id) {
      id
      parentId
      itemId
      attending
      views
      impressions
      seen
      week
      booked
      bookings_clicked
      subscribed
      unsubscribed
      checkins
      shared
      reviews
      offersClaimed
      offersRedeemed
      loyaltyPoints
      loyaltyCard
      scan
      ttl
      date
      updatedAt
      createdAt
    }
  }
`;
export const listDailyCounterss = /* GraphQL */ `
  query ListDailyCounterss(
    $filter: ModelDailyCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyCounterss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentId
        itemId
        attending
        views
        impressions
        seen
        week
        booked
        bookings_clicked
        subscribed
        unsubscribed
        checkins
        shared
        reviews
        offersClaimed
        offersRedeemed
        loyaltyPoints
        loyaltyCard
        scan
        ttl
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listDailyCountersByParent = /* GraphQL */ `
  query ListDailyCountersByParent(
    $parentId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyCountersByParent(
      parentId: $parentId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        itemId
        attending
        views
        impressions
        seen
        week
        booked
        bookings_clicked
        subscribed
        unsubscribed
        checkins
        shared
        reviews
        offersClaimed
        offersRedeemed
        loyaltyPoints
        loyaltyCard
        scan
        ttl
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listDailyCountersByItem = /* GraphQL */ `
  query ListDailyCountersByItem(
    $itemId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyCountersByItem(
      itemId: $itemId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        itemId
        attending
        views
        impressions
        seen
        week
        booked
        bookings_clicked
        subscribed
        unsubscribed
        checkins
        shared
        reviews
        offersClaimed
        offersRedeemed
        loyaltyPoints
        loyaltyCard
        scan
        ttl
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getArtist = /* GraphQL */ `
  query GetArtist($id: ID!) {
    getArtist(id: $id) {
      id
      title
      country
      currency {
        code
        name
        symbol
      }
      spotifyAppUri
      videoUrls
      nickname
      bookingConfig {
        bookingsEnabled
        messageAutoResponse
        emailsDisabled
        hourlyRate
        flatRate
        tastersAavailable
        tasterDurationInMins
        flatRateDurationInMins
        countriesAvailable
        citiesAvailable
      }
      rating
      pingGigsPlayed
      numReviews
      highlights {
        title
        image
        highlights {
          title
          videoUrl
          thumbnail
          createdAt
          isInfluencer
          duration
          userName
          userId
        }
        order
      }
      logo
      socials {
        facebook
        twitter
        tiktok
        linkedIn
        instagram
      }
      soundcloudAppUri
      appleMusicAppUri
      youtubeUrl
      spotifyUrl
      deezerUrl
      mixcloudUrl
      stripeConnectId
      amazonMusicUrl
      stripeCustomerId
      tidalUrl
      soundcloudUrl
      appleMusicUrl
      description
      genres
      images
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      location {
        lat
        lon
      }
      createdAt
      updatedAt
      reviews {
        items {
          id
          review
          score
          artistId
          dateOfVisit
          publisherId
          entityId
          status
          entityTitle
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listArtists = /* GraphQL */ `
  query ListArtists(
    $filter: ModelArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        country
        currency {
          code
          name
          symbol
        }
        spotifyAppUri
        videoUrls
        nickname
        bookingConfig {
          bookingsEnabled
          messageAutoResponse
          emailsDisabled
          hourlyRate
          flatRate
          tastersAavailable
          tasterDurationInMins
          flatRateDurationInMins
          countriesAvailable
          citiesAvailable
        }
        rating
        pingGigsPlayed
        numReviews
        highlights {
          title
          image
          order
        }
        logo
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        soundcloudAppUri
        appleMusicAppUri
        youtubeUrl
        spotifyUrl
        deezerUrl
        mixcloudUrl
        stripeConnectId
        amazonMusicUrl
        stripeCustomerId
        tidalUrl
        soundcloudUrl
        appleMusicUrl
        description
        genres
        images
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        location {
          lat
          lon
        }
        createdAt
        updatedAt
        reviews {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchArtists = /* GraphQL */ `
  query SearchArtists(
    $filter: SearchableArtistFilterInput
    $sort: SearchableArtistSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchArtists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        country
        currency {
          code
          name
          symbol
        }
        spotifyAppUri
        videoUrls
        nickname
        bookingConfig {
          bookingsEnabled
          messageAutoResponse
          emailsDisabled
          hourlyRate
          flatRate
          tastersAavailable
          tasterDurationInMins
          flatRateDurationInMins
          countriesAvailable
          citiesAvailable
        }
        rating
        pingGigsPlayed
        numReviews
        highlights {
          title
          image
          order
        }
        logo
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        soundcloudAppUri
        appleMusicAppUri
        youtubeUrl
        spotifyUrl
        deezerUrl
        mixcloudUrl
        stripeConnectId
        amazonMusicUrl
        stripeCustomerId
        tidalUrl
        soundcloudUrl
        appleMusicUrl
        description
        genres
        images
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        location {
          lat
          lon
        }
        createdAt
        updatedAt
        reviews {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getArtistReview = /* GraphQL */ `
  query GetArtistReview($id: ID!) {
    getArtistReview(id: $id) {
      id
      review
      score
      artistId
      dateOfVisit
      publisherId
      entityId
      status
      entityTitle
      createdAt
      updatedAt
    }
  }
`;
export const listArtistReviews = /* GraphQL */ `
  query ListArtistReviews(
    $filter: ModelArtistReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtistReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        review
        score
        artistId
        dateOfVisit
        publisherId
        entityId
        status
        entityTitle
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArtistEventPosition = /* GraphQL */ `
  query GetArtistEventPosition($id: ID!) {
    getArtistEventPosition(id: $id) {
      id
      location {
        lat
        lon
      }
      eventDate
      city
      ttl
      createdAt
      updatedAt
      artist {
        id
        title
        country
        currency {
          code
          name
          symbol
        }
        spotifyAppUri
        videoUrls
        nickname
        bookingConfig {
          bookingsEnabled
          messageAutoResponse
          emailsDisabled
          hourlyRate
          flatRate
          tastersAavailable
          tasterDurationInMins
          flatRateDurationInMins
          countriesAvailable
          citiesAvailable
        }
        rating
        pingGigsPlayed
        numReviews
        highlights {
          title
          image
          order
        }
        logo
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        soundcloudAppUri
        appleMusicAppUri
        youtubeUrl
        spotifyUrl
        deezerUrl
        mixcloudUrl
        stripeConnectId
        amazonMusicUrl
        stripeCustomerId
        tidalUrl
        soundcloudUrl
        appleMusicUrl
        description
        genres
        images
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        location {
          lat
          lon
        }
        createdAt
        updatedAt
        reviews {
          nextToken
        }
      }
    }
  }
`;
export const listArtistEventPositions = /* GraphQL */ `
  query ListArtistEventPositions(
    $filter: ModelArtistEventPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtistEventPositions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        location {
          lat
          lon
        }
        eventDate
        city
        ttl
        createdAt
        updatedAt
        artist {
          id
          title
          country
          spotifyAppUri
          videoUrls
          nickname
          rating
          pingGigsPlayed
          numReviews
          logo
          soundcloudAppUri
          appleMusicAppUri
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          stripeConnectId
          amazonMusicUrl
          stripeCustomerId
          tidalUrl
          soundcloudUrl
          appleMusicUrl
          description
          genres
          images
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchArtistEventPositions = /* GraphQL */ `
  query SearchArtistEventPositions(
    $filter: SearchableArtistEventPositionFilterInput
    $sort: SearchableArtistEventPositionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchArtistEventPositions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        location {
          lat
          lon
        }
        eventDate
        city
        ttl
        createdAt
        updatedAt
        artist {
          id
          title
          country
          spotifyAppUri
          videoUrls
          nickname
          rating
          pingGigsPlayed
          numReviews
          logo
          soundcloudAppUri
          appleMusicAppUri
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          stripeConnectId
          amazonMusicUrl
          stripeCustomerId
          tidalUrl
          soundcloudUrl
          appleMusicUrl
          description
          genres
          images
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getBlogPost = /* GraphQL */ `
  query GetBlogPost($id: ID!) {
    getBlogPost(id: $id) {
      id
      postType
      title
      desc
      city
      area
      catgories
      subcategories
      cuisine
      headerImg
      readCount
      shareCount
      metaDesc
      metaTitle
      videoUrl
      instagram
      tiktok
      twitter
      website
      facebook
      userId
      publishStatus
      owners
      ttl
      content {
        title
        image
        content
      }
      summary {
        title
        content
      }
      createdAt
      updatedAt
      entities {
        items {
          id
          entityId
          blogPostId
          summary
          title
          order
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      events {
        items {
          id
          eventId
          blogPostId
          summary
          title
          order
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      repeatEvents {
        items {
          id
          repeatEventId
          blogPostId
          summary
          title
          order
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const listBlogPosts = /* GraphQL */ `
  query ListBlogPosts(
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postType
        title
        desc
        city
        area
        catgories
        subcategories
        cuisine
        headerImg
        readCount
        shareCount
        metaDesc
        metaTitle
        videoUrl
        instagram
        tiktok
        twitter
        website
        facebook
        userId
        publishStatus
        owners
        ttl
        content {
          title
          image
          content
        }
        summary {
          title
          content
        }
        createdAt
        updatedAt
        entities {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        user {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listBlogPostByStatus = /* GraphQL */ `
  query ListBlogPostByStatus(
    $publishStatus: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPostByStatus(
      publishStatus: $publishStatus
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        title
        desc
        city
        area
        catgories
        subcategories
        cuisine
        headerImg
        readCount
        shareCount
        metaDesc
        metaTitle
        videoUrl
        instagram
        tiktok
        twitter
        website
        facebook
        userId
        publishStatus
        owners
        ttl
        content {
          title
          image
          content
        }
        summary {
          title
          content
        }
        createdAt
        updatedAt
        entities {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        user {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const blogPostsByCityQuery = /* GraphQL */ `
  query BlogPostsByCityQuery(
    $city: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogPostsByCityQuery(
      city: $city
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        title
        desc
        city
        area
        catgories
        subcategories
        cuisine
        headerImg
        readCount
        shareCount
        metaDesc
        metaTitle
        videoUrl
        instagram
        tiktok
        twitter
        website
        facebook
        userId
        publishStatus
        owners
        ttl
        content {
          title
          image
          content
        }
        summary {
          title
          content
        }
        createdAt
        updatedAt
        entities {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        user {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const blogPostsByReadCountQuery = /* GraphQL */ `
  query BlogPostsByReadCountQuery(
    $city: String
    $readCount: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogPostsByReadCountQuery(
      city: $city
      readCount: $readCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        title
        desc
        city
        area
        catgories
        subcategories
        cuisine
        headerImg
        readCount
        shareCount
        metaDesc
        metaTitle
        videoUrl
        instagram
        tiktok
        twitter
        website
        facebook
        userId
        publishStatus
        owners
        ttl
        content {
          title
          image
          content
        }
        summary {
          title
          content
        }
        createdAt
        updatedAt
        entities {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        user {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const blogPostsByAreaQuery = /* GraphQL */ `
  query BlogPostsByAreaQuery(
    $area: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogPostsByAreaQuery(
      area: $area
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        title
        desc
        city
        area
        catgories
        subcategories
        cuisine
        headerImg
        readCount
        shareCount
        metaDesc
        metaTitle
        videoUrl
        instagram
        tiktok
        twitter
        website
        facebook
        userId
        publishStatus
        owners
        ttl
        content {
          title
          image
          content
        }
        summary {
          title
          content
        }
        createdAt
        updatedAt
        entities {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        user {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchBlogPosts = /* GraphQL */ `
  query SearchBlogPosts(
    $filter: SearchableBlogPostFilterInput
    $sort: SearchableBlogPostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBlogPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        postType
        title
        desc
        city
        area
        catgories
        subcategories
        cuisine
        headerImg
        readCount
        shareCount
        metaDesc
        metaTitle
        videoUrl
        instagram
        tiktok
        twitter
        website
        facebook
        userId
        publishStatus
        owners
        ttl
        content {
          title
          image
          content
        }
        summary {
          title
          content
        }
        createdAt
        updatedAt
        entities {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        user {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getBookableTable = /* GraphQL */ `
  query GetBookableTable($id: ID!) {
    getBookableTable(id: $id) {
      id
      tableNumber
      capacity
      area
      entityId
      ttl
      maxPeople
      minPeople
      availability {
        startTime
        endTime
      }
      createdAt
      updatedAt
      bookings {
        items {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          venueNotes
          durationInMinutes
          entityTitle
          userEmail
          userPhone
          confirmed
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          artistId
          notes
          status
          ttl
          paymentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBookableTables = /* GraphQL */ `
  query ListBookableTables(
    $filter: ModelBookableTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookableTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tableNumber
        capacity
        area
        entityId
        ttl
        maxPeople
        minPeople
        availability {
          startTime
          endTime
        }
        createdAt
        updatedAt
        bookings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listEntityBookingTablesByMaxPeople = /* GraphQL */ `
  query ListEntityBookingTablesByMaxPeople(
    $entityId: ID
    $maxPeople: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookableTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityBookingTablesByMaxPeople(
      entityId: $entityId
      maxPeople: $maxPeople
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tableNumber
        capacity
        area
        entityId
        ttl
        maxPeople
        minPeople
        availability {
          startTime
          endTime
        }
        createdAt
        updatedAt
        bookings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listEntityBookingTablesByCapacity = /* GraphQL */ `
  query ListEntityBookingTablesByCapacity(
    $entityId: ID
    $minPeople: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookableTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityBookingTablesByCapacity(
      entityId: $entityId
      minPeople: $minPeople
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tableNumber
        capacity
        area
        entityId
        ttl
        maxPeople
        minPeople
        availability {
          startTime
          endTime
        }
        createdAt
        updatedAt
        bookings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCheckIn = /* GraphQL */ `
  query GetCheckIn($id: ID!) {
    getCheckIn(id: $id) {
      id
      userId
      entityId
      fullName
      phone
      partySize
      gender
      dob
      owners
      age
      email
      ttl
      status
      location {
        lat
        lon
      }
      updatedAt
      createdAt
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
    }
  }
`;
export const listCheckIns = /* GraphQL */ `
  query ListCheckIns(
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckIns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        entityId
        fullName
        phone
        partySize
        gender
        dob
        owners
        age
        email
        ttl
        status
        location {
          lat
          lon
        }
        updatedAt
        createdAt
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listUsersCurrentlyAtVenue = /* GraphQL */ `
  query ListUsersCurrentlyAtVenue(
    $entityId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListUsersCurrentlyAtVenue(
      entityId: $entityId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        entityId
        fullName
        phone
        partySize
        gender
        dob
        owners
        age
        email
        ttl
        status
        location {
          lat
          lon
        }
        updatedAt
        createdAt
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      title
      venueCount
      areas {
        title
        location {
          lat
          lon
        }
        parentArea
        images
        timezoneName
      }
      description
      country
      continent
      location {
        lat
        lon
      }
      timezoneOffset
      currency
      images
      createdAt
      updatedAt
      status
      config {
        events
        places
        pingers
      }
      utcOffsetMinutes
      timezoneName
      city
      isPingfluencerCity
    }
  }
`;
export const listCitys = /* GraphQL */ `
  query ListCitys(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        venueCount
        areas {
          title
          parentArea
          images
          timezoneName
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
        createdAt
        updatedAt
        status
        config {
          events
          places
          pingers
        }
        utcOffsetMinutes
        timezoneName
        city
        isPingfluencerCity
      }
      nextToken
    }
  }
`;
export const findCityByName = /* GraphQL */ `
  query FindCityByName(
    $title: String
    $sortDirection: ModelSortDirection
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findCityByName(
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        venueCount
        areas {
          title
          parentArea
          images
          timezoneName
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
        createdAt
        updatedAt
        status
        config {
          events
          places
          pingers
        }
        utcOffsetMinutes
        timezoneName
        city
        isPingfluencerCity
      }
      nextToken
    }
  }
`;
export const listCitiesByCountry = /* GraphQL */ `
  query ListCitiesByCountry(
    $title: String
    $sortDirection: ModelSortDirection
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCitiesByCountry(
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        venueCount
        areas {
          title
          parentArea
          images
          timezoneName
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
        createdAt
        updatedAt
        status
        config {
          events
          places
          pingers
        }
        utcOffsetMinutes
        timezoneName
        city
        isPingfluencerCity
      }
      nextToken
    }
  }
`;
export const searchCitys = /* GraphQL */ `
  query SearchCitys(
    $filter: SearchableCityFilterInput
    $sort: SearchableCitySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCitys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        venueCount
        areas {
          title
          parentArea
          images
          timezoneName
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
        createdAt
        updatedAt
        status
        config {
          events
          places
          pingers
        }
        utcOffsetMinutes
        timezoneName
        city
        isPingfluencerCity
      }
      nextToken
      total
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      code
      discountType
      discountAmount
      status
      publisherId
      eventType
      eventId
      repeatEventId
      repeatEventOccurencId
      ttl
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        discountType
        discountAmount
        status
        publisherId
        eventType
        eventId
        repeatEventId
        repeatEventOccurencId
        ttl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listCouponsByCode = /* GraphQL */ `
  query ListCouponsByCode(
    $code: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCouponsByCode(
      code: $code
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        discountType
        discountAmount
        status
        publisherId
        eventType
        eventId
        repeatEventId
        repeatEventOccurencId
        ttl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listEventCoupons = /* GraphQL */ `
  query ListEventCoupons(
    $eventId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventCoupons(
      eventId: $eventId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        discountType
        discountAmount
        status
        publisherId
        eventType
        eventId
        repeatEventId
        repeatEventOccurencId
        ttl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listPublisherCoupons = /* GraphQL */ `
  query ListPublisherCoupons(
    $publisherId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublisherCoupons(
      publisherId: $publisherId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        discountType
        discountAmount
        status
        publisherId
        eventType
        eventId
        repeatEventId
        repeatEventOccurencId
        ttl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listRepeatEventCoupons = /* GraphQL */ `
  query ListRepeatEventCoupons(
    $repeatEventId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRepeatEventCoupons(
      repeatEventId: $repeatEventId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        discountType
        discountAmount
        status
        publisherId
        eventType
        eventId
        repeatEventId
        repeatEventOccurencId
        ttl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      entityId
      logo
      headerVideos
      publisherId
      area
      sponsors {
        id
        title
        logo
        desc
      }
      subtitle
      description
      type
      score
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      categories
      genres
      dateTime
      startDate
      pageVideos {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      cardVideos {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      videoData {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      endDate
      closingTime
      rules
      email
      discount
      buyTicketUrl
      canBook
      isOnlineEvent
      featured
      video
      images
      eventFeatures {
        images
        title
        description
        bookUrl
        status
      }
      artistsList
      mapImages
      location {
        lat
        lon
      }
      price
      city
      features {
        title
        icon
      }
      attending
      extraInfo {
        title
        content
        url
      }
      promoterIds
      ttl
      views
      whatsappNumber
      phone
      status
      owner
      owners
      pushTimestamps
      ownerType
      tickets {
        items {
          id
          userId
          pingfluencerId
          bookingReference
          eventId
          repeatEventId
          repeatEventOccurencId
          eventType
          eventStartTimestamp
          chargeId
          paymentIntentId
          content
          ticketActivasted
          userName
          tier
          variation
          owners
          status
          ttl
          test
          refunded
          createdAt
          updatedAt
        }
        nextToken
      }
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      checklist {
        title
        listItems
      }
      tablesAvailable
      menuUrlList {
        title
        type
        url
      }
      menuPDFList {
        title
        type
        url
      }
      menuImages
      influencerInfo {
        hashtags
        tags
        details
      }
      username
      menu {
        id
        sections {
          id
          description
          title
          type
          icon
          status
        }
        title
        status
      }
      config {
        canBuyTickets
        ticketConfig {
          title
          quantity
          displayQuantity
          hideAmountLeft
          amountSold
          maxPerUser
          images
          variationSoldCount
          availableAppOnly
          disclaimer
          customEmailMessage
          description
          dateAvailableFrom
          dateAvailableTill
        }
      }
      pingfluenceOnly
      createdAt
      updatedAt
      blogPosts {
        items {
          id
          eventId
          blogPostId
          summary
          title
          order
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      attendees {
        items {
          id
          eventDate
          eventId
          userName
          repeatEventId
          repeatEventOccurenceId
          type
          userId
          owners
          status
          checkInDate
          tablesBooked
          subscriptionArn
          plusOnesRemaining
          authCode
          subscriptionArns
          subscriptionActive
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      tables {
        items {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      variations {
        items {
          id
          eventId
          repeatEventId
          repeatEventOccurenceId
          eventType
          variationRuleId
          isSoldOut
          status
          amountSoldCounter
          owners
          ttl
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        entityId
        logo
        headerVideos
        publisherId
        area
        sponsors {
          id
          title
          logo
          desc
        }
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        genres
        dateTime
        startDate
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        endDate
        closingTime
        rules
        email
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        pushTimestamps
        ownerType
        tickets {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        influencerInfo {
          hashtags
          tags
          details
        }
        username
        menu {
          id
          title
          status
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        variations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const eventShortUrlQuery = /* GraphQL */ `
  query EventShortUrlQuery(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventShortUrlQuery(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        entityId
        logo
        headerVideos
        publisherId
        area
        sponsors {
          id
          title
          logo
          desc
        }
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        genres
        dateTime
        startDate
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        endDate
        closingTime
        rules
        email
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        pushTimestamps
        ownerType
        tickets {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        influencerInfo {
          hashtags
          tags
          details
        }
        username
        menu {
          id
          title
          status
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        variations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchEvents = /* GraphQL */ `
  query SearchEvents(
    $filter: SearchableEventFilterInput
    $sort: SearchableEventSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        entityId
        logo
        headerVideos
        publisherId
        area
        sponsors {
          id
          title
          logo
          desc
        }
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        genres
        dateTime
        startDate
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        endDate
        closingTime
        rules
        email
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        pushTimestamps
        ownerType
        tickets {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        influencerInfo {
          hashtags
          tags
          details
        }
        username
        menu {
          id
          title
          status
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        variations {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getRepeatEvent = /* GraphQL */ `
  query GetRepeatEvent($id: ID!) {
    getRepeatEvent(id: $id) {
      id
      title
      entityId
      area
      headerVideos
      publisherId
      sponsors {
        id
        title
        logo
        desc
      }
      logo
      subtitle
      description
      type
      pageVideos {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      cardVideos {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      videoData {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      discount
      isOnlineEvent
      score
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      eventFeatures {
        images
        title
        description
        bookUrl
        status
      }
      categories
      genres
      closingTime
      dayOfWeek
      daysOfWeek
      startTime
      endTime
      showWeather
      buyTicketUrl
      canBook
      ttl
      whatsappNumber
      phone
      mapImages
      video
      images
      artistsList
      featured
      features {
        title
        icon
      }
      firstEventDate
      lastEventDate
      location {
        lat
        lon
      }
      price
      rules
      city
      attending
      status
      ownerType
      owner
      pushTimestamps
      promoterIds
      extraInfo {
        title
        content
        url
      }
      owners
      tickets {
        items {
          id
          userId
          pingfluencerId
          bookingReference
          eventId
          repeatEventId
          repeatEventOccurencId
          eventType
          eventStartTimestamp
          chargeId
          paymentIntentId
          content
          ticketActivasted
          userName
          tier
          variation
          owners
          status
          ttl
          test
          refunded
          createdAt
          updatedAt
        }
        nextToken
      }
      occurencesRules {
        title
        startTime
        endTime
        typeOfRepeat
        repeatInterval
        daysOfWeek
        daysOfMonth
        customDates {
          startDate
          endDate
        }
        weeksOfMonth
        weeksDaysOfMonth
        occurenceTotalRepeat
        startDate
        endDate
        usesEntityTables
        price {
          price
        }
        quantity
      }
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      checklist {
        title
        listItems
      }
      menuUrlList {
        title
        type
        url
      }
      menuPDFList {
        title
        type
        url
      }
      menuImages
      menu {
        id
        sections {
          id
          description
          title
          type
          icon
          status
        }
        title
        status
      }
      username
      influencerInfo {
        hashtags
        tags
        details
      }
      config {
        canBuyTickets
        ticketConfig {
          title
          quantity
          displayQuantity
          hideAmountLeft
          amountSold
          maxPerUser
          images
          variationSoldCount
          availableAppOnly
          disclaimer
          customEmailMessage
          description
          dateAvailableFrom
          dateAvailableTill
        }
      }
      pingfluenceOnly
      createdAt
      updatedAt
      blogPosts {
        items {
          id
          repeatEventId
          blogPostId
          summary
          title
          order
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      occurences {
        items {
          id
          repeatEventId
          startDate
          endDate
          owners
          isSoldOut
          totalTicketsSold
          canBuyTickets
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      attendees {
        items {
          id
          eventDate
          eventId
          userName
          repeatEventId
          repeatEventOccurenceId
          type
          userId
          owners
          status
          checkInDate
          tablesBooked
          subscriptionArn
          plusOnesRemaining
          authCode
          subscriptionArns
          subscriptionActive
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      tables {
        items {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listRepeatEvents = /* GraphQL */ `
  query ListRepeatEvents(
    $filter: ModelRepeatEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRepeatEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        entityId
        area
        headerVideos
        publisherId
        sponsors {
          id
          title
          logo
          desc
        }
        logo
        subtitle
        description
        type
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        categories
        genres
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        firstEventDate
        lastEventDate
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        pushTimestamps
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        tickets {
          nextToken
        }
        occurencesRules {
          title
          startTime
          endTime
          typeOfRepeat
          repeatInterval
          daysOfWeek
          daysOfMonth
          weeksOfMonth
          weeksDaysOfMonth
          occurenceTotalRepeat
          startDate
          endDate
          usesEntityTables
          quantity
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        influencerInfo {
          hashtags
          tags
          details
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        occurences {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const repeatEventShortUrlQuery = /* GraphQL */ `
  query RepeatEventShortUrlQuery(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelRepeatEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    repeatEventShortUrlQuery(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        entityId
        area
        headerVideos
        publisherId
        sponsors {
          id
          title
          logo
          desc
        }
        logo
        subtitle
        description
        type
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        categories
        genres
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        firstEventDate
        lastEventDate
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        pushTimestamps
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        tickets {
          nextToken
        }
        occurencesRules {
          title
          startTime
          endTime
          typeOfRepeat
          repeatInterval
          daysOfWeek
          daysOfMonth
          weeksOfMonth
          weeksDaysOfMonth
          occurenceTotalRepeat
          startDate
          endDate
          usesEntityTables
          quantity
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        influencerInfo {
          hashtags
          tags
          details
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        occurences {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchRepeatEvents = /* GraphQL */ `
  query SearchRepeatEvents(
    $filter: SearchableRepeatEventFilterInput
    $sort: SearchableRepeatEventSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchRepeatEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        entityId
        area
        headerVideos
        publisherId
        sponsors {
          id
          title
          logo
          desc
        }
        logo
        subtitle
        description
        type
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        categories
        genres
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        firstEventDate
        lastEventDate
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        pushTimestamps
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        tickets {
          nextToken
        }
        occurencesRules {
          title
          startTime
          endTime
          typeOfRepeat
          repeatInterval
          daysOfWeek
          daysOfMonth
          weeksOfMonth
          weeksDaysOfMonth
          occurenceTotalRepeat
          startDate
          endDate
          usesEntityTables
          quantity
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        influencerInfo {
          hashtags
          tags
          details
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        occurences {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getRepeatEventOccurence = /* GraphQL */ `
  query GetRepeatEventOccurence($id: ID!) {
    getRepeatEventOccurence(id: $id) {
      id
      repeatEventId
      startDate
      endDate
      owners
      isSoldOut
      totalTicketsSold
      canBuyTickets
      tickets {
        items {
          id
          userId
          pingfluencerId
          bookingReference
          eventId
          repeatEventId
          repeatEventOccurencId
          eventType
          eventStartTimestamp
          chargeId
          paymentIntentId
          content
          ticketActivasted
          userName
          tier
          variation
          owners
          status
          ttl
          test
          refunded
          createdAt
          updatedAt
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      repeatEvent {
        id
        title
        entityId
        area
        headerVideos
        publisherId
        sponsors {
          id
          title
          logo
          desc
        }
        logo
        subtitle
        description
        type
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        categories
        genres
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        firstEventDate
        lastEventDate
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        pushTimestamps
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        tickets {
          nextToken
        }
        occurencesRules {
          title
          startTime
          endTime
          typeOfRepeat
          repeatInterval
          daysOfWeek
          daysOfMonth
          weeksOfMonth
          weeksDaysOfMonth
          occurenceTotalRepeat
          startDate
          endDate
          usesEntityTables
          quantity
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        influencerInfo {
          hashtags
          tags
          details
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        occurences {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      attendees {
        items {
          id
          eventDate
          eventId
          userName
          repeatEventId
          repeatEventOccurenceId
          type
          userId
          owners
          status
          checkInDate
          tablesBooked
          subscriptionArn
          plusOnesRemaining
          authCode
          subscriptionArns
          subscriptionActive
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      variations {
        items {
          id
          eventId
          repeatEventId
          repeatEventOccurenceId
          eventType
          variationRuleId
          isSoldOut
          status
          amountSoldCounter
          owners
          ttl
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listRepeatEventOccurences = /* GraphQL */ `
  query ListRepeatEventOccurences(
    $filter: ModelRepeatEventOccurenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRepeatEventOccurences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        repeatEventId
        startDate
        endDate
        owners
        isSoldOut
        totalTicketsSold
        canBuyTickets
        tickets {
          nextToken
        }
        ttl
        createdAt
        updatedAt
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        attendees {
          nextToken
        }
        variations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      review
      score
      feedback
      scores {
        food
        vibe
        service
        value
        overall
      }
      dateOfVisit
      userId
      entityId
      publisherId
      status
      verified
      createdAt
      updatedAt
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        review
        score
        feedback
        scores {
          food
          vibe
          service
          value
          overall
        }
        dateOfVisit
        userId
        entityId
        publisherId
        status
        verified
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const getUserEvent = /* GraphQL */ `
  query GetUserEvent($id: ID!) {
    getUserEvent(id: $id) {
      id
      eventDate
      eventId
      userName
      repeatEventId
      repeatEventOccurenceId
      type
      userId
      owners
      status
      checkInDate
      documents {
        title
        type
        url
      }
      tablesBooked
      subscriptionArn
      plusOnesRemaining
      authCode
      subscriptionArns
      pushSubscriptions {
        endpointArn
        deviceEndpointArn
      }
      subscriptionActive
      title
      createdAt
      updatedAt
      event {
        id
        title
        entityId
        logo
        headerVideos
        publisherId
        area
        sponsors {
          id
          title
          logo
          desc
        }
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        genres
        dateTime
        startDate
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        endDate
        closingTime
        rules
        email
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        pushTimestamps
        ownerType
        tickets {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        influencerInfo {
          hashtags
          tags
          details
        }
        username
        menu {
          id
          title
          status
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        variations {
          nextToken
        }
      }
      repeatEvent {
        id
        title
        entityId
        area
        headerVideos
        publisherId
        sponsors {
          id
          title
          logo
          desc
        }
        logo
        subtitle
        description
        type
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        categories
        genres
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        firstEventDate
        lastEventDate
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        pushTimestamps
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        tickets {
          nextToken
        }
        occurencesRules {
          title
          startTime
          endTime
          typeOfRepeat
          repeatInterval
          daysOfWeek
          daysOfMonth
          weeksOfMonth
          weeksDaysOfMonth
          occurenceTotalRepeat
          startDate
          endDate
          usesEntityTables
          quantity
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        influencerInfo {
          hashtags
          tags
          details
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        occurences {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
    }
  }
`;
export const listUserEvents = /* GraphQL */ `
  query ListUserEvents(
    $filter: ModelUserEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventDate
        eventId
        userName
        repeatEventId
        repeatEventOccurenceId
        type
        userId
        owners
        status
        checkInDate
        documents {
          title
          type
          url
        }
        tablesBooked
        subscriptionArn
        plusOnesRemaining
        authCode
        subscriptionArns
        pushSubscriptions {
          endpointArn
          deviceEndpointArn
        }
        subscriptionActive
        title
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const getHelpTopic = /* GraphQL */ `
  query GetHelpTopic($id: ID!) {
    getHelpTopic(id: $id) {
      id
      title
      type
      category
      icon
      color
      notes
      subtitle
      authorId
      image
      index
      articles {
        index
        title
        subtitle
        image
        content
        articles {
          index
          title
          subtitle
          image
          content
        }
      }
      sections {
        index
        title
        content
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const listHelpTopics = /* GraphQL */ `
  query ListHelpTopics(
    $filter: ModelHelpTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelpTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        category
        icon
        color
        notes
        subtitle
        authorId
        image
        index
        articles {
          index
          title
          subtitle
          image
          content
        }
        sections {
          index
          title
          content
          type
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listHelpTopicByCategory = /* GraphQL */ `
  query ListHelpTopicByCategory(
    $category: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHelpTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListHelpTopicByCategory(
      category: $category
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        category
        icon
        color
        notes
        subtitle
        authorId
        image
        index
        articles {
          index
          title
          subtitle
          image
          content
        }
        sections {
          index
          title
          content
          type
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listhelpTopicByType = /* GraphQL */ `
  query ListhelpTopicByType(
    $type: String
    $index: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHelpTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListhelpTopicByType(
      type: $type
      index: $index
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        category
        icon
        color
        notes
        subtitle
        authorId
        image
        index
        articles {
          index
          title
          subtitle
          image
          content
        }
        sections {
          index
          title
          content
          type
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpecialOffer = /* GraphQL */ `
  query GetSpecialOffer($id: ID!) {
    getSpecialOffer(id: $id) {
      id
      daysAvailable
      startTime
      price
      discount
      endTime
      endDate
      countryCode
      declineReason
      requiresBooking
      startDate
      maxTransactionAmount
      minTransactionAmount
      returnPeriod
      metaData {
        userId
        publisherId
        brandId
        entityId
        title
        gender
        dob
        cognitoUsername
      }
      description
      totalLoyaltyPointsRequiredForAccess
      sharePointsRequiredForAccess
      contactToClaim
      contactEmail
      notificationEmails
      title
      images
      weeks
      entityId
      publisherId
      location {
        lat
        lon
      }
      fidelId
      spendRequired
      delayMinutes
      delayHours
      currency
      isStudentDiscount
      city
      area
      availableEntities
      quantity
      status
      loyaltyPointsRequired
      loyaltyPointsValue
      visitsRequired
      redeemCount
      claimCount
      dateAvailable
      allowanceLimit
      refreshDaysPeriod
      type
      rewardType
      subscribersOnly
      owner
      disclaimer
      metadata {
        userId
        publisherId
        brandId
        entityId
        title
        gender
        dob
        cognitoUsername
      }
      fidelOfferId
      fidelStartDate
      fidelEndDate
      fidelMinTransactionAmount
      fidelMaxTransactionAmount
      fidelReturnPeriod
      fidelDaysOfWeek
      hashtags
      mentions
      tiktokHashtags
      tiktokMentions
      instagramHashtags
      instagramMentions
      availabilityByDay {
        monday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        tuesday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        wednesday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        thursday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        friday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        saturday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        sunday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
      }
      claimType
      redeemLatencyPeriod
      contactPhone
      doNotWhatsapp
      userAllowedClaimsPerMonth
      socialPostRequirements {
        tiktok {
          and
          reel
          reelQuantity
          post
          postQuantity
          story
          storyQuantity
          video
          videoQuantity
        }
        instagram {
          and
          reel
          reelQuantity
          post
          postQuantity
          story
          storyQuantity
          video
          videoQuantity
        }
        and
        extraRequirement
      }
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      citiesAvailable
      minimumStarRating
      monetaryValue
      monthlyLimitPerUser
      pinToWelcome
      numOfFollowersRequired
      eventDateStart
      eventDateEnd
      customInstructions
      categories
      offerCategories
      quantityAvailable
      claimUrl
      comingSoon
      requiresConfirmation
      peopleQuantity
      additionalGuestsAllowed
      offerDuration
      bookingStickerUrl
      appearancePriority
      listingPriority
      showAsStandaloneOffer
      createdAt
      updatedAt
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const listSpecialOffers = /* GraphQL */ `
  query ListSpecialOffers(
    $filter: ModelSpecialOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        daysAvailable
        startTime
        price
        discount
        endTime
        endDate
        countryCode
        declineReason
        requiresBooking
        startDate
        maxTransactionAmount
        minTransactionAmount
        returnPeriod
        metaData {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        description
        totalLoyaltyPointsRequiredForAccess
        sharePointsRequiredForAccess
        contactToClaim
        contactEmail
        notificationEmails
        title
        images
        weeks
        entityId
        publisherId
        location {
          lat
          lon
        }
        fidelId
        spendRequired
        delayMinutes
        delayHours
        currency
        isStudentDiscount
        city
        area
        availableEntities
        quantity
        status
        loyaltyPointsRequired
        loyaltyPointsValue
        visitsRequired
        redeemCount
        claimCount
        dateAvailable
        allowanceLimit
        refreshDaysPeriod
        type
        rewardType
        subscribersOnly
        owner
        disclaimer
        metadata {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        fidelOfferId
        fidelStartDate
        fidelEndDate
        fidelMinTransactionAmount
        fidelMaxTransactionAmount
        fidelReturnPeriod
        fidelDaysOfWeek
        hashtags
        mentions
        tiktokHashtags
        tiktokMentions
        instagramHashtags
        instagramMentions
        claimType
        redeemLatencyPeriod
        contactPhone
        doNotWhatsapp
        userAllowedClaimsPerMonth
        socialPostRequirements {
          and
          extraRequirement
        }
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        citiesAvailable
        minimumStarRating
        monetaryValue
        monthlyLimitPerUser
        pinToWelcome
        numOfFollowersRequired
        eventDateStart
        eventDateEnd
        customInstructions
        categories
        offerCategories
        quantityAvailable
        claimUrl
        comingSoon
        requiresConfirmation
        peopleQuantity
        additionalGuestsAllowed
        offerDuration
        bookingStickerUrl
        appearancePriority
        listingPriority
        showAsStandaloneOffer
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPublisherOffersByStatus = /* GraphQL */ `
  query ListPublisherOffersByStatus(
    $publisherId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpecialOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublisherOffersByStatus(
      publisherId: $publisherId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        daysAvailable
        startTime
        price
        discount
        endTime
        endDate
        countryCode
        declineReason
        requiresBooking
        startDate
        maxTransactionAmount
        minTransactionAmount
        returnPeriod
        metaData {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        description
        totalLoyaltyPointsRequiredForAccess
        sharePointsRequiredForAccess
        contactToClaim
        contactEmail
        notificationEmails
        title
        images
        weeks
        entityId
        publisherId
        location {
          lat
          lon
        }
        fidelId
        spendRequired
        delayMinutes
        delayHours
        currency
        isStudentDiscount
        city
        area
        availableEntities
        quantity
        status
        loyaltyPointsRequired
        loyaltyPointsValue
        visitsRequired
        redeemCount
        claimCount
        dateAvailable
        allowanceLimit
        refreshDaysPeriod
        type
        rewardType
        subscribersOnly
        owner
        disclaimer
        metadata {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        fidelOfferId
        fidelStartDate
        fidelEndDate
        fidelMinTransactionAmount
        fidelMaxTransactionAmount
        fidelReturnPeriod
        fidelDaysOfWeek
        hashtags
        mentions
        tiktokHashtags
        tiktokMentions
        instagramHashtags
        instagramMentions
        claimType
        redeemLatencyPeriod
        contactPhone
        doNotWhatsapp
        userAllowedClaimsPerMonth
        socialPostRequirements {
          and
          extraRequirement
        }
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        citiesAvailable
        minimumStarRating
        monetaryValue
        monthlyLimitPerUser
        pinToWelcome
        numOfFollowersRequired
        eventDateStart
        eventDateEnd
        customInstructions
        categories
        offerCategories
        quantityAvailable
        claimUrl
        comingSoon
        requiresConfirmation
        peopleQuantity
        additionalGuestsAllowed
        offerDuration
        bookingStickerUrl
        appearancePriority
        listingPriority
        showAsStandaloneOffer
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const specialOffersByType = /* GraphQL */ `
  query SpecialOffersByType(
    $type: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpecialOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    specialOffersByType(
      type: $type
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        daysAvailable
        startTime
        price
        discount
        endTime
        endDate
        countryCode
        declineReason
        requiresBooking
        startDate
        maxTransactionAmount
        minTransactionAmount
        returnPeriod
        metaData {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        description
        totalLoyaltyPointsRequiredForAccess
        sharePointsRequiredForAccess
        contactToClaim
        contactEmail
        notificationEmails
        title
        images
        weeks
        entityId
        publisherId
        location {
          lat
          lon
        }
        fidelId
        spendRequired
        delayMinutes
        delayHours
        currency
        isStudentDiscount
        city
        area
        availableEntities
        quantity
        status
        loyaltyPointsRequired
        loyaltyPointsValue
        visitsRequired
        redeemCount
        claimCount
        dateAvailable
        allowanceLimit
        refreshDaysPeriod
        type
        rewardType
        subscribersOnly
        owner
        disclaimer
        metadata {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        fidelOfferId
        fidelStartDate
        fidelEndDate
        fidelMinTransactionAmount
        fidelMaxTransactionAmount
        fidelReturnPeriod
        fidelDaysOfWeek
        hashtags
        mentions
        tiktokHashtags
        tiktokMentions
        instagramHashtags
        instagramMentions
        claimType
        redeemLatencyPeriod
        contactPhone
        doNotWhatsapp
        userAllowedClaimsPerMonth
        socialPostRequirements {
          and
          extraRequirement
        }
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        citiesAvailable
        minimumStarRating
        monetaryValue
        monthlyLimitPerUser
        pinToWelcome
        numOfFollowersRequired
        eventDateStart
        eventDateEnd
        customInstructions
        categories
        offerCategories
        quantityAvailable
        claimUrl
        comingSoon
        requiresConfirmation
        peopleQuantity
        additionalGuestsAllowed
        offerDuration
        bookingStickerUrl
        appearancePriority
        listingPriority
        showAsStandaloneOffer
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listoffersByStatusKey = /* GraphQL */ `
  query ListoffersByStatusKey(
    $status: String
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpecialOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listoffersByStatusKey(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        daysAvailable
        startTime
        price
        discount
        endTime
        endDate
        countryCode
        declineReason
        requiresBooking
        startDate
        maxTransactionAmount
        minTransactionAmount
        returnPeriod
        metaData {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        description
        totalLoyaltyPointsRequiredForAccess
        sharePointsRequiredForAccess
        contactToClaim
        contactEmail
        notificationEmails
        title
        images
        weeks
        entityId
        publisherId
        location {
          lat
          lon
        }
        fidelId
        spendRequired
        delayMinutes
        delayHours
        currency
        isStudentDiscount
        city
        area
        availableEntities
        quantity
        status
        loyaltyPointsRequired
        loyaltyPointsValue
        visitsRequired
        redeemCount
        claimCount
        dateAvailable
        allowanceLimit
        refreshDaysPeriod
        type
        rewardType
        subscribersOnly
        owner
        disclaimer
        metadata {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        fidelOfferId
        fidelStartDate
        fidelEndDate
        fidelMinTransactionAmount
        fidelMaxTransactionAmount
        fidelReturnPeriod
        fidelDaysOfWeek
        hashtags
        mentions
        tiktokHashtags
        tiktokMentions
        instagramHashtags
        instagramMentions
        claimType
        redeemLatencyPeriod
        contactPhone
        doNotWhatsapp
        userAllowedClaimsPerMonth
        socialPostRequirements {
          and
          extraRequirement
        }
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        citiesAvailable
        minimumStarRating
        monetaryValue
        monthlyLimitPerUser
        pinToWelcome
        numOfFollowersRequired
        eventDateStart
        eventDateEnd
        customInstructions
        categories
        offerCategories
        quantityAvailable
        claimUrl
        comingSoon
        requiresConfirmation
        peopleQuantity
        additionalGuestsAllowed
        offerDuration
        bookingStickerUrl
        appearancePriority
        listingPriority
        showAsStandaloneOffer
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchSpecialOffers = /* GraphQL */ `
  query SearchSpecialOffers(
    $filter: SearchableSpecialOfferFilterInput
    $sort: SearchableSpecialOfferSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSpecialOffers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        daysAvailable
        startTime
        price
        discount
        endTime
        endDate
        countryCode
        declineReason
        requiresBooking
        startDate
        maxTransactionAmount
        minTransactionAmount
        returnPeriod
        metaData {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        description
        totalLoyaltyPointsRequiredForAccess
        sharePointsRequiredForAccess
        contactToClaim
        contactEmail
        notificationEmails
        title
        images
        weeks
        entityId
        publisherId
        location {
          lat
          lon
        }
        fidelId
        spendRequired
        delayMinutes
        delayHours
        currency
        isStudentDiscount
        city
        area
        availableEntities
        quantity
        status
        loyaltyPointsRequired
        loyaltyPointsValue
        visitsRequired
        redeemCount
        claimCount
        dateAvailable
        allowanceLimit
        refreshDaysPeriod
        type
        rewardType
        subscribersOnly
        owner
        disclaimer
        metadata {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        fidelOfferId
        fidelStartDate
        fidelEndDate
        fidelMinTransactionAmount
        fidelMaxTransactionAmount
        fidelReturnPeriod
        fidelDaysOfWeek
        hashtags
        mentions
        tiktokHashtags
        tiktokMentions
        instagramHashtags
        instagramMentions
        claimType
        redeemLatencyPeriod
        contactPhone
        doNotWhatsapp
        userAllowedClaimsPerMonth
        socialPostRequirements {
          and
          extraRequirement
        }
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        citiesAvailable
        minimumStarRating
        monetaryValue
        monthlyLimitPerUser
        pinToWelcome
        numOfFollowersRequired
        eventDateStart
        eventDateEnd
        customInstructions
        categories
        offerCategories
        quantityAvailable
        claimUrl
        comingSoon
        requiresConfirmation
        peopleQuantity
        additionalGuestsAllowed
        offerDuration
        bookingStickerUrl
        appearancePriority
        listingPriority
        showAsStandaloneOffer
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getLoyaltyCard = /* GraphQL */ `
  query GetLoyaltyCard($id: ID!) {
    getLoyaltyCard(id: $id) {
      id
      userId
      publisherId
      businessTitle
      currentTier
      entityId
      visits
      businessAssignedTier
      transactions
      totalLoyaltyPointsRequiredForAccess
      subscriptionArns
      pushSubscriptions {
        endpointArn
        deviceEndpointArn
      }
      notificationsEnabled
      totalSpend
      pendingPoints
      totalPoints
      ownerType
      status
      userFullName
      owner
      owners
      totalSpendablePoints
      createdAt
      updatedAt
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
    }
  }
`;
export const listLoyaltyCards = /* GraphQL */ `
  query ListLoyaltyCards(
    $id: ID
    $filter: ModelLoyaltyCardFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLoyaltyCards(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        publisherId
        businessTitle
        currentTier
        entityId
        visits
        businessAssignedTier
        transactions
        totalLoyaltyPointsRequiredForAccess
        subscriptionArns
        pushSubscriptions {
          endpointArn
          deviceEndpointArn
        }
        notificationsEnabled
        totalSpend
        pendingPoints
        totalPoints
        ownerType
        status
        userFullName
        owner
        owners
        totalSpendablePoints
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listUserLoyaltyCards = /* GraphQL */ `
  query ListUserLoyaltyCards(
    $userId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLoyaltyCards(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        publisherId
        businessTitle
        currentTier
        entityId
        visits
        businessAssignedTier
        transactions
        totalLoyaltyPointsRequiredForAccess
        subscriptionArns
        pushSubscriptions {
          endpointArn
          deviceEndpointArn
        }
        notificationsEnabled
        totalSpend
        pendingPoints
        totalPoints
        ownerType
        status
        userFullName
        owner
        owners
        totalSpendablePoints
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listPublisherLoyaltyCards = /* GraphQL */ `
  query ListPublisherLoyaltyCards(
    $publisherId: ID
    $totalPoints: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublisherLoyaltyCards(
      publisherId: $publisherId
      totalPoints: $totalPoints
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        publisherId
        businessTitle
        currentTier
        entityId
        visits
        businessAssignedTier
        transactions
        totalLoyaltyPointsRequiredForAccess
        subscriptionArns
        pushSubscriptions {
          endpointArn
          deviceEndpointArn
        }
        notificationsEnabled
        totalSpend
        pendingPoints
        totalPoints
        ownerType
        status
        userFullName
        owner
        owners
        totalSpendablePoints
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const searchLoyaltyCards = /* GraphQL */ `
  query SearchLoyaltyCards(
    $filter: SearchableLoyaltyCardFilterInput
    $sort: SearchableLoyaltyCardSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchLoyaltyCards(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userId
        publisherId
        businessTitle
        currentTier
        entityId
        visits
        businessAssignedTier
        transactions
        totalLoyaltyPointsRequiredForAccess
        subscriptionArns
        pushSubscriptions {
          endpointArn
          deviceEndpointArn
        }
        notificationsEnabled
        totalSpend
        pendingPoints
        totalPoints
        ownerType
        status
        userFullName
        owner
        owners
        totalSpendablePoints
        createdAt
        updatedAt
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
      total
    }
  }
`;
export const getDailyPromotions = /* GraphQL */ `
  query GetDailyPromotions($id: ID!) {
    getDailyPromotions(id: $id) {
      id
      entityId
      daysAvailable
      startTime
      endTime
      description
      price
      images
      title
      status
      disclaimer
      owner
      subscribersOnly
      createdAt
      updatedAt
    }
  }
`;
export const listDailyPromotionss = /* GraphQL */ `
  query ListDailyPromotionss(
    $filter: ModelDailyPromotionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyPromotionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        daysAvailable
        startTime
        endTime
        description
        price
        images
        title
        status
        disclaimer
        owner
        subscribersOnly
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLoyaltyActivity = /* GraphQL */ `
  query GetLoyaltyActivity($id: ID!) {
    getLoyaltyActivity(id: $id) {
      id
      userPublisherKey
      points
      eventId
      entityId
      publisherId
      repeatEventId
      userId
      adminAllocated
      message
      createdAt
      updatedAt
      event {
        id
        title
        entityId
        logo
        headerVideos
        publisherId
        area
        sponsors {
          id
          title
          logo
          desc
        }
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        genres
        dateTime
        startDate
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        endDate
        closingTime
        rules
        email
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        pushTimestamps
        ownerType
        tickets {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        influencerInfo {
          hashtags
          tags
          details
        }
        username
        menu {
          id
          title
          status
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        variations {
          nextToken
        }
      }
      repeatEvent {
        id
        title
        entityId
        area
        headerVideos
        publisherId
        sponsors {
          id
          title
          logo
          desc
        }
        logo
        subtitle
        description
        type
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        categories
        genres
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        firstEventDate
        lastEventDate
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        pushTimestamps
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        tickets {
          nextToken
        }
        occurencesRules {
          title
          startTime
          endTime
          typeOfRepeat
          repeatInterval
          daysOfWeek
          daysOfMonth
          weeksOfMonth
          weeksDaysOfMonth
          occurenceTotalRepeat
          startDate
          endDate
          usesEntityTables
          quantity
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        influencerInfo {
          hashtags
          tags
          details
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        occurences {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
    }
  }
`;
export const listLoyaltyActivitys = /* GraphQL */ `
  query ListLoyaltyActivitys(
    $filter: ModelLoyaltyActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoyaltyActivitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userPublisherKey
        points
        eventId
        entityId
        publisherId
        repeatEventId
        userId
        adminAllocated
        message
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listUserLoyaltyActivity = /* GraphQL */ `
  query ListUserLoyaltyActivity(
    $userId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLoyaltyActivity(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userPublisherKey
        points
        eventId
        entityId
        publisherId
        repeatEventId
        userId
        adminAllocated
        message
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listUserPublisherLoyaltyActivity = /* GraphQL */ `
  query ListUserPublisherLoyaltyActivity(
    $userPublisherKey: String
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPublisherLoyaltyActivity(
      userPublisherKey: $userPublisherKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userPublisherKey
        points
        eventId
        entityId
        publisherId
        repeatEventId
        userId
        adminAllocated
        message
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listEntityLoyaltyActivity = /* GraphQL */ `
  query ListEntityLoyaltyActivity(
    $entityId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityLoyaltyActivity(
      entityId: $entityId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userPublisherKey
        points
        eventId
        entityId
        publisherId
        repeatEventId
        userId
        adminAllocated
        message
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listPublisherLoyaltyActivity = /* GraphQL */ `
  query ListPublisherLoyaltyActivity(
    $publisherId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublisherLoyaltyActivity(
      publisherId: $publisherId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userPublisherKey
        points
        eventId
        entityId
        publisherId
        repeatEventId
        userId
        adminAllocated
        message
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const getPingfluencer = /* GraphQL */ `
  query GetPingfluencer($id: ID!) {
    getPingfluencer(id: $id) {
      id
      cognitoUsername
      role
      appVersion
      blocked
      firstName
      emailSettings {
        transactions
        offers
        loyaltyCard
        eventInvites
        marketing
      }
      lastName
      currency {
        code
        name
        symbol
      }
      placesAddedToListCount
      bio
      preferredTitle
      country {
        currencies {
          code
          name
          symbol
        }
        flag
        name
        alpha2Code
        alpha3Code
        callingCodes
        timezones
        region
      }
      dob
      email
      stripeCustomerId
      gender
      phone
      city
      allowNotifications
      allowLocation
      allowPersonalisation
      allowCalendar
      profilePicture
      subscibedToEmails
      pushTokens {
        token
        device
        endpointArn
        parentApp
      }
      lastLoggedIn
      locale
      summary
      categories
      social {
        facebook
        twitter
        tiktok
        linkedIn
        instagram
      }
      createdAt
      updatedAt
      termsSigned
      inMailingList
      termsVersionSigned
      appConfigs
      invitedUsers
      invitedPublishers {
        id
        businessName
        signedUpDate
        acceptedStatus
        trialFinished
        paidForInvite
      }
      influencerCategories
      aceptedAsInfluencer
      hideInstagramLogin
      hideTiktokLogin
      declinedAsInfluencer
      lastDateVetted
      tags
      instagramUsername
      tiktokUsername
      instagramBio
      tiktokBio
      currentInstagramFollowerCount
      currentTiktokFollowerCount
      instagramEngagementRanking
      tiktokEngagementRanking
      instagramAverageViewCount
      instagramAverageEngagement
      instagramAverageEngagementRate
      tiktokAverageViewCount
      tiktokAverageEngagement
      tiktokAverageEngagementRate
      instagramReach
      instagramImpressions
      instagramAudienceCity {
        city
        count
      }
      instagramAudienceAgeGender {
        gender
        age
        count
      }
      instagramLinked
      tiktokLinked
      tiktokDeepLink
      instagramVerified
      tiktokVerified
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      tickets {
        items {
          id
          userId
          pingfluencerId
          bookingReference
          eventId
          repeatEventId
          repeatEventOccurencId
          eventType
          eventStartTimestamp
          chargeId
          paymentIntentId
          content
          ticketActivasted
          userName
          tier
          variation
          owners
          status
          ttl
          test
          refunded
          createdAt
          updatedAt
        }
        nextToken
      }
      instagramAccessToken
      instagramUserId
      tiktokAccessToken
      tiktokRefreshToken
      facebookUserId
      facebookAccessToken
      facebookPageId
      facebookInstaAccountId
      warningCount
      noShowWarningCount
      instagramInsights {
        impressions
        reach
        follower_count
        profile_views
        audience_gender_age
        audience_locale
        audience_country
        audience_city
        online_followers
        accounts_engaged
        total_interactions
        likes
        comments
        shares
        saves
        replies
        engaged_audience_demographics
        reached_audience_demographics
        follower_demographics
        follows_and_unfollows
        profile_links_taps
      }
      tiktokInsights {
        impressions
        reach
        follower_count
        profile_views
        audience_gender_age
        audience_locale
        audience_country
        audience_city
        online_followers
        accounts_engaged
        total_interactions
        likes
        comments
        shares
        saves
        replies
        engaged_audience_demographics
        reached_audience_demographics
        follower_demographics
        follows_and_unfollows
        profile_links_taps
      }
      reviewCount
      reviewRating
      reviewScores {
        speed
        quality
        captions
        deliverables
        behaviour
        overall
      }
      conversations {
        items {
          id
          status
          createdAt
          updatedAt
          owners
          publisherId
          claimedOfferId
          publisherLastRead
          pingfluencerLastRead
          entityId
          userId
          pingfluencerId
        }
        nextToken
      }
      username
      notes
      offers {
        items {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
        nextToken
      }
      subscriptions {
        items {
          id
          userId
          pingfluencerId
          entityId
          publisherId
          userFullName
          subscriptionArn
          subscriptionArns
          notificationsEnabled
          credit
          loyaltyPoints
          loyaltyStatus
          EPOSId
          type
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPingfluencers = /* GraphQL */ `
  query ListPingfluencers(
    $filter: ModelPingfluencerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPingfluencers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPingfluencerByInstagram = /* GraphQL */ `
  query GetPingfluencerByInstagram(
    $instagramUsername: String
    $sortDirection: ModelSortDirection
    $filter: ModelPingfluencerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPingfluencerByInstagram(
      instagramUsername: $instagramUsername
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPingfluencerByTiktok = /* GraphQL */ `
  query GetPingfluencerByTiktok(
    $instagramUsername: String
    $sortDirection: ModelSortDirection
    $filter: ModelPingfluencerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPingfluencerByTiktok(
      instagramUsername: $instagramUsername
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPingfluencerByUsername = /* GraphQL */ `
  query GetPingfluencerByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelPingfluencerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPingfluencerByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchPingfluencers = /* GraphQL */ `
  query SearchPingfluencers(
    $filter: SearchablePingfluencerFilterInput
    $sort: SearchablePingfluencerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPingfluencers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getEntity = /* GraphQL */ `
  query GetEntity($id: ID!) {
    getEntity(id: $id) {
      id
      title
      shortUrl
      subtitle
      lastAdminCheck
      area
      contacts {
        title
        firstName
        lastName
        venue
        email
        phone
        position
        notes
      }
      notes
      summary
      uniqueAspect
      tags
      highlights {
        title
        image
        highlights {
          title
          videoUrl
          thumbnail
          createdAt
          isInfluencer
          duration
          userName
          userId
        }
        order
      }
      nickname
      hasEmailed
      firstName
      hasStudentDiscount
      LastName
      config {
        loyaltyEnabled
        canMessage
        canBook
        canCheckin
        canCall
        canEmail
        autoResponseMessage
        enterVenueCheckInEnabled
        checkInMessage
        colorTheme
        colourScheme {
          primary
          secondary
          text
          buttons
          backgrounds
        }
        bookingConfig {
          type
          bookingProvider
          bookingProviderBackup
          bookingProviderUsername
          bookingProviderUsernameBackup
          bookingProviderAPIClientSecret
          bookingProviderAPIClientId
          canAutoBook
          notes
          bookingDurationMins
          bookingEmails
          bookingPhoneNumber
          areas
        }
        emailsDisabled
        reviewsDisabled
        enableTrackTrace
      }
      nearbyStations {
        title
        distance
        line
        zone
        type
      }
      outdoorArea
      outdoorHeating
      description
      stripeSubscriptionItemId
      emojiSummary
      categories
      influencerCategories
      phone
      internationalPhoneNumber
      logo
      pushTimestamps
      websiteUrl
      orderfromTableUrl
      location {
        lat
        lon
      }
      entityTitle
      opens
      closes
      asFeaturedOn
      username
      trendingScore
      openHours {
        monday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        tuesday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        wednesday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        thursday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        friday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        saturday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
        sunday {
          open
          close
          fullDay
          closed
          vibe
          walkInWaitTimeInMins
          perfectFor
        }
      }
      status
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      virtualTourUrl
      emailsDisabled
      reviewsDisabled
      video
      pageVideos {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      cardVideos {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      sponsors {
        id
        title
        logo
        desc
      }
      headerVideos
      videoData {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      city
      currency {
        code
        name
        symbol
      }
      features {
        title
        icon
      }
      entityFeatures {
        images
        title
        description
        bookUrl
        status
      }
      rules
      position
      score
      score_before_boost
      boost_multiplier
      boost_until
      boostPurchaseId
      reviewRating
      reviewCount
      canMessage
      forceupdate
      email
      marketingEmail
      menuUrlList {
        title
        type
        url
      }
      menuPDFList {
        title
        type
        url
      }
      menuImages
      menu {
        id
        sections {
          id
          description
          title
          type
          icon
          status
        }
        title
        status
      }
      price
      featured
      cuisines
      dietryOptions
      foodDeliveryAppLinks {
        title
        icon
        url
      }
      verified
      qrIds
      publisherId
      subscribeOfferId
      subscriberCount
      viewCount
      bookCount
      shareCount
      checkInCount
      addToListCount
      pushTimestamp
      extraInfo {
        title
        content
        url
      }
      images
      googleImages {
        html_attributions
        image
      }
      socials {
        facebook
        twitter
        tiktok
        linkedIn
        instagram
      }
      minBookingLimit
      maxBookingLimit
      bookingUrl
      createdAt
      offersArray
      whatsappNumber
      updatedAt
      conversations {
        items {
          id
          status
          createdAt
          updatedAt
          owners
          publisherId
          claimedOfferId
          publisherLastRead
          pingfluencerLastRead
          entityId
          userId
          pingfluencerId
        }
        nextToken
      }
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      checklist {
        title
        listItems
      }
      owner
      reviewScores {
        food
        vibe
        service
        value
        overall
      }
      safetyChecklist {
        title
        listItems
      }
      venueSafetyData {
        list
        title
        description
        links
        updated
      }
      capacity
      acceptWalkIns
      covidMeasures {
        capacityPercentage
        tableLimit
        masksRequired
        bookingsRequired
        notes
      }
      mediaChannels {
        youtubeUrl
        spotifyUrl
        deezerUrl
        mixcloudUrl
        amazonMusicUrl
        tidalUrl
        soundcloudUrl
        appleMusicUrl
      }
      fidelConfig {
        merchantId
        brandId
        status
        brandUserId
        locationId
        contractSigned
      }
      pingReview {
        score
        title
        review
        createdAt
        reviewer
        reviewerPhoto
      }
      pingAwards {
        date
        type
      }
      activated
      lastVetted
      accessToPingCulture
      accessToPingfluence
      hospitalityType
      venueImageForPingfluencer
      isNotVenue
      utcOffsetMinutes
      timezoneName
      blogPosts {
        items {
          id
          entityId
          blogPostId
          summary
          title
          order
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      bookingTables {
        items {
          id
          tableNumber
          capacity
          area
          entityId
          ttl
          maxPeople
          minPeople
          createdAt
          updatedAt
        }
        nextToken
      }
      checkins {
        items {
          id
          userId
          entityId
          fullName
          phone
          partySize
          gender
          dob
          owners
          age
          email
          ttl
          status
          updatedAt
          createdAt
        }
        nextToken
      }
      events {
        items {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        nextToken
      }
      repeatEvents {
        items {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          review
          score
          feedback
          dateOfVisit
          userId
          entityId
          publisherId
          status
          verified
          createdAt
          updatedAt
        }
        nextToken
      }
      offers {
        items {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyPromotions {
        items {
          id
          entityId
          daysAvailable
          startTime
          endTime
          description
          price
          images
          title
          status
          disclaimer
          owner
          subscribersOnly
          createdAt
          updatedAt
        }
        nextToken
      }
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      tables {
        items {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      collection {
        items {
          id
          entityId
          listId
          usersVisited
          owners
          orderIndex
          images
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      claimedOffers {
        items {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
        nextToken
      }
      subscribers {
        items {
          id
          userId
          pingfluencerId
          entityId
          publisherId
          userFullName
          subscriptionArn
          subscriptionArns
          notificationsEnabled
          credit
          loyaltyPoints
          loyaltyStatus
          EPOSId
          type
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listEntitys = /* GraphQL */ `
  query ListEntitys(
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listEntitiesByPublisher = /* GraphQL */ `
  query ListEntitiesByPublisher(
    $publisherId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntitiesByPublisher(
      publisherId: $publisherId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listEntitiesByAreaScore = /* GraphQL */ `
  query ListEntitiesByAreaScore(
    $area: String
    $score: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntitiesByAreaScore(
      area: $area
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listEntitiesByCityScore = /* GraphQL */ `
  query ListEntitiesByCityScore(
    $city: String
    $score: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntitiesByCityScore(
      city: $city
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const entityShortUrlQuery = /* GraphQL */ `
  query EntityShortUrlQuery(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entityShortUrlQuery(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchEntitys = /* GraphQL */ `
  query SearchEntitys(
    $filter: SearchableEntityFilterInput
    $sort: SearchableEntitySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchEntitys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getFeaturedPlace = /* GraphQL */ `
  query GetFeaturedPlace($id: ID!) {
    getFeaturedPlace(id: $id) {
      id
      entityId
      eventId
      offerId
      location {
        lat
        lon
      }
      area
      title
      images
      deeplink
      linkText
      description
      pinToTop
      city
      featuredUntil
      coverage
      country
      days_selected
      startDate
      budget
      budget_left
      paid
      viewCount
      owner
      createdAt
      updatedAt
      status
      ttl
      event {
        id
        title
        entityId
        logo
        headerVideos
        publisherId
        area
        sponsors {
          id
          title
          logo
          desc
        }
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        genres
        dateTime
        startDate
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        endDate
        closingTime
        rules
        email
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        pushTimestamps
        ownerType
        tickets {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        influencerInfo {
          hashtags
          tags
          details
        }
        username
        menu {
          id
          title
          status
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        variations {
          nextToken
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const listFeaturedPlaces = /* GraphQL */ `
  query ListFeaturedPlaces(
    $filter: ModelFeaturedPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeaturedPlaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        entityId
        eventId
        offerId
        location {
          lat
          lon
        }
        area
        title
        images
        deeplink
        linkText
        description
        pinToTop
        city
        featuredUntil
        coverage
        country
        days_selected
        startDate
        budget
        budget_left
        paid
        viewCount
        owner
        createdAt
        updatedAt
        status
        ttl
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listFeaturedPlacesByArea = /* GraphQL */ `
  query ListFeaturedPlacesByArea(
    $area: String
    $paidFeaturedUntil: ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeaturedPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeaturedPlacesByArea(
      area: $area
      paidFeaturedUntil: $paidFeaturedUntil
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        eventId
        offerId
        location {
          lat
          lon
        }
        area
        title
        images
        deeplink
        linkText
        description
        pinToTop
        city
        featuredUntil
        coverage
        country
        days_selected
        startDate
        budget
        budget_left
        paid
        viewCount
        owner
        createdAt
        updatedAt
        status
        ttl
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listFeaturedPostInDateOrder = /* GraphQL */ `
  query ListFeaturedPostInDateOrder(
    $city: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeaturedPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeaturedPostInDateOrder(
      city: $city
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        eventId
        offerId
        location {
          lat
          lon
        }
        area
        title
        images
        deeplink
        linkText
        description
        pinToTop
        city
        featuredUntil
        coverage
        country
        days_selected
        startDate
        budget
        budget_left
        paid
        viewCount
        owner
        createdAt
        updatedAt
        status
        ttl
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const listFeaturedPlaceByBudget = /* GraphQL */ `
  query ListFeaturedPlaceByBudget(
    $entityId: ID
    $budget_left: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeaturedPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeaturedPlaceByBudget(
      entityId: $entityId
      budget_left: $budget_left
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        eventId
        offerId
        location {
          lat
          lon
        }
        area
        title
        images
        deeplink
        linkText
        description
        pinToTop
        city
        featuredUntil
        coverage
        country
        days_selected
        startDate
        budget
        budget_left
        paid
        viewCount
        owner
        createdAt
        updatedAt
        status
        ttl
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const searchFeaturedPlaces = /* GraphQL */ `
  query SearchFeaturedPlaces(
    $filter: SearchableFeaturedPlaceFilterInput
    $sort: SearchableFeaturedPlaceSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchFeaturedPlaces(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        entityId
        eventId
        offerId
        location {
          lat
          lon
        }
        area
        title
        images
        deeplink
        linkText
        description
        pinToTop
        city
        featuredUntil
        coverage
        country
        days_selected
        startDate
        budget
        budget_left
        paid
        viewCount
        owner
        createdAt
        updatedAt
        status
        ttl
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
      total
    }
  }
`;
export const getBookings = /* GraphQL */ `
  query GetBookings($id: ID!) {
    getBookings(id: $id) {
      id
      userName
      userId
      publisher
      bookingType
      entityId
      venueNotes
      durationInMinutes
      entityTitle
      userEmail
      userPhone
      confirmed
      owners
      owner
      dateTime
      tableId
      timeString
      provider
      dateString
      dateTimestamp
      people
      artistId
      notes
      status
      ttl
      paymentStatus
      artistBookingNotes {
        isEquiptmentProvided
        isBirthday
        isBrandEvent
        BrandDetails
        genresRequired
        equiptmentRequired
      }
      createdAt
      updatedAt
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
    }
  }
`;
export const listBookingss = /* GraphQL */ `
  query ListBookingss(
    $filter: ModelBookingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        venueNotes
        durationInMinutes
        entityTitle
        userEmail
        userPhone
        confirmed
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        artistId
        notes
        status
        ttl
        paymentStatus
        artistBookingNotes {
          isEquiptmentProvided
          isBirthday
          isBrandEvent
          BrandDetails
          genresRequired
          equiptmentRequired
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listBookingByBookDate = /* GraphQL */ `
  query ListBookingByBookDate(
    $entityId: ID
    $dateTimestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingByBookDate(
      entityId: $entityId
      dateTimestamp: $dateTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        venueNotes
        durationInMinutes
        entityTitle
        userEmail
        userPhone
        confirmed
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        artistId
        notes
        status
        ttl
        paymentStatus
        artistBookingNotes {
          isEquiptmentProvided
          isBirthday
          isBrandEvent
          BrandDetails
          genresRequired
          equiptmentRequired
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listUserBookings = /* GraphQL */ `
  query ListUserBookings(
    $userId: ID
    $dateTimestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserBookings(
      userId: $userId
      dateTimestamp: $dateTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        venueNotes
        durationInMinutes
        entityTitle
        userEmail
        userPhone
        confirmed
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        artistId
        notes
        status
        ttl
        paymentStatus
        artistBookingNotes {
          isEquiptmentProvided
          isBirthday
          isBrandEvent
          BrandDetails
          genresRequired
          equiptmentRequired
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listbookingsByStatusDateKey = /* GraphQL */ `
  query ListbookingsByStatusDateKey(
    $status: String
    $dateTimestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listbookingsByStatusDateKey(
      status: $status
      dateTimestamp: $dateTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        venueNotes
        durationInMinutes
        entityTitle
        userEmail
        userPhone
        confirmed
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        artistId
        notes
        status
        ttl
        paymentStatus
        artistBookingNotes {
          isEquiptmentProvided
          isBirthday
          isBrandEvent
          BrandDetails
          genresRequired
          equiptmentRequired
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listArtistBookings = /* GraphQL */ `
  query ListArtistBookings(
    $artistId: String
    $dateTimestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtistBookings(
      artistId: $artistId
      dateTimestamp: $dateTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        venueNotes
        durationInMinutes
        entityTitle
        userEmail
        userPhone
        confirmed
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        artistId
        notes
        status
        ttl
        paymentStatus
        artistBookingNotes {
          isEquiptmentProvided
          isBirthday
          isBrandEvent
          BrandDetails
          genresRequired
          equiptmentRequired
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const searchBookingss = /* GraphQL */ `
  query SearchBookingss(
    $filter: SearchableBookingsFilterInput
    $sort: SearchableBookingsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBookingss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        venueNotes
        durationInMinutes
        entityTitle
        userEmail
        userPhone
        confirmed
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        artistId
        notes
        status
        ttl
        paymentStatus
        artistBookingNotes {
          isEquiptmentProvided
          isBirthday
          isBrandEvent
          BrandDetails
          genresRequired
          equiptmentRequired
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
      total
    }
  }
`;
export const getPodcast = /* GraphQL */ `
  query GetPodcast($id: ID!) {
    getPodcast(id: $id) {
      id
      entityId
      listId
      listImage
      status
      embedType
      embedId
      embedUrl
      title
      description
      images
      username
      categories
      tags
      guests {
        name
        description
        url
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        details
        image
      }
      createdAt
      updatedAt
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      list {
        id
        userId
        title
        description
        area
        saved
        shared
        likes
        venueCount
        headerImg
        videos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        views
        lockCriteria
        score
        areas
        location {
          lat
          lon
        }
        username
        city
        cities
        status
        isPublic
        dateTime
        securityCode
        owners
        createdAt
        updatedAt
        users {
          nextToken
        }
        collectionLink {
          nextToken
        }
      }
    }
  }
`;
export const listPodcasts = /* GraphQL */ `
  query ListPodcasts(
    $filter: ModelPodcastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodcasts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        entityId
        listId
        listImage
        status
        embedType
        embedId
        embedUrl
        title
        description
        images
        username
        categories
        tags
        guests {
          name
          description
          url
          details
          image
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        list {
          id
          userId
          title
          description
          area
          saved
          shared
          likes
          venueCount
          headerImg
          views
          lockCriteria
          score
          areas
          username
          city
          cities
          status
          isPublic
          dateTime
          securityCode
          owners
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPodcastsByStatus = /* GraphQL */ `
  query ListPodcastsByStatus(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPodcastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodcastsByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        listId
        listImage
        status
        embedType
        embedId
        embedUrl
        title
        description
        images
        username
        categories
        tags
        guests {
          name
          description
          url
          details
          image
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        list {
          id
          userId
          title
          description
          area
          saved
          shared
          likes
          venueCount
          headerImg
          views
          lockCriteria
          score
          areas
          username
          city
          cities
          status
          isPublic
          dateTime
          securityCode
          owners
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPodcastsByEntity = /* GraphQL */ `
  query ListPodcastsByEntity(
    $entityId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPodcastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodcastsByEntity(
      entityId: $entityId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        listId
        listImage
        status
        embedType
        embedId
        embedUrl
        title
        description
        images
        username
        categories
        tags
        guests {
          name
          description
          url
          details
          image
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        list {
          id
          userId
          title
          description
          area
          saved
          shared
          likes
          venueCount
          headerImg
          views
          lockCriteria
          score
          areas
          username
          city
          cities
          status
          isPublic
          dateTime
          securityCode
          owners
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPodcastsByUsername = /* GraphQL */ `
  query ListPodcastsByUsername(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPodcastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodcastsByUsername(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        listId
        listImage
        status
        embedType
        embedId
        embedUrl
        title
        description
        images
        username
        categories
        tags
        guests {
          name
          description
          url
          details
          image
        }
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        list {
          id
          userId
          title
          description
          area
          saved
          shared
          likes
          venueCount
          headerImg
          views
          lockCriteria
          score
          areas
          username
          city
          cities
          status
          isPublic
          dateTime
          securityCode
          owners
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPublisher = /* GraphQL */ `
  query GetPublisher($id: ID!) {
    getPublisher(id: $id) {
      id
      cognitoUsername
      financeEmail
      role
      profilePicture
      bio
      config {
        features {
          dashboard
          events
          places
          loyalty
          messaging
          offers
          promos
          subscribers
          updates
          reviews
          bookings
        }
        type
        isBespoke
        standAloneVenue
        notificationsEnabled
        mailMarketingProvider
        paymentProvier
        POSProviders
        eventsConfig {
          usePaymentProvider
          tablesEnabled
        }
        bookingsConfig {
          bookingProvider
          integratePOS
          emailsDisabled
        }
        offersConfig {
          integratePOS
        }
        subscribersConfig {
          isPaid
          subscriptionLength
        }
        loyaltyConfig {
          integratePOS
          loyaltyEnabled
          color
          textColor
          loyaltyPeriodInDays
          logo
          listLogo
          cardLogo
          tiersEnabled
          type
          businessShortName
          loyaltyPointCurrencyValue
          classes
          securityCode
          disabledAutoTierCardColours
          useMainLogo
          textPosition
          customCardDesignUrl
          text
        }
        menuConfig {
          integratePOS
        }
        colourScheme {
          primary
          secondary
          text
          buttons
          backgrounds
        }
      }
      firstName
      lastName
      email
      notificationEmails
      publisherType
      editors
      owner
      username
      currency {
        code
        name
        symbol
      }
      country {
        currencies {
          code
          name
          symbol
        }
        flag
        name
        alpha2Code
        alpha3Code
        callingCodes
        timezones
        region
      }
      pushTimestamps
      emailsDisabled
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      timezone
      subscibedToEmails
      groupVenues
      groupVenuesName
      timezoneOffset
      logo
      businessName
      description
      websiteUrl
      whatsappNumber
      phone
      socials {
        facebook
        twitter
        tiktok
        linkedIn
        instagram
      }
      canMessage
      subscriberCount
      loyaltyCount
      status
      termsVersionSigned
      termsMinimumMonthContract
      activated
      reviewsDisabled
      reviewRating
      reviewCount
      activationDate
      rejectionDate
      startedPaying
      stripeConnectId
      contacts {
        title
        firstName
        lastName
        venue
        email
        phone
        position
        notes
      }
      stripeCustomerId
      fidelId
      activatedClaimedOffersCount
      stripeSubscriptions {
        items {
          id
          publisherId
          owner
          current_period_end
          current_period_start
          type
          paused
          priceId
          productId
          cancel_at_period_end
          subscriptionItemId
          entitiesIncluded
          quantity
          latest_invoice
          status
          trial_start
          trial_end
          isBespoke
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          status
          createdAt
          updatedAt
          owners
          publisherId
          claimedOfferId
          publisherLastRead
          pingfluencerLastRead
          entityId
          userId
          pingfluencerId
        }
        nextToken
      }
      averageResponseTime
      fidelConfig {
        merchantId
        brandId
        status
        brandUserId
        locationId
        contractSigned
      }
      preventSubscriptionLockout
      referralCodeUsed
      doesNotRequirePendingActions
      videoData {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      monthlyClaimsLimit
      monthlyClaimsCounter
      createdAt
      updatedAt
      events {
        items {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        nextToken
      }
      repeatEvents {
        items {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          review
          score
          feedback
          dateOfVisit
          userId
          entityId
          publisherId
          status
          verified
          createdAt
          updatedAt
        }
        nextToken
      }
      offers {
        items {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        nextToken
      }
      loyaltyCards {
        items {
          id
          userId
          publisherId
          businessTitle
          currentTier
          entityId
          visits
          businessAssignedTier
          transactions
          totalLoyaltyPointsRequiredForAccess
          subscriptionArns
          notificationsEnabled
          totalSpend
          pendingPoints
          totalPoints
          ownerType
          status
          userFullName
          owner
          owners
          totalSpendablePoints
          createdAt
          updatedAt
        }
        nextToken
      }
      entities {
        items {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        nextToken
      }
      subscribers {
        items {
          id
          userId
          pingfluencerId
          entityId
          publisherId
          userFullName
          subscriptionArn
          subscriptionArns
          notificationsEnabled
          credit
          loyaltyPoints
          loyaltyStatus
          EPOSId
          type
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPublishers = /* GraphQL */ `
  query ListPublishers(
    $filter: ModelPublisherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPublisherByOwner = /* GraphQL */ `
  query GetPublisherByOwner(
    $owner: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPublisherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublisherByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchPublishers = /* GraphQL */ `
  query SearchPublishers(
    $filter: SearchablePublisherFilterInput
    $sort: SearchablePublisherSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPublishers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getCategories = /* GraphQL */ `
  query GetCategories($id: ID!) {
    getCategories(id: $id) {
      id
      title
      type
      icon
      desc
      pingEditTitle
      metaTitle
      metaDescription
      priority
      showcaseImage
      pinToDiscover
      discoverTitle
      categoryCityCount
      isHidden
      citiesAvailable
      translateString
      colour
      image
      subCategories {
        title
        image
        isAdmin
        desc
        showcaseImage
        translateString
        pingEditTitle
        metaTitle
        metaDescription
        categoryCityCount
        isHidden
        citiesAvailable
        icon
        colour
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategoriess = /* GraphQL */ `
  query ListCategoriess(
    $filter: ModelCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoriess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        icon
        desc
        pingEditTitle
        metaTitle
        metaDescription
        priority
        showcaseImage
        pinToDiscover
        discoverTitle
        categoryCityCount
        isHidden
        citiesAvailable
        translateString
        colour
        image
        subCategories {
          title
          image
          isAdmin
          desc
          showcaseImage
          translateString
          pingEditTitle
          metaTitle
          metaDescription
          categoryCityCount
          isHidden
          citiesAvailable
          icon
          colour
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVenueCategoryTopics = /* GraphQL */ `
  query GetVenueCategoryTopics($id: ID!) {
    getVenueCategoryTopics(id: $id) {
      id
      title
      shortTitle
      subtitle
      image
      URL
      type
      itemId
      content
      pinToTop
      sliderTopic
      tag
      daysOfWeek
      timeStart
      timeEnd
      category
      subCategory
      price
      omittedCities
      allowedCities
      orderBy
      createdAt
      updatedAt
    }
  }
`;
export const listVenueCategoryTopicss = /* GraphQL */ `
  query ListVenueCategoryTopicss(
    $filter: ModelVenueCategoryTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenueCategoryTopicss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortTitle
        subtitle
        image
        URL
        type
        itemId
        content
        pinToTop
        sliderTopic
        tag
        daysOfWeek
        timeStart
        timeEnd
        category
        subCategory
        price
        omittedCities
        allowedCities
        orderBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCountryData = /* GraphQL */ `
  query GetCountryData($id: ID!) {
    getCountryData(id: $id) {
      id
      currencies {
        code
        name
        symbol
      }
      flag
      name
      alpha2Code
      alpha3Code
      callingCodes
      timezones
      region
      createdAt
      updatedAt
    }
  }
`;
export const listCountryDatas = /* GraphQL */ `
  query ListCountryDatas(
    $filter: ModelCountryDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountryDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currencies {
          code
          name
          symbol
        }
        flag
        name
        alpha2Code
        alpha3Code
        callingCodes
        timezones
        region
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCountriesByName = /* GraphQL */ `
  query ListCountriesByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCountryDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountriesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currencies {
          code
          name
          symbol
        }
        flag
        name
        alpha2Code
        alpha3Code
        callingCodes
        timezones
        region
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPingConfigs = /* GraphQL */ `
  query GetPingConfigs($id: ID!) {
    getPingConfigs(id: $id) {
      id
      type
      token
      data
      createdAt
      updatedAt
    }
  }
`;
export const listPingConfigss = /* GraphQL */ `
  query ListPingConfigss(
    $filter: ModelPingConfigsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPingConfigss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        token
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listConfigsByType = /* GraphQL */ `
  query ListConfigsByType(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPingConfigsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigsByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        token
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      userId
      pingfluencerId
      ttl
      email
      name
      feedback
      createdAt
      updatedAt
      pingfluencer {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        pingfluencerId
        ttl
        email
        name
        feedback
        createdAt
        updatedAt
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const getVenueTable = /* GraphQL */ `
  query GetVenueTable($id: ID!) {
    getVenueTable(id: $id) {
      id
      capacity
      quantityAvailable
      tablesBooked
      price
      includes {
        quantity
        title
        description
      }
      disclaimer
      instructions
      parentType
      entityId
      eventId
      repeatEventId
      status
      title
      owner
      createdAt
      updatedAt
      event {
        id
        title
        entityId
        logo
        headerVideos
        publisherId
        area
        sponsors {
          id
          title
          logo
          desc
        }
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        genres
        dateTime
        startDate
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        endDate
        closingTime
        rules
        email
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        pushTimestamps
        ownerType
        tickets {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        influencerInfo {
          hashtags
          tags
          details
        }
        username
        menu {
          id
          title
          status
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        variations {
          nextToken
        }
      }
      repeatEvent {
        id
        title
        entityId
        area
        headerVideos
        publisherId
        sponsors {
          id
          title
          logo
          desc
        }
        logo
        subtitle
        description
        type
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        eventFeatures {
          images
          title
          description
          bookUrl
          status
        }
        categories
        genres
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        firstEventDate
        lastEventDate
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        pushTimestamps
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        tickets {
          nextToken
        }
        occurencesRules {
          title
          startTime
          endTime
          typeOfRepeat
          repeatInterval
          daysOfWeek
          daysOfMonth
          weeksOfMonth
          weeksDaysOfMonth
          occurenceTotalRepeat
          startDate
          endDate
          usesEntityTables
          quantity
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        influencerInfo {
          hashtags
          tags
          details
        }
        config {
          canBuyTickets
        }
        pingfluenceOnly
        createdAt
        updatedAt
        blogPosts {
          nextToken
        }
        occurences {
          nextToken
        }
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const listVenueTables = /* GraphQL */ `
  query ListVenueTables(
    $filter: ModelVenueTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenueTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        capacity
        quantityAvailable
        tablesBooked
        price
        includes {
          quantity
          title
          description
        }
        disclaimer
        instructions
        parentType
        entityId
        eventId
        repeatEventId
        status
        title
        owner
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      nextToken
    }
  }
`;
export const getTableBookingRequest = /* GraphQL */ `
  query GetTableBookingRequest($id: ID!) {
    getTableBookingRequest(id: $id) {
      id
      userName
      userId
      paid
      amountPaid
      eventId
      status
      owners
      tableId
      message
      createdAt
      updatedAt
      table {
        id
        capacity
        quantityAvailable
        tablesBooked
        price
        includes {
          quantity
          title
          description
        }
        disclaimer
        instructions
        parentType
        entityId
        eventId
        repeatEventId
        status
        title
        owner
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          logo
          headerVideos
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          genres
          dateTime
          startDate
          endDate
          closingTime
          rules
          email
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          pushTimestamps
          ownerType
          tablesAvailable
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          headerVideos
          publisherId
          logo
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          genres
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          firstEventDate
          lastEventDate
          price
          rules
          city
          attending
          status
          ownerType
          owner
          pushTimestamps
          promoterIds
          owners
          menuImages
          username
          pingfluenceOnly
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
    }
  }
`;
export const listTableBookingRequests = /* GraphQL */ `
  query ListTableBookingRequests(
    $filter: ModelTableBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTableBookingRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        paid
        amountPaid
        eventId
        status
        owners
        tableId
        message
        createdAt
        updatedAt
        table {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const tableRequestsByTableEvent = /* GraphQL */ `
  query TableRequestsByTableEvent(
    $tableId: ID
    $eventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTableBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tableRequestsByTableEvent(
      tableId: $tableId
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        paid
        amountPaid
        eventId
        status
        owners
        tableId
        message
        createdAt
        updatedAt
        table {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const tableRequestsByUserEvent = /* GraphQL */ `
  query TableRequestsByUserEvent(
    $userId: ID
    $eventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTableBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tableRequestsByUserEvent(
      userId: $userId
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        paid
        amountPaid
        eventId
        status
        owners
        tableId
        message
        createdAt
        updatedAt
        table {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const tableRequestsByEvent = /* GraphQL */ `
  query TableRequestsByEvent(
    $eventId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTableBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tableRequestsByEvent(
      eventId: $eventId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        paid
        amountPaid
        eventId
        status
        owners
        tableId
        message
        createdAt
        updatedAt
        table {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const getTicketVariationOccurence = /* GraphQL */ `
  query GetTicketVariationOccurence($id: ID!) {
    getTicketVariationOccurence(id: $id) {
      id
      eventId
      repeatEventId
      repeatEventOccurenceId
      eventType
      variationRuleId
      isSoldOut
      status
      amountSoldCounter
      owners
      ttl
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTicketVariationOccurences = /* GraphQL */ `
  query ListTicketVariationOccurences(
    $filter: ModelTicketVariationOccurenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketVariationOccurences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        repeatEventId
        repeatEventOccurenceId
        eventType
        variationRuleId
        isSoldOut
        status
        amountSoldCounter
        owners
        ttl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listVariationsByRuleId = /* GraphQL */ `
  query ListVariationsByRuleId(
    $variationRuleId: String
    $amountSoldCounter: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketVariationOccurenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVariationsByRuleId(
      variationRuleId: $variationRuleId
      amountSoldCounter: $amountSoldCounter
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        repeatEventId
        repeatEventOccurenceId
        eventType
        variationRuleId
        isSoldOut
        status
        amountSoldCounter
        owners
        ttl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listRepeatEventOccurenceVariations = /* GraphQL */ `
  query ListRepeatEventOccurenceVariations(
    $repeatEventOccurenceId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTicketVariationOccurenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRepeatEventOccurenceVariations(
      repeatEventOccurenceId: $repeatEventOccurenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        repeatEventId
        repeatEventOccurenceId
        eventType
        variationRuleId
        isSoldOut
        status
        amountSoldCounter
        owners
        ttl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listEventVariations = /* GraphQL */ `
  query ListEventVariations(
    $eventId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTicketVariationOccurenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventVariations(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        repeatEventId
        repeatEventOccurenceId
        eventType
        variationRuleId
        isSoldOut
        status
        amountSoldCounter
        owners
        ttl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUpdate = /* GraphQL */ `
  query GetUpdate($id: ID!) {
    getUpdate(id: $id) {
      id
      title
      content
      parentId
      parentTitle
      isPinned
      metadata
      actionCount
      viewCount
      url
      images
      route
      extra
      type
      updatedAt
      createdAt
    }
  }
`;
export const listUpdates = /* GraphQL */ `
  query ListUpdates(
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        parentId
        parentTitle
        isPinned
        metadata
        actionCount
        viewCount
        url
        images
        route
        extra
        type
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listUpdatesByDate = /* GraphQL */ `
  query ListUpdatesByDate(
    $parentId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdatesByDate(
      parentId: $parentId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        parentId
        parentTitle
        isPinned
        metadata
        actionCount
        viewCount
        url
        images
        route
        extra
        type
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getPlaceCollection = /* GraphQL */ `
  query GetPlaceCollection($id: ID!) {
    getPlaceCollection(id: $id) {
      id
      userId
      title
      description
      area
      saved
      shared
      likes
      venueCount
      headerImg
      videos {
        state
        key
        thumbnail
        duration
        updatedAt
        types
      }
      views
      lockCriteria
      score
      areas
      location {
        lat
        lon
      }
      username
      city
      cities
      status
      isPublic
      dateTime
      securityCode
      owners
      createdAt
      updatedAt
      users {
        items {
          id
          listId
          userId
          remminderSet
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      collectionLink {
        items {
          id
          entityId
          listId
          usersVisited
          owners
          orderIndex
          images
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listPlaceCollections = /* GraphQL */ `
  query ListPlaceCollections(
    $filter: ModelPlaceCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaceCollections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        description
        area
        saved
        shared
        likes
        venueCount
        headerImg
        videos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        views
        lockCriteria
        score
        areas
        location {
          lat
          lon
        }
        username
        city
        cities
        status
        isPublic
        dateTime
        securityCode
        owners
        createdAt
        updatedAt
        users {
          nextToken
        }
        collectionLink {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listUserPlaceLists = /* GraphQL */ `
  query ListUserPlaceLists(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPlaceLists(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        description
        area
        saved
        shared
        likes
        venueCount
        headerImg
        videos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        views
        lockCriteria
        score
        areas
        location {
          lat
          lon
        }
        username
        city
        cities
        status
        isPublic
        dateTime
        securityCode
        owners
        createdAt
        updatedAt
        users {
          nextToken
        }
        collectionLink {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listUserPlaceListsCreatedAt = /* GraphQL */ `
  query ListUserPlaceListsCreatedAt(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPlaceListsCreatedAt(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        description
        area
        saved
        shared
        likes
        venueCount
        headerImg
        videos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        views
        lockCriteria
        score
        areas
        location {
          lat
          lon
        }
        username
        city
        cities
        status
        isPublic
        dateTime
        securityCode
        owners
        createdAt
        updatedAt
        users {
          nextToken
        }
        collectionLink {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listUserListsByCity = /* GraphQL */ `
  query ListUserListsByCity(
    $city: String
    $isPublic: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserListsByCity(
      city: $city
      isPublic: $isPublic
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        description
        area
        saved
        shared
        likes
        venueCount
        headerImg
        videos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        views
        lockCriteria
        score
        areas
        location {
          lat
          lon
        }
        username
        city
        cities
        status
        isPublic
        dateTime
        securityCode
        owners
        createdAt
        updatedAt
        users {
          nextToken
        }
        collectionLink {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userPlaceListByUsername = /* GraphQL */ `
  query UserPlaceListByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userPlaceListByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        description
        area
        saved
        shared
        likes
        venueCount
        headerImg
        videos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        views
        lockCriteria
        score
        areas
        location {
          lat
          lon
        }
        username
        city
        cities
        status
        isPublic
        dateTime
        securityCode
        owners
        createdAt
        updatedAt
        users {
          nextToken
        }
        collectionLink {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listUserListsByCityScore = /* GraphQL */ `
  query ListUserListsByCityScore(
    $city: String
    $score: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserListsByCityScore(
      city: $city
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        description
        area
        saved
        shared
        likes
        venueCount
        headerImg
        videos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        views
        lockCriteria
        score
        areas
        location {
          lat
          lon
        }
        username
        city
        cities
        status
        isPublic
        dateTime
        securityCode
        owners
        createdAt
        updatedAt
        users {
          nextToken
        }
        collectionLink {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listUserListsByIsPublic = /* GraphQL */ `
  query ListUserListsByIsPublic(
    $isPublic: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserListsByIsPublic(
      isPublic: $isPublic
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        description
        area
        saved
        shared
        likes
        venueCount
        headerImg
        videos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        views
        lockCriteria
        score
        areas
        location {
          lat
          lon
        }
        username
        city
        cities
        status
        isPublic
        dateTime
        securityCode
        owners
        createdAt
        updatedAt
        users {
          nextToken
        }
        collectionLink {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchPlaceCollections = /* GraphQL */ `
  query SearchPlaceCollections(
    $filter: SearchablePlaceCollectionFilterInput
    $sort: SearchablePlaceCollectionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPlaceCollections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userId
        title
        description
        area
        saved
        shared
        likes
        venueCount
        headerImg
        videos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        views
        lockCriteria
        score
        areas
        location {
          lat
          lon
        }
        username
        city
        cities
        status
        isPublic
        dateTime
        securityCode
        owners
        createdAt
        updatedAt
        users {
          nextToken
        }
        collectionLink {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoUsername
      role
      appVersion
      activated
      blocked
      firstName
      emailSettings {
        transactions
        offers
        loyaltyCard
        eventInvites
        marketing
      }
      lastName
      currency {
        code
        name
        symbol
      }
      placesAddedToListCount
      bio
      preferredTitle
      country {
        currencies {
          code
          name
          symbol
        }
        flag
        name
        alpha2Code
        alpha3Code
        callingCodes
        timezones
        region
      }
      dob
      linkCardRewardClaimed
      email
      stripeConnectId
      stripeCustomerId
      gender
      phone
      city
      allowNotifications
      allowLocation
      allowPersonalisation
      allowCalendar
      profilePicture
      subscibedToEmails
      pushTokens {
        token
        device
        endpointArn
        parentApp
      }
      points
      accumulatedPoints
      isStudent
      lastLoggedIn
      locale
      isInfluencer
      summary
      categories
      social {
        facebook
        twitter
        tiktok
        linkedIn
        instagram
      }
      createdAt
      updatedAt
      termsSigned
      inMailingList
      termsVersionSigned
      EPOSId
      appConfigs
      disabled
      username
      invitedUsers
      tickets {
        items {
          id
          userId
          pingfluencerId
          bookingReference
          eventId
          repeatEventId
          repeatEventOccurencId
          eventType
          eventStartTimestamp
          chargeId
          paymentIntentId
          content
          ticketActivasted
          userName
          tier
          variation
          owners
          status
          ttl
          test
          refunded
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          status
          createdAt
          updatedAt
          owners
          publisherId
          claimedOfferId
          publisherLastRead
          pingfluencerLastRead
          entityId
          userId
          pingfluencerId
        }
        nextToken
      }
      linkedCards {
        scheme
        accountId
        countryCode
        created
        expYear
        expDate
        live
        lastNumbers
        expMonth
        updated
        programId
        firstNumbers
        id
        type
      }
      checkIns {
        items {
          id
          userId
          entityId
          fullName
          phone
          partySize
          gender
          dob
          owners
          age
          email
          ttl
          status
          updatedAt
          createdAt
        }
        nextToken
      }
      reviews {
        items {
          id
          review
          score
          feedback
          dateOfVisit
          userId
          entityId
          publisherId
          status
          verified
          createdAt
          updatedAt
        }
        nextToken
      }
      userEvents {
        items {
          id
          eventDate
          eventId
          userName
          repeatEventId
          repeatEventOccurenceId
          type
          userId
          owners
          status
          checkInDate
          tablesBooked
          subscriptionArn
          plusOnesRemaining
          authCode
          subscriptionArns
          subscriptionActive
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      loyaltyCards {
        items {
          id
          userId
          publisherId
          businessTitle
          currentTier
          entityId
          visits
          businessAssignedTier
          transactions
          totalLoyaltyPointsRequiredForAccess
          subscriptionArns
          notificationsEnabled
          totalSpend
          pendingPoints
          totalPoints
          ownerType
          status
          userFullName
          owner
          owners
          totalSpendablePoints
          createdAt
          updatedAt
        }
        nextToken
      }
      tableBookings {
        items {
          id
          userName
          userId
          paid
          amountPaid
          eventId
          status
          owners
          tableId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      lists {
        items {
          id
          listId
          userId
          remminderSet
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      offers {
        items {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
        nextToken
      }
      subscriptions {
        items {
          id
          userId
          pingfluencerId
          entityId
          publisherId
          userFullName
          subscriptionArn
          subscriptionArns
          notificationsEnabled
          credit
          loyaltyPoints
          loyaltyStatus
          EPOSId
          type
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserByUsername = /* GraphQL */ `
  query GetUserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getInfluencerUsers = /* GraphQL */ `
  query GetInfluencerUsers(
    $isInfluencer: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInfluencerUsers(
      isInfluencer: $isInfluencer
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getClaimedOffer = /* GraphQL */ `
  query GetClaimedOffer($id: ID!) {
    getClaimedOffer(id: $id) {
      id
      userId
      pingfluencerId
      offerId
      fidelOfferId
      reviewDate
      reviewId
      reviewRating
      activated
      pointsCosts
      entityId
      metadata
      publisherId
      type
      ttl
      deliveryAddress {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      deliveryDelivered
      bookingClicked
      influencerContentDelivered
      influencerContentPlatform
      socialContent {
        id
        type
        platform
        url
        media_url
        thumbnail
        duration
        title
        description
        uploadDate
        addedToPingfluenceDate
        embed_url
        like_count
        display_type
        engagement
        impressions
        reach
        saved
        comments
        likes
        plays
        shared
        engagement_rating
      }
      socialContentArray {
        id
        type
        platform
        url
        media_url
        thumbnail
        duration
        title
        description
        uploadDate
        addedToPingfluenceDate
        embed_url
        like_count
        display_type
        engagement
        impressions
        reach
        saved
        comments
        likes
        plays
        shared
        engagement_rating
      }
      socialContentShareLink
      confirmationStatus
      confirmationStatusUpdatedAt
      confirmationStatusReason
      registeredAsNoShow
      noShowRespondedTo
      registeredAsNoShowDate
      proposalDate
      proposalDates
      proposalDatesRanges {
        startDate
        endDate
      }
      plusGuests
      owners
      status
      createdAt
      updatedAt
      bookingReservationId
      conversations {
        items {
          id
          status
          createdAt
          updatedAt
          owners
          publisherId
          claimedOfferId
          publisherLastRead
          pingfluencerLastRead
          entityId
          userId
          pingfluencerId
        }
        nextToken
      }
      pingfluencerConfirmationStatus
      createdByPublisher
      offerRespondedTo
      notesFromPingfluencer
      autoCancelled
      offer {
        id
        daysAvailable
        startTime
        price
        discount
        endTime
        endDate
        countryCode
        declineReason
        requiresBooking
        startDate
        maxTransactionAmount
        minTransactionAmount
        returnPeriod
        metaData {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        description
        totalLoyaltyPointsRequiredForAccess
        sharePointsRequiredForAccess
        contactToClaim
        contactEmail
        notificationEmails
        title
        images
        weeks
        entityId
        publisherId
        location {
          lat
          lon
        }
        fidelId
        spendRequired
        delayMinutes
        delayHours
        currency
        isStudentDiscount
        city
        area
        availableEntities
        quantity
        status
        loyaltyPointsRequired
        loyaltyPointsValue
        visitsRequired
        redeemCount
        claimCount
        dateAvailable
        allowanceLimit
        refreshDaysPeriod
        type
        rewardType
        subscribersOnly
        owner
        disclaimer
        metadata {
          userId
          publisherId
          brandId
          entityId
          title
          gender
          dob
          cognitoUsername
        }
        fidelOfferId
        fidelStartDate
        fidelEndDate
        fidelMinTransactionAmount
        fidelMaxTransactionAmount
        fidelReturnPeriod
        fidelDaysOfWeek
        hashtags
        mentions
        tiktokHashtags
        tiktokMentions
        instagramHashtags
        instagramMentions
        claimType
        redeemLatencyPeriod
        contactPhone
        doNotWhatsapp
        userAllowedClaimsPerMonth
        socialPostRequirements {
          and
          extraRequirement
        }
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        citiesAvailable
        minimumStarRating
        monetaryValue
        monthlyLimitPerUser
        pinToWelcome
        numOfFollowersRequired
        eventDateStart
        eventDateEnd
        customInstructions
        categories
        offerCategories
        quantityAvailable
        claimUrl
        comingSoon
        requiresConfirmation
        peopleQuantity
        additionalGuestsAllowed
        offerDuration
        bookingStickerUrl
        appearancePriority
        listingPriority
        showAsStandaloneOffer
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
      }
      pingfluencer {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      pingfluencerContent {
        items {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
          owners
          entityId
          publisherId
          pingfluencerId
          claimedOfferId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listClaimedOffers = /* GraphQL */ `
  query ListClaimedOffers(
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaimedOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listpublisherClaimedOfferKey = /* GraphQL */ `
  query ListpublisherClaimedOfferKey(
    $publisherId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listpublisherClaimedOfferKey(
      publisherId: $publisherId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const publisherClaimedOffersConfirmationBetweenDates = /* GraphQL */ `
  query PublisherClaimedOffersConfirmationBetweenDates(
    $publisherId: ID
    $confirmationStatusUpdatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publisherClaimedOffersConfirmationBetweenDates(
      publisherId: $publisherId
      confirmationStatusUpdatedAt: $confirmationStatusUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listentityClaimedOfferKey = /* GraphQL */ `
  query ListentityClaimedOfferKey(
    $entityId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listentityClaimedOfferKey(
      entityId: $entityId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userClaimedOffersByType = /* GraphQL */ `
  query UserClaimedOffersByType(
    $type: String
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userClaimedOffersByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userClaimedOffersBetweenMonths = /* GraphQL */ `
  query UserClaimedOffersBetweenMonths(
    $userId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userClaimedOffersBetweenMonths(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const pingfluencerClaimedOffersBetweenMonths = /* GraphQL */ `
  query PingfluencerClaimedOffersBetweenMonths(
    $pingfluencerId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pingfluencerClaimedOffersBetweenMonths(
      pingfluencerId: $pingfluencerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const pingfluencerClaimedOffersConfirmationStatus = /* GraphQL */ `
  query PingfluencerClaimedOffersConfirmationStatus(
    $pingfluencerId: ID
    $confirmationStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pingfluencerClaimedOffersConfirmationStatus(
      pingfluencerId: $pingfluencerId
      confirmationStatus: $confirmationStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const pingfluencerClaimedOffersByOfferId = /* GraphQL */ `
  query PingfluencerClaimedOffersByOfferId(
    $pingfluencerId: ID
    $offerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pingfluencerClaimedOffersByOfferId(
      pingfluencerId: $pingfluencerId
      offerId: $offerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const offerClaimsByOfferId = /* GraphQL */ `
  query OfferClaimsByOfferId(
    $offerId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerClaimsByOfferId(
      offerId: $offerId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPingfluencerContent = /* GraphQL */ `
  query GetPingfluencerContent($id: ID!) {
    getPingfluencerContent(id: $id) {
      id
      type
      platform
      url
      media_url
      thumbnail
      duration
      title
      description
      uploadDate
      addedToPingfluenceDate
      embed_url
      like_count
      display_type
      engagement
      impressions
      reach
      saved
      comments
      likes
      plays
      shared
      engagement_rating
      owners
      entityId
      publisherId
      pingfluencerId
      claimedOfferId
      createdAt
      updatedAt
      pingfluencer {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      claimedOffer {
        id
        userId
        pingfluencerId
        offerId
        fidelOfferId
        reviewDate
        reviewId
        reviewRating
        activated
        pointsCosts
        entityId
        metadata
        publisherId
        type
        ttl
        deliveryAddress {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        deliveryDelivered
        bookingClicked
        influencerContentDelivered
        influencerContentPlatform
        socialContent {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentArray {
          id
          type
          platform
          url
          media_url
          thumbnail
          duration
          title
          description
          uploadDate
          addedToPingfluenceDate
          embed_url
          like_count
          display_type
          engagement
          impressions
          reach
          saved
          comments
          likes
          plays
          shared
          engagement_rating
        }
        socialContentShareLink
        confirmationStatus
        confirmationStatusUpdatedAt
        confirmationStatusReason
        registeredAsNoShow
        noShowRespondedTo
        registeredAsNoShowDate
        proposalDate
        proposalDates
        proposalDatesRanges {
          startDate
          endDate
        }
        plusGuests
        owners
        status
        createdAt
        updatedAt
        bookingReservationId
        conversations {
          nextToken
        }
        pingfluencerConfirmationStatus
        createdByPublisher
        offerRespondedTo
        notesFromPingfluencer
        autoCancelled
        offer {
          id
          daysAvailable
          startTime
          price
          discount
          endTime
          endDate
          countryCode
          declineReason
          requiresBooking
          startDate
          maxTransactionAmount
          minTransactionAmount
          returnPeriod
          description
          totalLoyaltyPointsRequiredForAccess
          sharePointsRequiredForAccess
          contactToClaim
          contactEmail
          notificationEmails
          title
          images
          weeks
          entityId
          publisherId
          fidelId
          spendRequired
          delayMinutes
          delayHours
          currency
          isStudentDiscount
          city
          area
          availableEntities
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          visitsRequired
          redeemCount
          claimCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          subscribersOnly
          owner
          disclaimer
          fidelOfferId
          fidelStartDate
          fidelEndDate
          fidelMinTransactionAmount
          fidelMaxTransactionAmount
          fidelReturnPeriod
          fidelDaysOfWeek
          hashtags
          mentions
          tiktokHashtags
          tiktokMentions
          instagramHashtags
          instagramMentions
          claimType
          redeemLatencyPeriod
          contactPhone
          doNotWhatsapp
          userAllowedClaimsPerMonth
          citiesAvailable
          minimumStarRating
          monetaryValue
          monthlyLimitPerUser
          pinToWelcome
          numOfFollowersRequired
          eventDateStart
          eventDateEnd
          customInstructions
          categories
          offerCategories
          quantityAvailable
          claimUrl
          comingSoon
          requiresConfirmation
          peopleQuantity
          additionalGuestsAllowed
          offerDuration
          bookingStickerUrl
          appearancePriority
          listingPriority
          showAsStandaloneOffer
          createdAt
          updatedAt
        }
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
        pingfluencerContent {
          nextToken
        }
      }
    }
  }
`;
export const listPingfluencerContents = /* GraphQL */ `
  query ListPingfluencerContents(
    $filter: ModelPingfluencerContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPingfluencerContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        platform
        url
        media_url
        thumbnail
        duration
        title
        description
        uploadDate
        addedToPingfluenceDate
        embed_url
        like_count
        display_type
        engagement
        impressions
        reach
        saved
        comments
        likes
        plays
        shared
        engagement_rating
        owners
        entityId
        publisherId
        pingfluencerId
        claimedOfferId
        createdAt
        updatedAt
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        claimedOffer {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
      }
      nextToken
    }
  }
`;
export const listPublisherContent = /* GraphQL */ `
  query ListPublisherContent(
    $publisherId: ID
    $uploadDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPingfluencerContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublisherContent(
      publisherId: $publisherId
      uploadDate: $uploadDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        platform
        url
        media_url
        thumbnail
        duration
        title
        description
        uploadDate
        addedToPingfluenceDate
        embed_url
        like_count
        display_type
        engagement
        impressions
        reach
        saved
        comments
        likes
        plays
        shared
        engagement_rating
        owners
        entityId
        publisherId
        pingfluencerId
        claimedOfferId
        createdAt
        updatedAt
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        claimedOffer {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
      }
      nextToken
    }
  }
`;
export const listEntityContent = /* GraphQL */ `
  query ListEntityContent(
    $entityId: ID
    $uploadDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPingfluencerContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityContent(
      entityId: $entityId
      uploadDate: $uploadDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        platform
        url
        media_url
        thumbnail
        duration
        title
        description
        uploadDate
        addedToPingfluenceDate
        embed_url
        like_count
        display_type
        engagement
        impressions
        reach
        saved
        comments
        likes
        plays
        shared
        engagement_rating
        owners
        entityId
        publisherId
        pingfluencerId
        claimedOfferId
        createdAt
        updatedAt
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        claimedOffer {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
      }
      nextToken
    }
  }
`;
export const listPingfluencerContent = /* GraphQL */ `
  query ListPingfluencerContent(
    $pingfluencerId: ID
    $uploadDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPingfluencerContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPingfluencerContent(
      pingfluencerId: $pingfluencerId
      uploadDate: $uploadDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        platform
        url
        media_url
        thumbnail
        duration
        title
        description
        uploadDate
        addedToPingfluenceDate
        embed_url
        like_count
        display_type
        engagement
        impressions
        reach
        saved
        comments
        likes
        plays
        shared
        engagement_rating
        owners
        entityId
        publisherId
        pingfluencerId
        claimedOfferId
        createdAt
        updatedAt
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        claimedOffer {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
      }
      nextToken
    }
  }
`;
export const listClaimedOfferContent = /* GraphQL */ `
  query ListClaimedOfferContent(
    $claimedOfferId: ID
    $uploadDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPingfluencerContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaimedOfferContent(
      claimedOfferId: $claimedOfferId
      uploadDate: $uploadDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        platform
        url
        media_url
        thumbnail
        duration
        title
        description
        uploadDate
        addedToPingfluenceDate
        embed_url
        like_count
        display_type
        engagement
        impressions
        reach
        saved
        comments
        likes
        plays
        shared
        engagement_rating
        owners
        entityId
        publisherId
        pingfluencerId
        claimedOfferId
        createdAt
        updatedAt
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        claimedOffer {
          id
          userId
          pingfluencerId
          offerId
          fidelOfferId
          reviewDate
          reviewId
          reviewRating
          activated
          pointsCosts
          entityId
          metadata
          publisherId
          type
          ttl
          deliveryDelivered
          bookingClicked
          influencerContentDelivered
          influencerContentPlatform
          socialContentShareLink
          confirmationStatus
          confirmationStatusUpdatedAt
          confirmationStatusReason
          registeredAsNoShow
          noShowRespondedTo
          registeredAsNoShowDate
          proposalDate
          proposalDates
          plusGuests
          owners
          status
          createdAt
          updatedAt
          bookingReservationId
          pingfluencerConfirmationStatus
          createdByPublisher
          offerRespondedTo
          notesFromPingfluencer
          autoCancelled
        }
      }
      nextToken
    }
  }
`;
export const getSubscriber = /* GraphQL */ `
  query GetSubscriber($id: ID!) {
    getSubscriber(id: $id) {
      id
      userId
      pingfluencerId
      entityId
      publisherId
      userFullName
      counters {
        bookings
        offersRedeemed
        checkins
      }
      subscriptionArn
      subscriptionArns
      notificationsEnabled
      credit
      loyaltyPoints
      loyaltyStatus
      EPOSId
      type
      status
      createdAt
      updatedAt
      pingfluencer {
        id
        cognitoUsername
        role
        appVersion
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        email
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        lastLoggedIn
        locale
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        appConfigs
        invitedUsers
        invitedPublishers {
          id
          businessName
          signedUpDate
          acceptedStatus
          trialFinished
          paidForInvite
        }
        influencerCategories
        aceptedAsInfluencer
        hideInstagramLogin
        hideTiktokLogin
        declinedAsInfluencer
        lastDateVetted
        tags
        instagramUsername
        tiktokUsername
        instagramBio
        tiktokBio
        currentInstagramFollowerCount
        currentTiktokFollowerCount
        instagramEngagementRanking
        tiktokEngagementRanking
        instagramAverageViewCount
        instagramAverageEngagement
        instagramAverageEngagementRate
        tiktokAverageViewCount
        tiktokAverageEngagement
        tiktokAverageEngagementRate
        instagramReach
        instagramImpressions
        instagramAudienceCity {
          city
          count
        }
        instagramAudienceAgeGender {
          gender
          age
          count
        }
        instagramLinked
        tiktokLinked
        tiktokDeepLink
        instagramVerified
        tiktokVerified
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        tickets {
          nextToken
        }
        instagramAccessToken
        instagramUserId
        tiktokAccessToken
        tiktokRefreshToken
        facebookUserId
        facebookAccessToken
        facebookPageId
        facebookInstaAccountId
        warningCount
        noShowWarningCount
        instagramInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        tiktokInsights {
          impressions
          reach
          follower_count
          profile_views
          audience_gender_age
          audience_locale
          audience_country
          audience_city
          online_followers
          accounts_engaged
          total_interactions
          likes
          comments
          shares
          saves
          replies
          engaged_audience_demographics
          reached_audience_demographics
          follower_demographics
          follows_and_unfollows
          profile_links_taps
        }
        reviewCount
        reviewRating
        reviewScores {
          speed
          quality
          captions
          deliverables
          behaviour
          overall
        }
        conversations {
          nextToken
        }
        username
        notes
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        lastAdminCheck
        area
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        notes
        summary
        uniqueAspect
        tags
        highlights {
          title
          image
          order
        }
        nickname
        hasEmailed
        firstName
        hasStudentDiscount
        LastName
        config {
          loyaltyEnabled
          canMessage
          canBook
          canCheckin
          canCall
          canEmail
          autoResponseMessage
          enterVenueCheckInEnabled
          checkInMessage
          colorTheme
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        outdoorArea
        outdoorHeating
        description
        stripeSubscriptionItemId
        emojiSummary
        categories
        influencerCategories
        phone
        internationalPhoneNumber
        logo
        pushTimestamps
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        asFeaturedOn
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        pageVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        cardVideos {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        sponsors {
          id
          title
          logo
          desc
        }
        headerVideos
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        position
        score
        score_before_boost
        boost_multiplier
        boost_until
        boostPurchaseId
        reviewRating
        reviewCount
        canMessage
        forceupdate
        email
        marketingEmail
        menuUrlList {
          title
          type
          url
        }
        menuPDFList {
          title
          type
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        cuisines
        dietryOptions
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        qrIds
        publisherId
        subscribeOfferId
        subscriberCount
        viewCount
        bookCount
        shareCount
        checkInCount
        addToListCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        googleImages {
          html_attributions
          image
        }
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        minBookingLimit
        maxBookingLimit
        bookingUrl
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        owner
        reviewScores {
          food
          vibe
          service
          value
          overall
        }
        safetyChecklist {
          title
          listItems
        }
        venueSafetyData {
          list
          title
          description
          links
          updated
        }
        capacity
        acceptWalkIns
        covidMeasures {
          capacityPercentage
          tableLimit
          masksRequired
          bookingsRequired
          notes
        }
        mediaChannels {
          youtubeUrl
          spotifyUrl
          deezerUrl
          mixcloudUrl
          amazonMusicUrl
          tidalUrl
          soundcloudUrl
          appleMusicUrl
        }
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        pingReview {
          score
          title
          review
          createdAt
          reviewer
          reviewerPhoto
        }
        pingAwards {
          date
          type
        }
        activated
        lastVetted
        accessToPingCulture
        accessToPingfluence
        hospitalityType
        venueImageForPingfluencer
        isNotVenue
        utcOffsetMinutes
        timezoneName
        blogPosts {
          nextToken
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        collection {
          nextToken
        }
        claimedOffers {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        cognitoUsername
        financeEmail
        role
        profilePicture
        bio
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        notificationEmails
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        pushTimestamps
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        subscibedToEmails
        groupVenues
        groupVenuesName
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        canMessage
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        termsMinimumMonthContract
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        rejectionDate
        startedPaying
        stripeConnectId
        contacts {
          title
          firstName
          lastName
          venue
          email
          phone
          position
          notes
        }
        stripeCustomerId
        fidelId
        activatedClaimedOffersCount
        stripeSubscriptions {
          nextToken
        }
        conversations {
          nextToken
        }
        averageResponseTime
        fidelConfig {
          merchantId
          brandId
          status
          brandUserId
          locationId
          contractSigned
        }
        preventSubscriptionLockout
        referralCodeUsed
        doesNotRequirePendingActions
        videoData {
          state
          key
          thumbnail
          duration
          updatedAt
          types
        }
        monthlyClaimsLimit
        monthlyClaimsCounter
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        cognitoUsername
        role
        appVersion
        activated
        blocked
        firstName
        emailSettings {
          transactions
          offers
          loyaltyCard
          eventInvites
          marketing
        }
        lastName
        currency {
          code
          name
          symbol
        }
        placesAddedToListCount
        bio
        preferredTitle
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        dob
        linkCardRewardClaimed
        email
        stripeConnectId
        stripeCustomerId
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        isStudent
        lastLoggedIn
        locale
        isInfluencer
        summary
        categories
        social {
          facebook
          twitter
          tiktok
          linkedIn
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        inMailingList
        termsVersionSigned
        EPOSId
        appConfigs
        disabled
        username
        invitedUsers
        tickets {
          nextToken
        }
        conversations {
          nextToken
        }
        linkedCards {
          scheme
          accountId
          countryCode
          created
          expYear
          expDate
          live
          lastNumbers
          expMonth
          updated
          programId
          firstNumbers
          id
          type
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        lists {
          nextToken
        }
        offers {
          nextToken
        }
        subscriptions {
          nextToken
        }
      }
    }
  }
`;
export const listSubscribers = /* GraphQL */ `
  query ListSubscribers(
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        pingfluencerId
        entityId
        publisherId
        userFullName
        counters {
          bookings
          offersRedeemed
          checkins
        }
        subscriptionArn
        subscriptionArns
        notificationsEnabled
        credit
        loyaltyPoints
        loyaltyStatus
        EPOSId
        type
        status
        createdAt
        updatedAt
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const listLoyaltyUsersFollowerKey = /* GraphQL */ `
  query ListLoyaltyUsersFollowerKey(
    $publisherId: ID
    $loyaltyStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoyaltyUsersFollowerKey(
      publisherId: $publisherId
      loyaltyStatus: $loyaltyStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        pingfluencerId
        entityId
        publisherId
        userFullName
        counters {
          bookings
          offersRedeemed
          checkins
        }
        subscriptionArn
        subscriptionArns
        notificationsEnabled
        credit
        loyaltyPoints
        loyaltyStatus
        EPOSId
        type
        status
        createdAt
        updatedAt
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
    }
  }
`;
export const searchSubscribers = /* GraphQL */ `
  query SearchSubscribers(
    $filter: SearchableSubscriberFilterInput
    $sort: SearchableSubscriberSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSubscribers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userId
        pingfluencerId
        entityId
        publisherId
        userFullName
        counters {
          bookings
          offersRedeemed
          checkins
        }
        subscriptionArn
        subscriptionArns
        notificationsEnabled
        credit
        loyaltyPoints
        loyaltyStatus
        EPOSId
        type
        status
        createdAt
        updatedAt
        pingfluencer {
          id
          cognitoUsername
          role
          appVersion
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          email
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          lastLoggedIn
          locale
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          appConfigs
          invitedUsers
          influencerCategories
          aceptedAsInfluencer
          hideInstagramLogin
          hideTiktokLogin
          declinedAsInfluencer
          lastDateVetted
          tags
          instagramUsername
          tiktokUsername
          instagramBio
          tiktokBio
          currentInstagramFollowerCount
          currentTiktokFollowerCount
          instagramEngagementRanking
          tiktokEngagementRanking
          instagramAverageViewCount
          instagramAverageEngagement
          instagramAverageEngagementRate
          tiktokAverageViewCount
          tiktokAverageEngagement
          tiktokAverageEngagementRate
          instagramReach
          instagramImpressions
          instagramLinked
          tiktokLinked
          tiktokDeepLink
          instagramVerified
          tiktokVerified
          instagramAccessToken
          instagramUserId
          tiktokAccessToken
          tiktokRefreshToken
          facebookUserId
          facebookAccessToken
          facebookPageId
          facebookInstaAccountId
          warningCount
          noShowWarningCount
          reviewCount
          reviewRating
          username
          notes
        }
        entity {
          id
          title
          shortUrl
          subtitle
          lastAdminCheck
          area
          notes
          summary
          uniqueAspect
          tags
          nickname
          hasEmailed
          firstName
          hasStudentDiscount
          LastName
          outdoorArea
          outdoorHeating
          description
          stripeSubscriptionItemId
          emojiSummary
          categories
          influencerCategories
          phone
          internationalPhoneNumber
          logo
          pushTimestamps
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          asFeaturedOn
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          headerVideos
          city
          rules
          position
          score
          score_before_boost
          boost_multiplier
          boost_until
          boostPurchaseId
          reviewRating
          reviewCount
          canMessage
          forceupdate
          email
          marketingEmail
          menuImages
          price
          featured
          cuisines
          dietryOptions
          verified
          qrIds
          publisherId
          subscribeOfferId
          subscriberCount
          viewCount
          bookCount
          shareCount
          checkInCount
          addToListCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          bookingUrl
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
          capacity
          acceptWalkIns
          activated
          lastVetted
          accessToPingCulture
          accessToPingfluence
          hospitalityType
          venueImageForPingfluencer
          isNotVenue
          utcOffsetMinutes
          timezoneName
        }
        publisher {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          bio
          firstName
          lastName
          email
          notificationEmails
          publisherType
          editors
          owner
          username
          pushTimestamps
          emailsDisabled
          timezone
          subscibedToEmails
          groupVenues
          groupVenuesName
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          termsMinimumMonthContract
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          rejectionDate
          startedPaying
          stripeConnectId
          stripeCustomerId
          fidelId
          activatedClaimedOffersCount
          averageResponseTime
          preventSubscriptionLockout
          referralCodeUsed
          doesNotRequirePendingActions
          monthlyClaimsLimit
          monthlyClaimsCounter
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUsername
          role
          appVersion
          activated
          blocked
          firstName
          lastName
          placesAddedToListCount
          bio
          preferredTitle
          dob
          linkCardRewardClaimed
          email
          stripeConnectId
          stripeCustomerId
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          isStudent
          lastLoggedIn
          locale
          isInfluencer
          summary
          categories
          createdAt
          updatedAt
          termsSigned
          inMailingList
          termsVersionSigned
          EPOSId
          appConfigs
          disabled
          username
          invitedUsers
        }
      }
      nextToken
      total
    }
  }
`;
