import { Pipe, PipeTransform } from '@angular/core';
import { UtilitiesService } from '../services/utilities.service';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'freindProfilePic'
})
export class FriendUrlPipe implements PipeTransform {

  constructor(private utils: UtilitiesService) {}

  transform(image: any, size?: number, customFolder?: string, bigPlaceholder?: boolean): any {
    if (!image){ return bigPlaceholder ? 'assets/images/placeholder.jpg' : 'assets/images/user-placeholder.png'; }
    if (this.utils.validURL(image)) {
        return image;
    } else {
      return `${environment.aws.s3Url}${customFolder || 'profile-pictures'}/${size ? size : 400}/${image}`;
    }
  }

}
