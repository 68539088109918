import { isPlatformBrowser , DOCUMENT} from '@angular/common';
import { environment } from '../environments/environment';
import { Component, OnInit , Inject, PLATFORM_ID} from '@angular/core';
import { Router, NavigationEnd, RouterOutlet, NavigationStart, ActivatedRoute } from "@angular/router";
import { routerAnimation } from 'src/app/shared/animations';
import { UtilitiesService } from './shared/services/utilities.service';
// import { BreakpointService } from './shared/services/breakpoint.service';
import { BehaviorSubject } from 'rxjs';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { MatDialog } from '@angular/material/dialog';

declare let gtag: Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations:[routerAnimation]
})
export class AppComponent implements OnInit {
  static isBrowser = new BehaviorSubject<boolean>(null);

  public fullView: boolean;
  constructor(@Inject(PLATFORM_ID) private platformId: any, @Inject(DOCUMENT) private document: any, private router: Router,
    private route: ActivatedRoute,
    private analytics: GoogleAnalyticsService,
    private modalService: MatDialog,
    private utils: UtilitiesService) {
      this.utils.startSpinner();
      AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    }

  ngOnInit() {
    this.utils.startSpinner();

    // checks if is android or ios and if shown before
    // this.utils.openDownloadModal();

    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        if (window?.scroll) {
          window?.scrollTo(0, 0);
        }
        this.fullView = evt?.url.includes('qr') ? true : false;

        if (typeof gtag !== 'undefined') {
          gtag('config', 'G-4JJ3EDT1BH',
          {
            'page_path': evt.urlAfterRedirects
          }
         );
        }
      }
      if (evt instanceof NavigationStart) {
        this.utils.startSpinner();
      }

    });

    // record all link clicks to gtag
    if (typeof document !== 'undefined' && environment.production && typeof gtag !== 'undefined') {
      document.addEventListener('click', function(evt: any){
        if (evt.target.tagName === 'A' && evt.target.innerText && evt.target.href) {
          gtag('event', 'Link Click', {
            'event_category': 'Clicks',
            'event_label': evt.target.href,
            'link_text': evt.target.innerText,
            'link_url': evt.target.href,
            'link_classes': evt.target.classes,
          });
        }
      });
    }

    // this.applicationUpdateService.updatesAvailable$
    // .pipe(filter(isNotEmpty), first())
    // .subscribe((available: boolean) => {
    //   if (!available) { return; }
    //   console.log('Ping has some new and exciting updates - your dashboard will refresh in 5 seconds');
    //   this.applicationUpdateService.performUpdate()
    // })


    // Check for query parameters
    this.route.queryParams.subscribe(params => {

    });


    if (!isPlatformBrowser(this.platformId)) {
        let bases = this.document.getElementsByTagName('base');

        if (bases?.length > 0) {
            bases[0].setAttribute('href', environment.baseHref);
        }
    }

}


  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  public downloadApp() {
    this.analytics.trackMixpanelEvent('app-download', { platform: 'universal' });
    this.analytics.trackFbEvent('Lead', {content_name: 'universal', value: 1});
  }

}
