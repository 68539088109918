import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";
// import { CookieBarComponent } from "./shared/components/cookie-bar/cookie-bar.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import {LayoutModule} from '@angular/cdk/layout';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { SidebarBlogsResolver } from "./modules/blog/resolvers/sidebar-blogs.resolver";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { BlogsResolver } from "./modules/blog/resolvers/blog-places-page.resolver";

@NgModule({
  declarations: [AppComponent, NotFoundComponent, 
    // CookieBarComponent,
],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    CoreModule,
    LayoutModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    // ServiceWorkerModule.register('ngsw-worker.js'),
  ],
  providers: [
    BlogsResolver,
    SidebarBlogsResolver,
    // {
      //   provide: SwRegistrationOptions,
      //   useFactory: () => ({ enabled: environment.production }),
      // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
