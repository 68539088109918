/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:4ad968ae-799a-43b1-a084-9625558ce910",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_nYgIx1alS",
    "aws_user_pools_web_client_id": "4ugm8tjtplfg5uvmpedvcslvbn",
    "oauth": {
        "domain": "cultureapp0240b79c-0240b79c-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "com.app.ping://welcome/",
        "redirectSignOut": "com.app.ping://welcome/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "culture-storage-bucket-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://4ddlkwleqnabnk4wpnw2sc2exe.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "767c5a68a7164222bad162fa60ef0533",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;