/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:7929a752-ed71-4eb7-91de-3b12ed8dd96a",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_h05ZiK5sE",
    "aws_user_pools_web_client_id": "7j2hosl1ebfdjdfeehjchojvg7",
    "oauth": {
        "domain": "cultureapp0240b79c-0240b79c-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:8100/welcome/",
        "redirectSignOut": "http://localhost:8100/welcome/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "culture-storage-bucket-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://nnvd6hejwva2tnaayrs2twn3nq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "49319f5bbf47471db1aa60f0ac1cc1a8",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;

