<ping-header *ngIf="!fullView"></ping-header>

<div class="w-100 app-container" [ngClass]="{ 'pt-0' : fullView }">
    <router-outlet></router-outlet>
</div>

<ping-footer *ngIf="!fullView"></ping-footer>

<!-- <ping-cookie-bar></ping-cookie-bar> -->

<ping-sleek-loader></ping-sleek-loader>

  <!-- <a class="download-banner d-md-none d-flex w-100" href="https://pingapp.page.link/invite" target="_blank" alt="Download Pingfluence" (click)="downloadApp()">
      <div class="icon d-flex flex-center flex-shrink-0">
        <img src="assets/logo/app-icon.jpg" width="50" height="50" alt="Ping culture Logo"/>
      </div>
      <div class="d-flex flex-column flex-grow-1 px-3">
        <p class="font-weight-bold text-dark m-0 p-0 text-uppercase">Tap to download the app!</p>
        <p class="text-dark m-0 p-0">Let's get Pinging!</p>
      </div>
    </a> -->


    <style>
      .la-ball-atom > div:before {
          background: var(--ion-color-primary) !important;
      }
      .la-ball-atom > div:nth-child(1) {
        width: 80px !important;
        height: 80px !important;
        background: var(--ion-color-dark) !important;
        background: url('/assets/logo/logo-black-icon.png') center no-repeat !important;
        background-size: contain !important;
      }
    </style>
