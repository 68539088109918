import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    constructor() { }

    log(msg: any, type?: string, where?: string) {
        msg = typeof (msg) === 'string' ? msg : JSON.stringify(msg);
        if (!environment.production) {
            let style: string;
            switch (type) {
                case 'component':
                    style = 'background: #b0d2ff; color: blue; padding: 5px; border-radius:2px';
                    break;
                case 'service':
                    style = 'background: #565721; color: #f3f580; padding: 5px; border-radius:2px';
                    break;
                case 'pipe':
                    style = 'background: #b8ffca; color: green; padding: 5px; border-radius:2px';
                    break;
                case 'directive':
                    style = 'background: #d3b8ff; color: #640eee; padding: 5px; border-radius:2px';
                    break;
                default:
                    style = 'color: #bada55';
                    break;
            }
            if (where) {
                console.log(`%c${type}: ${where}`, style);
            }
            console.log(`%c${type}: ${msg}`, style);

        }
        else {
            // AppInsights
        }

    }

    logError(error: any, where?: string) {
        if (!environment.production) {
            console.error(error);
        }
        else {
            // AppInsights
        }

    }

}