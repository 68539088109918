import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import PubSub from '@aws-amplify/pubsub';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import awsconfig from './aws-exports';
import awsconfigdev from './aws-exports-dev';

const config = environment.awsEnv === 'prod' ? awsconfig : awsconfigdev;
if (environment.production) {
  enableProdMode();
}


API.configure(config);
PubSub.configure(config);
Auth.configure(config);


document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      // if ('serviceWorker' in navigator && environment.production) {
      //   navigator.serviceWorker.register('ngsw-worker.js');
      // }
    })
    .catch(err => console.error(err));
});

