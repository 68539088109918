import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { faBars, faTimes, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { filter, takeUntil } from "rxjs/operators";
import { fadeHideShow } from 'src/app/shared/animations';
import { GoogleAnalyticsService } from "src/app/shared/services/google-analytics.service";
import { Subject } from "rxjs";

@Component({
  selector: "ping-header",
  templateUrl: "./header.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./header.component.scss"],
  animations: [fadeHideShow]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public navbarOpen: boolean;
  public shrinkNav: boolean;
  public isHomepage: boolean = false;
  public hidePlayStore: boolean = false;
  public preventGrow: boolean = false;
  private $destroy = new Subject();

  faBars = faBars;
  faClose = faTimes;
  faSearch = faSearch;

  constructor(private cd: ChangeDetectorRef, private router: Router, private analytics: GoogleAnalyticsService) {}

  ngOnInit(): void {
    if (window && window.innerWidth < 992) {
      this.shrinkNav = true;
      this.preventGrow = true;
    }

    this.router.events.pipe(takeUntil(this.$destroy), filter((event => event instanceof NavigationEnd)))
    .subscribe(() => {
      // add your logic here
      // if (this.router.url === '/' ||  this.router.url === '' || this.router.url === '/business') {
      if (this.router.url.includes('/collab/')) {
        this.hidePlayStore = true;
      } else {
        this.hidePlayStore = false;
      }

      if (this.router.url.includes('/project/')) {
        this.shrinkNav = true;
        this.preventGrow = true;
      } else {
        if (window && window?.innerWidth > 991) {
          this.preventGrow = false;
        }
      }
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.$destroy.next(null);
    this.$destroy.complete();
  }

  public toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    this.cd.detectChanges();
  }

  public goHome(): void {
    this.router.navigateByUrl("");
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    if (window?.scroll){
      if (window.innerWidth > 768) {
        if (window.scrollY > 20 && !this.shrinkNav) {
          this.shrinkNav = true;
          this.cd.detectChanges();
        }
        if (window.scrollY < 20 && this.shrinkNav && !this.preventGrow) {
          this.shrinkNav = false;
          this.cd.detectChanges();
        }
      }
    }
  }


  public downloadApp(platform: string) {
    this.analytics.trackMixpanelEvent('app-download', { platform: platform });
    this.analytics.trackFbEvent('Lead', {content_name: platform, value: 1});
    this.analytics.trackEvent('Download Link', 'Header Icons', platform);
  }
}
