import { Injectable } from '@angular/core';
// import * as mixpanel from 'mixpanel-browser';
import { LoggerService } from 'src/app/core/services/logger.service';
import { environment } from 'src/environments/environment';

declare let gtag: Function;
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private logger: LoggerService) {
    // if (mixpanel) {
    //   mixpanel.init('8aee0bfe6192acabf23eb91a8c9db8f5');

    // }

  }

  public trackEvent(
    eventCategory: string = null,
    eventName: string,
    label: string = null,
    extra_data: any = null) {
    try {
      if (environment.production) {
        gtag('event', eventName, {
          'event_category': eventCategory,
          'event_label': label,
          ...extra_data
        });

        (window as any).fbq('trackCustom', eventName, {
          'event_category': eventCategory,
          'event_label': label,
          ...extra_data
        });
      }
    } catch (err) {
      this.logger.logError(err);
    }
  }

  /**
  * trackEvent
  * @param category
  * @param action
  * @param label
  * @param value
  */
  public trackMixpanelEvent(eventTitle: string, data?: any) {
    // if (mixpanel && environment.production) {
    //   mixpanel.track(
    //     eventTitle,
    //     {
    //       ...data,
    //       source: 'WEB_APP'
    //     }
    //   );
    // }
  }

  public trackFbEvent(trackName?: string, trackingData?: any) {
    if (environment.production) {
      (window as any).fbq('track', trackName, trackingData);
    }
  }

}
