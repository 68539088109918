import { Injectable } from '@angular/core';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '../models/aws';

import awsconfig from "./../../../aws-exports";
import awsconfigdev from "./../../../aws-exports-dev";

const config = environment.awsEnv === 'prod' ? awsconfig : awsconfigdev;

export enum ErrorType {
  ERROR = 'ERROR',
  NETWORK = 'NETWORK',
  GEOLOCATION = 'GEOLOCATION'
}
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  isReady = new BehaviorSubject<boolean>(false);

  constructor() {
    Auth.configure({ ...config, ssr: true });
    API.configure({ ...config, ssr: true });
    this.isReady.next(true);
  }

  public runQuery<T>(query: any, variables: any, mapField: string, iamMode?: boolean, hideToast?: boolean): Observable<T | ErrorType> {
    return from(API.graphql({
      query,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM
      // authMode: !iamMode ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM
    }) as any)
      .pipe(
        map((res: GraphQLResult<T>) => res?.data[mapField]),
        catchError(err => {
          console.log('API Service calling the query: ' + mapField);

          return of(ErrorType.ERROR);
        }),
        first(),
      )
  }

  public runMutation<T>(query: any, variables: any, mapField: string, hideToast?: boolean, iamMode?: boolean): Observable<T | ErrorType> {
    return from(API.graphql({
      query,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM
      // authMode: !iamMode ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM
    }) as any)
      .pipe(
        map((res: GraphQLResult<T>) => res?.data[mapField]),
        catchError(err => {
          console.log('API Service calling the query: ' + mapField)

          return of(ErrorType.ERROR);
        }), first()
      )
  }

}
