import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { IBlogPost } from 'src/app/core/models/blog';
import { Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { BlogService } from '../services/blog/blog.service';
import { GraphResponse } from 'src/app/core/models/aws';
import { SeoService } from 'src/app/shared/services/seo.service';

@Injectable()
export class BlogsResolver implements Resolve<GraphResponse<IBlogPost>> {

    constructor(
        private blogService: BlogService,
        private seo: SeoService,
        @Inject(PLATFORM_ID) private platformId,
        private router: Router,
        private transferState: TransferState) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GraphResponse<IBlogPost>> {

        this.applySEO();

        const KEY = makeStateKey<GraphResponse<IBlogPost>>('blogpostlist');
        if (this.transferState.hasKey(KEY)) {
            const posts = this.transferState.get<GraphResponse<IBlogPost>>(KEY, null);
            this.transferState.remove(KEY);
            return of(posts);
        }
                return this.blogService.getBlogPosts()
                    .pipe(
                        tap(postS => {
                            if (isPlatformServer(this.platformId)) {
                                this.transferState.set(KEY, postS);
                            }

                        }),
                        first()
                    );
    }

    private applySEO(city?: string, area?: string) {

      this.seo.updateMeta(
        `Our thoughts and insights ${area ? 'into ' + area : ''} - Pingfluence`,
        `Our hand-written thoughts and inspirations for the best (or worst) places and things to do ${area ? 'in ' + area : ' in your area'}. `,
        this.router?.url || null,
        'https://pingfluence.com/assets/images/banner-crop.jpg',
        'website'
      );

      this.seo.createLinkForCanonicalURL('https://pingfluence.com/blog/list')
    }
}
