import { NgModule, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { EnsureModuleLoadedOnceGuard } from './ensureLoadedOnce';


import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { FilterService } from './services/filter.service';
import { HttpClientModule } from '@angular/common/http';
import { SleekLoaderComponent } from '../shared/components/sleek-loader/sleek-loader.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SleekLoaderComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
  ],
  providers: [FilterService],
  exports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    SleekLoaderComponent
  ],
  entryComponents: [SleekLoaderComponent]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {    // Ensure that CoreModule is only loaded into AppModule

  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }

}

