import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { IBlogPost } from 'src/app/core/models/blog';
import { Observable, of } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { BlogService } from '../services/blog/blog.service';
import { GraphResponse } from 'src/app/core/models/aws';
import { isNotEmpty } from 'src/app/core/guards/type.guards';
import { StoreService } from 'src/app/core/services/store.service';

@Injectable()
export class SidebarBlogsResolver implements Resolve<[GraphResponse<IBlogPost>]> {

    constructor(
        private blogService: BlogService,
        @Inject(PLATFORM_ID) private platformId,
        private store: StoreService,
        private transferState: TransferState) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[GraphResponse<IBlogPost>]> {

        let city = route.params['city'] || null;
        if (!city && typeof localStorage !== 'undefined') {
          city = localStorage.getItem('city') || null;
        }

        let BLOGS_KEY = makeStateKey<[GraphResponse<IBlogPost>]>('sidebarBlogs-' + (city || ''));


        if (this.transferState.hasKey(BLOGS_KEY)) {
          const blogs: [GraphResponse<IBlogPost>] = this.transferState.get<[GraphResponse<IBlogPost>]>(BLOGS_KEY, null);
          this.transferState.remove(BLOGS_KEY);
          return of(blogs);
        } else {
          this.blogService.getSidebarBlogs(city, 5);
          return this.store.object$<[GraphResponse<IBlogPost>]>('sidebarBlogs')
              .pipe(
                  filter(isNotEmpty),
                  tap(b => {

                      if (isPlatformServer(this.platformId)) {
                          this.transferState.set(BLOGS_KEY, b);
                      }
                  }),
                  first()
              );
      }
    }

}
