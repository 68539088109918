import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable()
export class SeoService {
  constructor(private title: Title, private meta: Meta,
    @Inject(DOCUMENT) private doc: any) {}

  updateMeta(title?: string, description?: string, url?: string, imgUrl?: string, type?: string, publisherDate?: string, author?: string) {
    if (title) {
      this.title.setTitle(title);
      this.meta.addTag({name: 'twitter:title', content: title});
      this.meta.addTag({name: 'og:title', content: title});
    }

    if (author) {
      this.meta.updateTag({ name: "author", property: "og:author", content: author });
    } else {
      this.meta.updateTag({ name: "author", property:"og:author", content: 'Pingfluence' });
    }

    if (publisherDate) {
      this.meta.updateTag({ name: "article:published_time", property: "article:published_time", content: publisherDate});
    }

    if (description) {
      this.meta.updateTag({ name: "description", property: "og:description", content: description });
      this.meta.updateTag({ name: "description", content: description });
      this.meta.addTag({name: 'twitter:description', content: description});
      this.meta.addTag({name: 'twitter:text:description', content: description});
      this.meta.addTag({name: 'og:description', content: description});
    }
    if (imgUrl) {
      this.meta.addTag({name: 'image', property: 'og:image', content: imgUrl});
      this.meta.addTag({name: 'twitter:image', content: imgUrl});
      this.meta.addTag({name: 'og:image', content: imgUrl.replace('https', 'http')});
      this.meta.addTag({name: 'og:image:secure', content: imgUrl});
      this.meta.addTag({name: 'og:image:secure_url', content: imgUrl});
      this.meta.addTag({name: 'og:image:type', content: 'image/jpeg'});
    }


      this.meta.addTag({name: 'og:type', content: type || 'website'});
      this.meta.addTag({name: 'fb:app_id ', content: '2321451344755922'});



    // Twitter metadata
    this.meta.addTag({name: 'twitter:card', content: 'summary'});
    this.meta.addTag({name: 'twitter:site', content: 'Pingfluence'});

    // facebook and other
    if (url) {
      this.meta.addTag({name: 'og:url', content: 'https://ping-culture.com' + url});
    }
    this.meta.addTag({name: 'og:type', content: 'article'});
  }

  createLinkForCanonicalURL(originUrl?: string) {
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', originUrl);
    this.doc.head.appendChild(link);
  }

  applyImagePreload(imageUrl: string) {
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'preload');
    link.setAttribute('as', 'image');
    link.setAttribute('href', imageUrl);
    this.doc.head.appendChild(link);
  }
}
