import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { UtilitiesService } from '../../services/utilities.service';
import {Response} from 'express';
import {RESPONSE} from '@nguniversal/express-engine/tokens';

@Component({
  selector: 'ping-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  private response: Response;

    constructor(
      private utils: UtilitiesService,
      @Optional() @Inject(RESPONSE)
      private responseInject: any,
      @Inject(PLATFORM_ID) private platformId: Object)
    {
      this.response = responseInject;
    }

  ngOnInit(): void {
    this.utils.stopSpinner();

    if (this.response) {
      this.response.status(404);
    }
  }

}
