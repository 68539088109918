import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  state,
  animateChild,
  stagger
} from '@angular/animations';



export const fadeHideShow = trigger('fadeHideShow',
  [
    transition(
      ':enter',
      [
        style({ opacity: 0 }),
        animate('0.2s ease-in',
          style({ opacity: 1 }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ opacity: 1 }),
        animate('0.2s ease-out',
          style({ opacity: 0 }))
      ]
    )
  ]
)


export const routerAnimation =
  trigger('routeAnimations', [
    transition('HomePage <=> AboutPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%'})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%'}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ], {optional: true}),
      query(':enter', [
        style({ opacity: '0'}),
      ], {optional: true}),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ opacity: '0'})),
        ], {optional: true}),
        query(':enter', [
          animate('300ms ease-out', style({ opacity: '1'}))
        ], {optional: true})
      ]),
      query(':enter', animateChild(), { optional: true }),
    ])
  ]);