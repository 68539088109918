import { LocationInput, DailyOpeningTimesInput, AddressInput, CurrencyInput, FeatureInput, UrlTypeInput, MenuInput, FoodDeliveryAppLinkInput, AccordionDataInput, SocialItemsInput, TimelineInput, ChecklistInput, MenuSectionInput } from '../api/api';
import { ISpecialOffer } from './offers';
import { IPublisher } from './publisher';
import { IBookingSystemConfig } from './booking';
import { Status } from './enums';

export interface IEntity {
  id?: string | null,
  title: string,
  config?: IEntityConfig,
  area?: string,
  subtitle?: string | null,
  summary?: string | null,
  headerVideos?: string[],
  nickname?: string | null,
  username?: string,
  uniqueAspect?: string,
  hightlights?: IPlaceHighlight[],
  tags?: string[],
  description?: string | null,
  categories?: Array<string | null> | null,
  cuisines?: Array<string | null> | null,
  phone?: string | null,
  bookingUrl?: string,
  dietryOptions?: Array<string | null> | null,
  internationalPhoneNumber?: string | null,
  logo?: string | null,
  imagePrefixType?: any,
  isCheckedIn?: any,
  emojiSummary?: any,
  outdoorArea?: boolean,
  outdoorHeating?: boolean,
  googleImages?: {
    image: string
    html_attributions: string
  }[],
  cardImages?: string[],
  websiteUrl?: string | null,
  location?: LocationInput | null,
  entityTitle?: string | null,
  opens?: number | null,
  closes?: number | null,
  virtualTourUrl?: string | null,
  orderfromTableUrl?: string | null,
  openHours?: DailyOpeningTimesInput | null,
  status?: string,
  address?: AddressInput | null,
  canBook?: boolean | null,
  reviewsDisabled?: boolean | null,
  video?: string | null,
  city?: string,
  currency?: CurrencyInput | null,
  features?: Array<FeatureInput | null> | null,
  rules?: string | null,
  score?: number | null,
  reviewRating?: number | null,
  reviewCount?: number | null,
  canMessage?: boolean | null,
  email?: string | null,
  owner?: string | null,
  menuUrlList?: Array<UrlTypeInput | null> | null,
  menuPDFList?: Array<UrlTypeInput | null> | null,
  menuImages?: Array<string | null> | null,
  menu?: MenuInput | null,
  price?: string | null,
  showMenu?: boolean,
  featured?: string | null,
  entityFeatures?: IEntityFeature[] | null,
  foodDeliveryAppLinks?: Array<FoodDeliveryAppLinkInput | null> | null,
  verified?: boolean | null,
  publisherId?: string,
  publisher?: any | null,
  safetyChecklist?: ChecklistInput | null,
  subscriberCount?: number | null,
  pushTimestamps?: any,
  extraInfo?: Array<AccordionDataInput | null> | null,
  images?: Array<string | null> | null,
  socials?: SocialItemsInput | null,
  minBookingLimit?: number | null,
  maxBookingLimit?: number | null,
  createdAt?: number,
  offersArray?: Array<string | null> | null,
  whatsappNumber?: string | null,
  updatedAt?: number,
  timelines?: Array<TimelineInput | null> | null,
  checklist?: ChecklistInput | null,
  events?: any,
  repeatEvents?: any | null,
  subscribers?: any[] | null,
  offers?: any | null,
  tables?: any | null,
  reviews?: any | null,
  dailyPromotions?: any | null,
  rewards?: ISpecialOffer | null,
  updates?: any[] | null,
  isSubscribed?: boolean | null,
  pingReview?: IPingReview,
  pingAwards?: IPingAward[]
}

export type IPlaceHighlight = {
  title: string,
  image?: string,
  icon?: string,
  color?: string,
  highlights: IPlaceHighlightItem[],
  order: number
}

export interface IPingReview {
  score?: number,
  title?: string,
  review?: string,
  createdAt?: string,
  reviewer?: string,
  reviewerPhoto?: string
}

export type IPlaceHighlightItem = {
  title?: string,
  videoUrl?: string,
  createdAt?: string,
  duration?: number,
  progress?: number,
  userName?: string
}

export type IMenu = {
  id: string,
  publisher?: IPublisher | null,
  sections?: Array<MenuSectionInput | null> | null,
  title?: string | null,
  status: string,
};

export interface IEntityConfig {
  loyaltyEnabled?: boolean,
  canMessage?: boolean,
  canBook?: boolean,
  colorTheme?: string,
  colourScheme?: EntityColourScheme,
  bookingConfig?: IBookingSystemConfig,
  autoResponseMessage?: string,
  emailsDisabled?: boolean,
  reviewsDisabled?: boolean,
  enableTrackTrace?: boolean,
}

export interface IEntityFeature {
  images: string[],
  title: string,
  description: string,
  bookUrl: string,
  status: Status
}

export type EntityColourScheme = {
  primary?: string | null,
  secondary?: string | null,
  text?: string | null,
  buttons?: Array< string | null > | null,
  backgrounds?: Array< string | null > | null,
};

export interface IFeaturedPlace {
  id: String,
  entityId?: String,
  location: LocationInput,
  area: String,
  featuredUntil: String,
  paid: number,
  count: number,
  createdAt: String,
  updatedAt: String,
  ttl: number,
  entity?: IEntity
}

export interface IPingAward {
  date: string,
  type: string
}

export type SearchPlacesSortInput = {
  field?: SearchPlacesSortFields | null,
  direction?: SearchableSortDirection | null,
};


export enum SearchPlacesSortFields {
  id = "id",
  title = "title",
  city = "city",
  area = "area",
  price = "price",
  status = "status",
  score = "score",
  location = "location",
  reviewRating = "reviewRating",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}
