export interface GraphQLOptions {
    query: any;
    variables?: object;
    authMode?: GRAPHQL_AUTH_MODE;
}
export enum GRAPHQL_AUTH_MODE {
    API_KEY = "API_KEY",
    AWS_IAM = "AWS_IAM",
    OPENID_CONNECT = "OPENID_CONNECT",
    AMAZON_COGNITO_USER_POOLS = "AMAZON_COGNITO_USER_POOLS"
}
export interface GraphQLResult<T = object> {
    data?: T;
    errors?: [object];
    extensions?: {
        [key: string]: any;
    };
}

export interface GraphErrorResponse {
    error: string | any,
    query: string
}

export interface GraphResponse<T extends any> {
    items: T[];
    nextToken: any;
    reset?: boolean;
}

export interface ISignUpResult {
    codeDeliveryDetails?: any,
    user: any,
    userSub: string,
    userConfirmed?: boolean
}


export interface ICognitoUser {
    Session: any;
    attributes: {
        sub: string,
        username: string,
        email: string
    };
    email: string;
    email_verified: boolean;
    sub: string;
    authenticationFlowType: string;
    client: any;
    keyPrefix: string;
    pool: any;
    preferredMFA: string;
    username: string;
}

export enum ModelSortDirection {
    ASC = "ASC",
    DESC = "DESC",
  }
  