export const searchEntitys = /* GraphQL */ `
  query SearchEntitys(
    $filter: SearchableEntityFilterInput
    $sort: SearchableEntitySortInput
    $limit: Int
    $nextToken: String
  ) {
    searchEntitys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        summary
        username
        publisherId
        headerVideos
        city
        cuisines
        categories
        location {
          lat
          lon
        }
        opens
        closes
        price
        currency {
          symbol
        }
        reviewRating
        reviewCount
        images
        googleImages {
          image
          html_attributions
        }
      }
      nextToken
      total
    }
  }
`;

export const searchElasticEvents = /* GraphQL */  `query SearchElasticEvents($input: SearchEventsInput) {
  searchElasticEvents(input: $input) {
    items {
      id
      title
      entity {
        title
        city
        images
        logo
        location {
            lat
            lon
        }
        address {
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
      }
      username
      publisherId
      ownerType
      address {
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      entityId
      subtitle
      description
      type
      location {
          lat
          lon
      }
      categories
      dateTime
      startDate
      endDate
      closingTime
      images
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      buyTicketUrl
      price
      city
      attending
      views
      status
    }
    nextToken
  }
}
`;

export const searchElasticRepeatedEvents = /* GraphQL */ `query SearchElasticRepeatedEvents($input: SearchRepeatEventsInput) {
  searchElasticRepeatedEvents(input: $input) {
    items {
      id
      title
      entityId
      dayOfWeek
      subtitle
      description
      type
      categories
      closingTime
      username
      publisherId
      ownerType
      daysOfWeek
      startTime
      endTime
      location {
        lat
        lon
      }
      entity {
        id
        title
        city
        logo
        images
        location {
            lat
            lon
        }
        address {
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
      }
      address {
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      showWeather
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      buyTicketUrl
      canBook
      video
      images
      price
      city
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const searchEvents =  /* GraphQL */  `query SearchEvents(
  $filter: SearchableEventFilterInput
  $sort: SearchableEventSortInput
  $limit: Int
  $nextToken: String
) {
  searchEvents(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      type
      artistsList
      startDate
      publisherId
      ownerType
      attending
      username
      categories
      endDate
      status
      dateTime
      categories
      images
      location {
        lat
        lon
      }
      city
      entity {
        title
        images
        logo
        location {
          lat
          lon
        }
      }
    }
    nextToken
  }
}
`;

export const searchRepeatEvents =  /* GraphQL */  `query SearchRepeatEvents(
  $filter: SearchableRepeatEventFilterInput
  $sort: SearchableRepeatEventSortInput
  $limit: Int
  $nextToken: String
) {
  searchRepeatEvents(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      categories
      closingTime
      publisherId
      ownerType
      daysOfWeek
      username
      startTime
      endTime
      type
      status
      images
      city
      attending
      entity {
        title
        images
        logo
      }
    }
    nextToken
  }
}
`;

export const getBlogPost = /* GraphQL */ `
  query GetBlogPost($id: ID!) {
    getBlogPost(id: $id) {
      id
      postType
      title
      desc
      city
      metaTitle
      metaDesc
      summary {
        title
        content
      }
      area
      user {
        firstName
        lastName
        profilePicture
      }
      facebook
      instagram
      twitter
      website
      videoUrl
      content {
        title
        content
        image
      }
      headerImg
      ttl
      createdAt
      updatedAt
      events {
        items {
          order
          summary
          title
          event {
            id
            type
            city
            title
            description
            startDate
            endDate
            images
          }
        }
      }
      repeatEvents {
        items {
          order
          summary
          title
          repeatEvent {
            id
            type
            city
            title
            description
            daysOfWeek
            startTime
            endTime
            images
          }
        }
      }
      entities {
        items {
          order
          summary
          id
          title
          entity {
            summary
            price
            currency {
              symbol
            }
            location {
              lat
              lon
            }
            title
            username
            id
            description
            categories
            images
            pingReview {
              score
              title
              review
              createdAt
              reviewer
              reviewerPhoto
            }
            logo
            bookingUrl
            config {
              canBook
              bookingConfig {
                bookingProvider
                bookingProviderUsername
              }
            }
            socials {
              tiktok
              instagram
              facebook
            }
            websiteUrl
            username
            city
            area
            logo
            images
          }
        }
        nextToken
      }
    }
  }
`;

export const blogPostsByCityQuery = /* GraphQL */ `
  query BlogPostsByCityQuery(
    $city: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogPostsByCityQuery(
      city: $city
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        title
        desc
        city
        content {
          title
          content
        }
        summary {
          title
          content
        }
        area
        entities (limit: 1) {
          items {
            entity {
              images
            }
          }
        }
        headerImg
        videoUrl
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const blogPostsByAreaQuery = /* GraphQL */ `
  query BlogPostsByAreaQuery(
    $area: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogPostsByAreaQuery(
      city: $city
      areaUpdatedAt: $areaUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        title
        desc
        city
        area
        entities (limit: 1) {
          items {
            entity {
              images
            }
          }
        }
        headerImg
        videoUrl
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listBlogPosts = /* GraphQL */ `
  query ListBlogPosts(
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postType
        title
        desc
        city
        entities (limit: 1) {
          items {
            entity {
              images
            }
          }
        }
        area
        headerImg
        videoUrl
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const listAllPublishersLogos = /* GraphQL */ `
  query ListPublishers(
    $filter: ModelPublisherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        logo
        businessName
        activatedClaimedOffersCount
      }
    }
  }
  `