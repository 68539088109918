import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longNum'
})
export class LongNumPipe implements PipeTransform {

  transform(value: any, digits): any {
    return value ? this.nFormatter(value, digits || 2) :  value;
  }

  private nFormatter(num: number, digits?: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find((item) => {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

}
