export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}


export function isNotUndefined<T>(value: T): value is NonNullable<T> {
  return value != undefined;
}

export function isNotEmpty<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}
